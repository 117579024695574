.title {
  text-align: center;
  font-size: 1.8rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: rgb(33, 33, 33);
}

.text {
  font-family: 'Poppins', sans-serif;
  color: #212121;
  margin-bottom: 1rem !important;
  font-size: 1.2rem;
  display: flex;
  gap: 1rem;
  font-size: 1.2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

  .arrow {
    rotate: 90deg;
  }

.video_home_fast_sale {
  width: 100% !important;
  height: 220px !important;
}

@media(min-width: 768px) and (max-width: 1200px) {
  .container_fast_sale {
    margin-top: 4rem;
  }
  .title {
    text-align: initial;
    margin-top: 0rem;
  }
  .text {
    text-align: initial;
  }
  .text {
    font-family: 'Poppins', sans-serif;
    color: #212121;
    margin-bottom: 1rem !important;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    flex-direction: row;
    font-size: 1.2rem;
  }
  .arrow {
    rotate: initial;
  }
}

@media(min-width: 1201px) {
  .container_fast_sale {
    margin-top: 4rem;
  }
  .video_home_fast_sale {
    width: 100% !important;
    height: 234px!important;
  }
  .title {
    margin-top: 0rem;
    text-align: left;
  }
  .text {
    text-align: left;
  }
  .text {
    font-family: 'Poppins', sans-serif;
    color: #212121;
    margin-bottom: 1rem !important;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 3rem;
    flex-direction: row;
    font-size: 1.2rem;
  }
  .arrow {
    rotate: initial;
  }
}