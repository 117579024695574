.SwiperImoveis{
  height:400px;
}

.SwiperImoveis .swiper-slide{
  width:auto!important;
}

.SwiperImoveis .swiper-button-prev, .SwiperImoveis .swiper-button-next{
  color:#3E5E3F;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  border-radius:50%;
  width:40px;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SwiperImoveis .swiper-button-next:after, .SwiperImoveis .swiper-button-prev:after{
  font-size: 26px!important;
  font-weight: 800!important;
}

.SwiperImoveis .moreAll{
  width:100px;
  background-color: #333;
  height:calc(100% - 35px);
  margin-top:20px;
  margin-left:15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  text-align: center;
  line-height: 1.2;
  transition:0.3s;
  cursor:pointer;
}

.SwiperImoveis .moreAll:hover{
  transform: scale(1.05);
}

.filter-btn{
  // box-shadow: 0 0 8px rgba(0,0,0,0.3);
  background-color: #fff!important;
  min-width:90px;
}

.searchField{
  display: flex;
  flex-direction: row;
  border-radius:  5px 0 0 5px;
  background-color: #fff;
  margin-bottom:10px;
}

.searchField input{
  border-radius: 5px 0 0 5px;
  height:40px;
}

.searchField input::placeholder{
  color:#cdcdcd;
}

.searchField .search-btn{
  border-radius: 0 5px 5px 0;
  width:37px!important;
  height:40px!important;
  background-color: #3E5E3F!important;
  border:none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.3s;
}

.searchField .search-btn i{
  color:#fff;
  font-size: 16px;
  line-height: 1;
}

.searchField .search-btn:hover{
  transform: scale(1.1);
}

.filterActive{
  font-size: 12px!important;
  cursor:pointer!important;
}

.filterActive i{
  margin-left:5px;
}

.sortContainer{
  justify-content:flex-end;
  display: flex;
  justify-content: flex-end;
}

.moreFilters{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding:10px 0;
  border-bottom: solid 1px #333;
  margin-bottom:10px;
  cursor:pointer;
}

.button-select-all-imobiles {
  margin-top: 21px;
}

.button-no-select {
  margin-top: 21px;
  background-color: #ababab !important;
  border: none !important;
  color: #fff  !important;
}

.continer-button-add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 21px !important;
}

.atention {
  width: 100% !important;
}

@media(max-width:768px){
  .contractTabs{
    gap:5px;
    padding:0 10px;
    height:30px;
  }
  .contractTabs.construction{
    justify-content: center;
  }
  .contractTabs .tab{
    padding:4px 12px;
    font-size: 10px;
  }
  .tabList{
    padding:0!important;
  }
  .sortContainer{
    padding-top:20px;
    align-items: flex-start!important;
    justify-content: flex-start!important;
    margin:0 10px;
  }
  .button-no-select {
    margin-left: 1rem;
  }
  .button-select-all-imobiles {
    margin-left: 1rem;
  }
  .continer-button-add {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;
  }
  .sortContainerMobile {
    margin-left: 2rem !important;
  }
}