#policy h5{
  margin:20px 0 10px!important;
}

#policy h3{
  font-size: 24px;
  margin-bottom: 20px!important;
  margin-top: 10px!important;
}

#policy h4{
  font-size: 24px;
  margin-bottom: 0px!important;
  margin-top: 30px!important;
}