.checkout-mensagem{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.checkout-mensagem img{
    width: 100px;
    margin-bottom: 25px;
}

.container-msg{
    background-color: white;
    padding: 50px;
}

.checkout-mensagem h1{
    text-align: center;
    max-width: 450px;
    font-size: 22px;
    margin: 0px;
}

.checkout-mensagem p{
    color: #666;
    text-align: center;
    max-width: 550px;
    margin: 30px 0px;
}

.checkout-mensagem p:last-of-type{
    margin-top: 0px;
}

.checkout-mensagem a{
    background-color: #3e5e3f;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 500;
}