/*? SWIPER INICIAL */
.bg_item {
  background-image: url('../../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
}

/*? ABOUT IMOBILE */

.bg_mobile {
  background-color: #F6F6F6;
  margin-top: 1rem;
}

.mobile_content {
  display: flex;
  justify-content: center;
  margin: 1rem;
  flex-direction: column;
}

.name_mobile {
  font-size: .9rem;
  font-weight: 500;
  margin-top: 1rem;
}

.container_icons_mobile {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin: 1rem 0;
}

.icons_imobile {
  display: flex;
  align-items: center;
  font-size: .8rem;
  gap: .3rem;
}

.location_mobile {
  font-size: .8rem;
  margin-bottom: 1rem;
}

.price_mobile {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: .5rem;
}

.price_mobile span {
  font-size: 1.5rem;
  font-weight: 500;
}

.others {
  font-size: .7rem;
}

.container_buttons_mobile {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  margin-top: 1.5rem;
}

.container_buttons_mobile button {
  padding: .2rem;
  background-color: #f9af24;
  color: #FFF;
  border: none;
  font-weight: 500;
}

.share {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

/*? ABOUT */

.text_mobile {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.text_mobile_about {
  font-size: .8rem;
}

/*? CAROUSEL */

.container_carousel {
  max-width: 1200px;
  margin: auto;
  padding-top: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

/* CONTAINER CAROUSEL */

.container_imobile {
  width: 310px;
  height: 320px;
  margin-left: 3.4rem;
}

.bg_teste {
  background-image: url('../../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 240px;
  margin: 0 -1rem;
}

.container_config {
  padding: 0.8rem;
  background-color: #F6F6F6;
  width: 240px;
  margin: 0 -1rem;
  position: absolute;
  top: 125px;
}

.name {
  font-size: .9rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.price {
  font-weight: 600;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.price span {
  font-size: 1.2rem;
}

.container_config_imobile {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.container_config_imobile span {
  display: flex;
  align-items: center;
  font-size: .8rem;
  margin-bottom: 1rem;
}

.details::after {
  content: "";
  display: block;
  height: 1px;
  width: 150px;
  background-color: #c6c4c4;
}

.container_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
  font-size: .8rem;
}

.view {
  background-color: #f9af24;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem .5rem !important;
  cursor: pointer;
  transition: .3s;
}

.view:hover {
  color: #f9af24;
  background-color: #000;
}

/* BUTTON */

.container_button {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.container_button button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  border: none;
  background-color: #f9af24;
  color: #FFF;
  padding: .5rem 0;
  font-weight: 600;
}