.banner-conecta{
    background-image: url('./../../../../../assets/images/banner-conecta.png');
    background-size:cover;
    background-position: bottom center;
    display: flex;
    font-family: "Exo 2", sans-serif;

    height:380px;
    margin-bottom:20px;
    padding:40px;

    & .info-col{
        flex:3;
    }

    & .image-col{
        flex:2;
    }

    & .image-col{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    & .info-col{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        height:100%;

        & p{
            color:#fff;
            font-size: 24px;
            font-weight: 500;
        }
    }

    & .cadore-foguete-conecta{
        width:240px;
        margin-top:8px;
        margin-bottom: -10px;
        margin-right: 20px;
    }

    & .logo-conecta{
        width:140px;
        margin-bottom:-40px;
        margin-left:-40px;
    }

    & .info-col-title{
        display: flex;
        gap:30px;

        & span{
            display:block;
        }
    }

    & .info-col-subtitle{
        color:white;
        font-size: 24px;
        font-weight: 500;

        & span:nth-child(2){
            margin-top: -15px;
            font-size: 32px;
        }
    }

    & .info-promo{
        background-color: #3BAC50;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding:0 20px;
        border-radius: 80px;
        margin-bottom: 30px;
        height:80px;

        & span:nth-child(1){
            color:#19233C;
            font-size: 24px;
            font-weight: 600;
        }
        & span:nth-child(2){
            color:white;
            font-size: 44px;
            font-weight: 500;
            margin-top: -20px;
        }
    }

    & .image-col-title{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap:3px;
        color:#fff;
        font-weight:500;
        font-size: 28px;

        & span{
            border:solid 1px #3BAC50;
            border-radius: 60px;
            padding:0px 8px;
        }
    }

    @media(max-width:790px){
        flex-direction: column;
        height:auto;
        gap:20px;
        background-position: bottom right;
        padding:20px 40px 40px;

        & .cadore-foguete-conecta{
            margin-right: 30px;
        }

        & .logo-conecta{
            display:none;
        }

        & .info-col{
           justify-content: center;
           align-items: center;

           & p{
            text-align: center;
            font-size: 18px;
           }
        }

        & .info-col-title{
            flex-direction: column;
            gap:5px;
        }

        & .info-col-subtitle{
            display:flex;
            flex-direction: row;
            align-items: center;
            gap:10px;

            & span:nth-child(2){
                margin-top: 0px;
            }
        }
    }
  }

  .conecta-more-info{
    padding:0 0 15px;

    & a{
        font-size: 16px;
        color:rgb(22, 41, 68);
    }

    @media(max-width:790px){
        padding:0 20px 15px;
    }
  }