.title  {
  text-align: center;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.bg {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  border-radius: 0.5rem 0.5rem 0 0;
  width: 250px;
  margin-left: 2.1rem;
}

.container_immobile {
  background-color: rgb(236, 236, 236);
  position: relative;
  border-radius: 0 0 0.5rem 0.5rem;
  height: 150px;
  width: 250px;
  margin-left: 2.1rem;
  margin-bottom: 1rem;
}

.title_immobile {
  text-align: center;
}

.immobile {
  font-size: .9rem;
  font-weight: 500;
  padding-top: 1rem !important;
  text-align: left;
  padding-left: 1rem !important;
}

.location {
  font-size: .7rem;
  margin-bottom: .7rem !important;
}

.icon_maps {
  margin-bottom: 1rem;
}

.container_location {
  display: flex;
  align-items: center;
}

.config_immobile {
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: .7rem;
  padding-top: .5rem;
}

.container_price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
  padding-bottom: 1rem;
}

.container_price p {
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  padding: .1rem 3rem;
  font-weight: 600;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
}

.container_button button {
  padding: .5rem 2rem;
  font-size: 1rem;
  border: none;
  color: #FFF;
  background-color: var(--primary-color);
  border-radius: 1.5rem;
}

.view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 1rem;
}

.view_immobile{
  margin-bottom: .8rem !important;
  border-radius: 1rem;
  padding: .1rem 1rem !important;
  font-size: .7rem;
  color: #fff;
  background-color: #000;
}

.container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
}