.action-property-desc{
    line-height: 1.1;
    display:block;
    padding-top:5px;
}

.action-property-capter-card{
    background-color: #f4f4f4;
    border-radius: 8px;
    padding:5px;

    & h3{
        font-size: 12px;
    }

    & .capter-card{
        display:flex;
        margin-top:10px;
        gap:8px;
        align-items: center;

        & img{
            width:60px;
            height:60px;
            object-fit: cover;
            border-radius: 100%;
        }

        & h5{
            font-size: 16px;
            font-weight: 500;
            color:#333;
        }

        & .capter-card-content{
            display:flex;
            flex-direction: column;
            gap:5px;
        }
    }
}