.contracts {
  display: flex;
  align-items: center;
  gap: 1rem;
  /* margin-left: 2rem; */
  /* overflow-x: auto; */
}

.contractsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 0 20%;
  gap: 1rem;
  padding-bottom: 1rem; */
}

.container_contracts {
  /* scroll-snap-align: center; */
  display: flex;
  align-items: center;
  gap: .5rem;
  background: #f5f4f4;
  width: 270px;
  height: 110px;
  border-radius: 5px;
  /* margin-top: 4rem; */
  padding-left: 1rem;
}

.container_contracts:first-child {
  margin-top: 3rem;
}

/* .container_contracts:last-child {
  margin-right: 2rem;
} */

.container_contracts img {
  width: 32px;
}

.container_content {
  width: 270px;
}

.container_content p {
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.content_emphasis {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.content_text {
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
}


.indicatorsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: #ccc;
  border-radius: 50%;
}

.activeIndicator {
  background-color: #3e5e3f;
}

@media(min-width: 759px) and (max-width: 1119px) {
  .contracts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0px;
    margin-top: 3rem;

  }
  .contractsWrapper {
    justify-content: center;
    gap: 1rem;
  }
  .container_contracts {
    width: 260px;
  }
  .container_contracts:first-child {
    margin-top: 0rem;
  }
}

@media(min-width: 1200px) {
  .contracts {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
  .container_contracts:first-child {
    margin-top: 0rem;
  }
}