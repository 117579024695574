.coupon-list-item{
    display:flex;
    flex-wrap:wrap;
    border-bottom:solid 1px #cdcdcd;
    padding:10px;
  
    &:hover{
      background-color: #f4f4f4;
    }
  
    & .coupon-code{
      flex:3;
    }
    & .coupon-description{
        flex:4;
    }
    & .coupon-owner-name{
      flex:2;
    }
    & .coupon-owner-type{
      flex:2;
    }
    & .coupon-created{
      flex:3;
    }
    & .coupon-discount{
        flex:1;
    }
    & .coupon-status{
        flex:1;
    }
    & .coupon-uses{
        flex:1;
    }
    & .edit{
      flex:1;
      text-align: center;
  
      & i{
        font-size:20px;
        cursor:pointer;
        transition:0.2s;
      }
    }
  
    @media(max-width:768px){
      flex-wrap:nowrap;
      overflow:auto;
      gap:20px;
  
      & .indicator, & .user{
        white-space: nowrap;
      }
    }
  }
  
  .coupon-list-item.title div{
    font-weight:800;
    font-size:13px;
  }
  
  .coupon-list-item.title{
    background-color: #f4f4f4;
  
    @media(max-width:768px){
      display:none!important;
    }
  }