#imobileMobile .img {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

#imobileMobile .title {
  font-size: 1.2rem;
  padding: 1.5rem 1rem 0 2rem;
}

#imobileMobile .container_location {
  display: flex;
  align-items: center;
}

#imobileMobile .location_name {
  text-align: center;
}

#imobileMobile .container_location_name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  font-size: .9rem;
}

#imobileMobile .share {
  display: flex;
  align-items: center;
  gap: .3rem;
  background-color: var(--primary-color);
  color: #000;
  padding: 1rem 4rem;
  border-radius: .2rem;
  height: 56px;
}

#imobileMobile .share p {
  font-weight: 500;
}

#imobileMobile .container_imobile_config {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

#imobileMobile .config {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#imobileMobile .container_config {
  display: flex;
  align-items: center;
  gap: .3rem;
}

#imobileMobile .container_config p {
  font-size: .8rem;
}

#imobileMobile .price {
  margin-top: 1rem;
  border: 1px solid var(--primary-color);
  padding: 0.5rem 2rem;
  border-radius: 0.3rem;
  font-size: 1.5rem;
}

#imobileMobile .hire {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  text-align: center;
  padding-top: .5rem;
  font-size: .8rem;
}

#imobileMobile .container_button {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

#imobileMobile .container_button div {
  width: 269px;
  padding: 1rem 3.5rem;
  background-color: #f6f6f6;
  color: #000;
  border: none;
  font-weight: 500;
  border-radius: .2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#imobileMobile .container_about {
  border: 1px solid gray;
  border-radius: .5rem;
  margin: 1rem 0;
}

#imobileMobile .about {
  text-align: center;
  margin-top: 1.5rem !important;
  font-size: 1.2rem;
  font-weight: 500;
}

#imobileMobile .config_about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

#imobileMobile .config_about p {
  font-size: .7rem;
}

#imobileMobile .characteristics {
  margin: 1rem 0;
}

#imobileMobile .view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 .5rem;
}

#imobileMobile .view_immobile{
  margin-bottom: .8rem !important;
  border-radius: 1rem;
  padding: .1rem 1rem !important;
  font-size: .7rem;
  color: #fff;
  background-color: #000;
}

#imobileMobile .container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
}

#imobileMobile .icon_maps {
  margin-bottom: 1rem;
}

/* IMÓVEIS SEMELHANTES */

#imobileMobile .scale_immobile {
  transform: scale(.9) !important;
}

#imobileMobile .title_similar {
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 4rem;
  margin-bottom: 1rem !important;
}

#imobileMobile .bg {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  border-radius: 0.5rem;
  width: 250px;
  margin-left: 2.1rem;
}

#imobileMobile .container_immobile {
  background-color:rgb(236, 236, 236);
  position: relative;
  top: -70px;
  border-radius: 0 0 .5rem .5rem;
  width: 250px;
  margin-left: 2.1rem;
}

#imobileMobile .title_immobile {
  text-align: center;
}

#imobileMobile .immobile {
  font-size: .9rem;
  font-weight: 500;
  padding-top: 1.5rem !important;
  text-align: left;
  padding-left: 1.1rem !important;
  padding-right: 1.1rem !important;
  height: 71px;
}

#imobileMobile .location {
  font-size: .7rem;
  margin-bottom: .8rem !important;
}

#imobileMobile .config_immobile {
  display: flex;
  justify-content: center;
  gap: .5rem;
  font-size: .7rem;
  padding-top: .5rem;
}

#imobileMobile .container_price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
  padding-bottom: 1rem;
}

#imobileMobile .container_price p {
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  padding: .1rem 1.5rem;
  font-weight: 600;
}

#imobileMobile .container_new_price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

/* PEOPLE */

#imobileMobile .container_people {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

#imobileMobile .people_img {
  width: 112px;
  height: 112px;
  border-radius: 50%;
}

/*! CONFIG WEB */

#imobileMobile .titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 22px;
  margin: -20px auto 0;
  color:#101010;
  font-family: 'Poppins';
  margin-bottom: 1rem !important;
}

#imobileMobile .actionIcons{
  position: absolute;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
  z-index: 2;
}

#imobileMobile .actionIcons2{
  position: absolute;
  top: -20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
  z-index: 2;
}

#imobileMobile .actionIcons:hover{
  opacity:1;
}

#imobileMobile .titleContainer,
#imobileMobile .container_button{
  width:auto;
  text-align: center;
  position:relative;
}

#imobileMobile .titleContainer .actionIcons{
  right:calc(50% - 55px);
  /* top:-30px; */
  left:auto;
}

#imobileMobile .container_button  .actionIcons{
  right:calc(50% - 185px);
  top:0px;
  left:auto;
}

#imobileMobile .actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

#imobileMobile .actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

#imobileMobile.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

#imobileMobile .colorPickerTitle i,
#imobileMobile .colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

#imobileMobile .colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}