.alert-data{
    background-color: #ed4c4b;
    padding:8px 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.alert-data p{
    color:#fff;
}

.alert-data a{
    font-weight: 600!important;
    font-size: 15px;
    color:#333!important;
    background-color: #fff;
    border-radius: 15px;
    padding:5px 8px;
    cursor:pointer;
    white-space: nowrap;
    margin-left:20px;
}

@media(max-width:768px){
    .alert-data a{
        margin-left:0px;
        max-width:150px;
    }
    .alert-data{
        flex-direction: column;
        gap:7px;
    }
}