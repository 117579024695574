.bg {
  background-color: #cdcdcd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
}

.bg_logo {
  background-color: #FFF;
  opacity: .7;
}

.bg_logo img {
  width: 150px;
  padding: 1rem 0 1rem 4rem;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title h1 {
  font-size: 2rem;
  text-align: center;
  margin-top: 8rem;
}

.none {
  display: none;
}

.semLogo{
  background-color: gray;
  color:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  margin-left: 2rem;
}

.logo {
  width: 70px;
}

.container_logo{
  position: relative;
  background: #f6f6f6;
  opacity: .7;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.inputLogo{
  background-color: #bbb5b5;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 94px;
  cursor: pointer;
}


/* ACTIONS */

.actionIconsFirst {
  position: absolute;
  left: 80px;
  top: 70px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIconsFirst:hover{
  opacity:1;
}

.titleContainer .actionIconsFirst{
  right:calc(50% - 67px);
  top: 94px;
  left:auto;
}

.container_button .actionIconsFirst{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIconsFirst label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIconsFirst span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIconsFirst i{
  font-size: 18px;
  cursor:pointer;
}

.actionIcons{
  position: absolute;
  top: 85px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top: 94px;
  left:auto;
}

.container_button .actionIcons{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:auto;
  font-family: 'Poppins';
  padding-top: 3rem;
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

.imageOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
}

.imageOptions label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  margin-bottom: 10px !important;
  border: 1px solid #f4f4f4;
  padding: 1rem !important;
  border-radius: .3rem;
}

.imageOptions label:hover {
  background-color: #f0f0f0;
}

.imageOptions input[type="radio"] {
  margin-right: 10px;
}

.imageOptions .imagePreview {
  width: 160px;
  height: 80px;
  object-fit: cover;
  border-radius: .3rem;
}

.selected {
  border: 5px solid #c4c4c4 !important;
}

.title_options {
  font-size: 1.2rem;
  margin: 1rem 0 !important;
}

@media(max-width: 759px) {
  .title h1 {
    font-size: 1.5rem;
  }
}