.add-new-coupon{
    padding:40px 20px 20px;
    background-color: #fff;
    transform:translate(-50%, -500%);
    border-radius: 10px;
    top:50%;
    left:50%;
    position:fixed;
    z-index:1999;
    transition:0.3s;
    width: 100%;
    max-width: 420px;

    &.active{
        transform:translate(-50%, -50%);
    }

    & .close-modal{
        position:absolute;
        top:10px;
        right:20px;
        font-size: 26px;
        cursor:pointer;
    }

    & .input-container{
        margin-bottom: 10px;

        & span{
            margin-top: 10px;
            display:block;
        }
    }

    & .coupon-buttons-container{
        text-align: right;
    }
}

.add-new-coupon-overlay{
    width:100%;
    height:100vh;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: black;
    opacity:0.6;
    position:fixed;
    z-index:1998;
    display:none;

    &.active{
        display:block;
    }
}