.container_header {
  /* background-image: url('../assets//background.jpg'); */
  background-color: #cdcdcd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  padding: 0 1rem;
  /* padding-top: 7rem; */
  font-weight: 600;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* SELECT */

.container_select {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  position: relative;
}

.container_select select {
  cursor:pointer;
  width: 105px;
  border: none;
  height: 42px;
  padding-left: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.select_form {
  border-radius: 1rem 0 0 1rem;
}

.select_type {
  border-radius: 0 1rem 1rem 0;
}

.container_select::after {
  content: '▼'; 
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 8px;
  color: var(--primary-color);
}

/* TEXT */

.container_search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.container_search img {
  width: 18px;
  position: relative;
  left: 38px;
}

.container_search input {
  width: 300px;
  border: none;
  height: 50px;
  border-radius: 1.5rem;
  padding-left: 3rem;
  font-size: .8rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; 
  margin-right: 1rem;
}

/* BUTTON */

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  position:relative;
}

.container_button button {
  background-color: #f9af24;
  color: #fff;
  width: 150px;
  border: none;
  height: 40px;
  border-radius: 1.5rem;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.actionIcons{
  position: absolute;
  left: 6px;
  top: 86px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right: calc(50% - 67px);
  top: 30px;
  left: auto;
}

.container_button .actionIcons{
  right: -70px;
  top: 0px;
  left: auto;
}

.actionIcons label{
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

/* .actionIcons:hover{
  transform: scale(1.05);
} */

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 22px;
  margin:auto;
  font-family: 'Poppins';
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
  z-index: 9999;
}

.imageOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
}

.imageOptions label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  margin-bottom: 10px !important;
  border: 1px solid #f4f4f4;
  padding: 1rem !important;
  border-radius: .3rem;
}

.imageOptions label:hover {
  background-color: #f0f0f0;
}

.imageOptions input[type="radio"] {
  margin-right: 10px;
}

.imageOptions .imagePreview {
  width: 160px;
  height: 80px;
  object-fit: cover;
  border-radius: .3rem;
}

.selected {
  border: 5px solid #c4c4c4 !important;
}

.title_options {
  font-size: 1.2rem;
  margin: 1rem 0 !important;
}

@media(min-width: 1200px) {
  .container_select select {
    width: 150px;
  }
  .container_button button {
    width: 180px;
  }
  .container_search img {
    left: 50px;
  }
  .container_search input {
    width: 500px;
    padding-left: 4rem;
    border: none;
    height: 50px;
    border-radius: 1.5rem;
    font-size: .8rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px; 
  }
  .titleEdit {
    text-align: center;
    padding: 0 1rem;
    border:none;
    width: 580px;
    max-width: 100%;
    font-weight: 500;
    font-size: 34px;
    margin:auto;
    font-family: 'Poppins';
  }
}