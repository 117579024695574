.detail {
  background-color: #d1d1d1;
  height: 50px;
}

.semLogo{
  background-color: gray;
  color:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  margin-left: 2rem;
}

.logo {
  width: 70px;
}

.container_logo{
  position: relative;
  background: #f6f6f6;
  opacity: .7;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.inputLogo{
  background-color: #bbb5b5;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 94px;
  cursor: pointer;
}