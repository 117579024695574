.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.bg h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 7rem;
  font-size: 3rem;
  color: var(--primary-color);
  font-weight: 600;
}

.container_search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-left: 2rem;
  cursor: pointer;
}

.container_search input {
  width: 1250px;
  height: 70px;
  border: 1px solid #e8e8e8;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(142, 141, 141, 0.1) 0px 4px 6px -1px, rgba(143, 143, 143, 0.06) 0px 2px 4px -1px;
  border-radius: 2rem;
  padding-left: 2rem;
  
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #101010;
  border-radius: 3rem;
  padding: .4rem;
  position: relative;
  right: 60px;
}

.imobiles {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

/* SWIPER */

.bg_second {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
  width: 280px;
  margin: 1.5rem 3rem 0rem 3rem;
  border-radius: .5rem .5rem 0 0;
}

.container_immobile {
  background-color:rgb(236, 236, 236);
  margin: 0rem 3rem 3rem 3rem;
  position: relative;
  border-radius: 0 0 .5rem .5rem;
  width: 280px;
}

.title_immobile {
  text-align: left;
  margin-left: 1rem;
  margin-bottom: .5rem;
}

.immobile {
  font-size: .8rem;
  font-weight: 500;
  padding-top: 1rem !important;
  height: 38px;
}

.location {
  font-size: .7rem;
  margin-bottom: .9rem !important;
}

.config_immobile {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: .7rem;
  padding-top: .5rem;
}

.container_price_slide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
  padding-bottom: 1rem;;
}

.container_price_slide p {
  border: 1px solid black;
  border-radius: 1rem;
  padding: .1rem 1.5rem !important;
  font-weight: 600 !important;
}

.view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0 1rem;
  cursor: pointer;
}

.view_immobile {
  margin-bottom: 1rem;
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  padding: .1rem 1rem !important;
  font-size: .7rem;
  color: #fff;
  background-color: var(--primary-color);
  margin-bottom: .8rem !important;
}

.view_immobile:hover {
  background-color: #FFF;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
}

.container_father {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container_carousel {
  max-width: 1000px !important;
}

.container_location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.icon_maps {
  margin-bottom: 1rem;
}

.container_carrosseis {
  margin-top: 4.5rem;
}

@media(min-width: 759px) {
  .container_carousel {
    max-width: 710px !important;
  }
}

/* ACTIONS */

.actionIcons{
  position: absolute;
  left: 50px;
  top: 100px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
  z-index: 999;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top: 94px;
  left:auto;
}

.container_button .actionIcons{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border: none;
  width: 580px;
  max-width: 150%;
  font-weight: 500;
  font-size: 34px;
  margin: auto;
  font-family: 'Poppins';
  position: relative;
  top: 132px;
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

.fake_pages {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4rem;
  margin-right: 2rem;
  gap: 1rem;
}

.arrow_page {
  background-color: #cdcdcd;
  padding: 0.5rem;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
}

.page_now {
  background-color: #cdcdcd;
  padding: 0.5rem;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
}

.page_next {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
}

.imageOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
}

.imageOptions label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  margin-bottom: 10px !important;
  border: 1px solid #f4f4f4;
  padding: 1rem !important;
  border-radius: .3rem;
}

.imageOptions label:hover {
  background-color: #f0f0f0;
}

.imageOptions input[type="radio"] {
  margin-right: 10px;
}

.imageOptions .imagePreview {
  width: 160px;
  height: 80px;
  object-fit: cover;
  border-radius: .3rem;
}

.selected {
  border: 5px solid #c4c4c4 !important;
}

.title_options {
  font-size: 1.2rem;
  margin: 1rem 0 !important;
}