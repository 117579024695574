.container_header {
  background-color: #F6F6F6;
  opacity: .7;
  height: 80px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.logo {
  width: 80px;
}

.semLogo{
  background-color: #cdcdcd;
  color:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  margin-left: 2rem;
}

.logo {
  width: 60px;
}

.container_logo{
  position:relative;
}

.inputLogo{
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom:0px;
  height:25px;
  width:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  left:80px;
  cursor:pointer;
}
