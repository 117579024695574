.container_about {
  height: 470px;
  background-color: #dadada;
  /* margin-top: 4rem; */
}

.container_details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}

.details::after {
  content: "";
  display: block;
  height: 3px;
  width: 200px;
  background-color: #9b9b9b;
}

.bg {
  background-color: #fff;
  padding: 1.5rem;
  margin: 1rem;
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 600;
  font-size: 1.8rem;
}

.text p {
  padding: 1rem;
  font-size: .8rem;
  text-align: left;
}

.people {
  width: 200px;
}

.titleEdit {
  text-align: center;
  padding: 0;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:0;
  color:#101010;
  font-family: 'Poppins';
}

.actionIcons{
  position: absolute;
  left:20px;
  top:20px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer{
  width:auto;
  text-align: left;
  position:relative;
}

/* .container_button{
  margin:-30px;
} */

.titleContainer .actionIcons{
  right:auto;
  top:-30px;
  left: 100px;;
}

.container_button  .actionIcons{
  right:calc(50% - 185px);
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}