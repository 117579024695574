.bg {
  background-color: #F6F6F6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 10%);
}

.people {
  width: 200px;
  margin: auto;
}

.title {
  text-align: left;
  color: #101010;
  font-size: 28px;
}

.text,
.textExpand {
  text-align: left;
  margin-left: 4rem !important;
  width: 200px;
}

.aboutMore{
  margin-left: 8rem;
}

.titleContainer{
  left: 120px;
}

.aboutMore{
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 600;
  text-decoration: underline;
  cursor:pointer;
  margin-top:10px;
}

.text{
  height:175px;
  overflow:hidden;
}

.textExpand{
  height:auto;
  overflow:hidden;
}

.titleEdit {
  text-align: left;
  padding: 0;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 22px;
  margin:0;
  color:#101010;
  font-family: 'Poppins';
  margin-left: -3.5rem;
}

.actionIcons{
  position: absolute;
  left:20px;
  top:20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer{
  width:auto;
  text-align: left;
  position:relative;
}

.titleContainer .actionIcons{
  right:auto;
  top:-30px;
  left:auto;
}

.container_button  .actionIcons{
  right:calc(50% - 185px);
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

@media(min-width: 1200px) {
  .bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-image: none;
    padding: 3rem 0;
  }
  .people {
    height: 250px;
    width: 250px;
    margin-right:20px;
    object-fit: cover;
    border-radius: 5px;
  }
  .title {
    text-align: left;
  }
  .text,
  .textExpand {
    width: 600px;
    text-align: left;
    padding: 2rem 2.5rem 4rem 2.5rem;
    margin-left: 8rem !important;
  }
  .titleEdit {
    text-align: left;
    padding: 0;
    border:none;
    width: 580px;
    max-width: 100%;
    font-weight: 500;
    font-size: 34px;
    margin:0;
    color:#101010;
    font-family: 'Poppins';
  }
}