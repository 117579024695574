.title {
  text-align: center;
  font-size: 2rem;
  margin-top: 4rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color:rgb(33, 33, 33);
}

.container_premium {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  gap: 6rem;
}

.content_premium {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  gap: 4rem;
}

.content_premium h2 {
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
}

.content_premium p {
  width: 370px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin-top:10px!important;
}

.container {
  cursor: pointer;
  transition: .7s;
}

.index {
  color: grey;
}

.bg {
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 300px;
  height: 400px;
  border-radius: 5px;
  transition: .5s;
  opacity: 1;
}

.container_content {
  display: flex;
}

.detail {
  height: 50px;
  width: 7px;
  background-color: #3E5E3F;
  margin-right: 1rem;
  border-radius: .5rem;
}

.bg_active {
  opacity: .5;
}

@media(min-width: 760px) {
  .container_premium {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    gap: 7rem;
    margin-left: 1rem;
  }
}