.container_inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  position: absolute;
  top: 50px;
  transform: translate(-50%, -50%);
  z-index: 999;
  transform: scale(.8);
  width: 97%;
}

.options_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  width: 220px;
  height: 56px;
  border: none;
  background-color: #FFF;
  position: relative;
  cursor: pointer;
  font-size: .9rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.options_button span {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.options_container {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  position: absolute;
  width: 240px;
  background-color: #FFF;
  padding: 1rem;
  border-radius: 0 0 .5rem .5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 594 !important;
}

.container_search input {
  width: 240px;
  height: 56px;
  border: none;
  border-radius: 0 .5rem .5rem 0;
  padding-left: 2.2rem;
  font-size: .9rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  text-overflow: ellipsis 
}

.container_search span {
  position: absolute;
  top: 15px;
  left: 972px;
}

.search {
  margin-left: 1rem;
}

.first {
  border-radius: .5rem 0 0 0;
}

.first_active {
  border-radius: .5rem 0 0 .5rem;
}

.search button {
  height: 56px;
  width: 94px;
  color: #FFF;
  background-color: #1919bf;
  border-radius: .5rem;
  border: none;
  position: relative;
  cursor: pointer;
}

.search button:hover {
  background-color: #FFF;
  color: #1919bf;
  border: 2px solid #1919bf;
}

.input_text input::placeholder {
  color: #000;
  width: max-content;
  font-size: 0.9rem;
  background-color: #FFF;
  border: none;
}

.input_value_option {
  border: none;
}

.input_value_option::placeholder {
  color: #000;
  height: 38px;
}

/* EFEITO */
.text_container {
  width: 200px;
  font-family: 'Arial', sans-serif;
  font-size: 1rem;
  position: relative;
  background-color: #FFF;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: .5rem;
  gap: .5rem;
  border: none;
  border-radius: 0 .5rem .5rem 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.text_animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(50, end) infinite alternate;
  border: none;
  height: 38px;
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}

.pause_animation {
  animation-play-state: paused;
  width: 170px !important;
}

.actionIcons {
  position: absolute;
  top: -38px;
  right: 50px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  padding:0 10px;
  border:solid 1px cyan;
}

.actionIcons label {
  cursor: pointer;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}
