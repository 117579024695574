.dados{
    width: 100%;
    height: 470px;
}

.dados .info-client h1{
    font-size: 17px;
    color: #555;
    letter-spacing: .5px;
    margin: 6px 0;
}

.dados .info-client p{
    font-size: 12px;
    margin: 0;
    margin-bottom: 30px;
    color: #666;
}

.dados .dados-form{
    overflow-y: scroll;
    height: 420px;
}

.dados .dados-form .dados-form-scroll{
    display: flex;
    flex-direction: column;
    padding-right: 10px;
}

.dados .dados-form .dados-form-scroll h1{
    font-size: 14px;
    color: #666;
    margin: 0;
    margin-bottom: 10px;
}

.dados .dados-form .dados-form-scroll p{
    color: red;
    font-size: x-small;
    margin-top: 0;
}

.dados .dados-form input{
    height: 37px;
    border-radius: 3px;
    background-color: #f5f5f5;
    border: 0;
    margin-bottom: 5px;
    padding: 0 17px;
    color: #999;
    outline: none;
}

.dados .dados-form input:focus{
    outline: 0;
    border: 1px solid rgba(59,134,255,0.5) !important;
}

.dados .loggin-form .container-inputs input{
    width:calc(100% - 10px);
    display:block;
    height:40px;
    border:solid 1px #cdcdcd;
    border-radius:5px;
    padding:0 5px;
    margin-bottom:10px;
    background-color: #f4f4f4;
}

.dados .loggin-form .key-container span{
    cursor:pointer;
    margin-top:9px;
    margin-left:5px;
    color: #888;
}

.dados .loggin-form .key-container{
    width:100%;
    display:block;
    height:40px;
    border:solid 1px #cdcdcd;
    border-radius:5px;
    margin-bottom:10px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
}

.dados .loggin-form  .key-container input{
    width:calc(100% - 50px);
    display:block;
    height:40px;
    border:none;
    border-radius:0;
    padding:0 5px;
    margin-bottom:0px;
    background-color: rgba(0,0,0,0)!important;
}

.dados .loggin-form .container-inputs button{
    width: 140px;
    background-color: #3E5E3F!important;
    outline: none;
    border: 0px;
    padding: 10px!important;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.dados .loggin-form .container-inputs a{
    color: #666;
    font-size: 15px!important;
}

.dados .loggin-form .container-inputs a span{
    font-weight: 500;
    text-decoration: underline;
}

.dados .dados-form .input-line{
    display: flex;
    gap: 5px;
}

.dados .dados-form .input-line input{
    width: 50%;
}

.dados .dados-form .autorization-check{
    display: flex;
    align-items: center;
    
}

.dados .dados-form .autorization-check input{
    margin: 0px;
    margin-right: 5px;
}

.dados .dados-form .autorization-check a{
    margin-left: 5px;
    color: #666;
    text-decoration: underline!important;
} 

.dados .dados-form .dados-form-scroll input[type='file']{
    display: none;
}

.dados .dados-form .dados-form-scroll #label-foto{
    background-color: unset;
    padding: 5px;
    border-radius: 3px;
    color: #666;
    text-decoration: underline;
    cursor: pointer;
}

.dados .dados-form .dados-form-scroll .input-line span{
    display: flex;
    align-items: center;
}

.dados .dados-form .dados-form-user{
    width: 100%;
}

.dados .dados-form .dados-form-user .user-photo{
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid lightgray;
    margin-bottom: 8px;
}

.dados .dados-form .dados-form-user legend{
    font-size: 13px;
    color: #666;
    margin: 0px;
}

.dados .dados-form .dados-form-user input{
    width: 448px;
    margin-bottom: 8px;
}

.dados .dados-form:has(.dados-form-user){
    overflow: hidden;
}

.dados .dados-form .dados-form-scroll button{
    width: 160px;
    margin-top: 10px;
    outline: none;
    background-color: #00bb4c;
    border: 0px;
    padding: 5px;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    transition: .3s;
}

.dados .dados-form .dados-form-scroll button:hover{
    transition: .3s;
    opacity: .9;
}

.key-container i{
    font-size:20px;
    margin-right:6px;
    margin-top:5px;
}

@media(max-width:768px){
    .dados .dados-form .autorization-check a{
        width:100%!important;
    }
    .dados .dados-form{
        overflow-y: hidden;
        height: auto!important;
    }

    .dados{
        overflow: scroll;
    }
}