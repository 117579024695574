.premium {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.title {
  text-align: center;
  padding: 0 20px;
  font-weight: 500;
  font-size: 1.8rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color:rgb(33, 33, 33);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.container_none {
  display: none;
}

.bg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 350px;
  height: 350px;
  border-radius: 5px;
}

.container_arrow_left {
  background-color: #FFF;
  border-radius: 50%;
  margin-left: 1rem;
  opacity: .7;
}

.container_arrow_right {
  background-color: #FFF;
  border-radius: 50%;
  margin-right: 1rem;
  opacity: .7;
}

.container_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding:0 15px;
}

.container_text h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: .5rem;
  font-family: 'Poppins', sans-serif;
}

.container_text p {
  text-align: center;
  padding: 0 2rem;
  margin-bottom: 0px;
  height: 72px;
  margin-top:10px!important;
  font-family: 'Poppins', sans-serif;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.counter span {
  margin: 0 0.3rem;
  cursor: pointer;
  background-color: rgb(197, 192, 192);
  width: 45px;
  height: 6px;
  border-radius: .5rem;
}

.counter .active {
  background-color: #3e5e3f;
}