.container {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  padding: 4rem 2rem 0rem 2rem;
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.title_premium {
  text-align: center;
  font-weight: 500;
  width: 80px !important;
}

.container_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
}

.container_buttons button {
  width: 120px;
  background-color: gray;
  color: #fff;
  border-radius: .5rem;
  border: none;
  padding: .8rem 1rem;
}

.container_buttons button:hover {
  background-color: #979090;
}

.active {
  width: 120px;
  background-color: #3e5e3f !important;
  color: #fff;
  border-radius: .5rem;
  border: none;
  padding: .8rem 1rem;
}

.active:hover {
  background-color: #597d5a !important;
}

.comparison_table {
  table-layout: fixed;
  width: 93%;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
}

.comparison_table th,
.comparison_table td {
  padding: 0.5rem;
}

.comparison_table th:first-child {
  border: 0;
}

/* .comparison_table th:nth-child(2n) {
  box-shadow: 0 0 2rem hsla(0, 0%, 0%, 0.15);
} */

.comparison_table th:nth-child(2n),
.comparison_table td:nth-child(2n) {
  position: relative;
  background: #fff;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.comparison_table td:not(:first-child) {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.comparison_table thead,
.comparison_table thead span {
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
}

.check,
.no_check {
  width: 1rem;
}

.no_check {
  opacity: .2;
}

/* .detail_right {
  border-right: 1px solid #ddd;
  font-weight: 500;
} */

th .detail_right,
.new_mobile,
.premium,
.free_title,
.plans,
.detail_right {
  font-size: .95rem !important;
  font-weight: bold;
}

.height_plans,
.detail_right {
  width: 60px;
}

.detail_bottom {
  border-bottom: 1px solid #ddd;
}

.detail_bottom td {
  font-size: 12px !important;
}

.free {
  font-size: 1rem;
  font-weight: 600;
  color: green;
  font-family: 'Poppins', sans-serif;
}

.installments,
.dollar {
  font-weight: 600;
  color: green;
  margin-left: .6rem;
  font-family: 'Poppins', sans-serif;
}

.price {
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
}

.align {
  text-align: left !important;
  font-family: 'Poppins', sans-serif;
}

.free_title {
  font-size: 12px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #000;
}

.start {
  font-size: 12px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #4582A1;
}

.premium {
  font-size: 12px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #3e5e3f;
}

.container_button_free {
  display: flex;
  justify-content: center;
  margin-top: 2.1rem;
}

.container_buttons button {
  width: 120px;
  background-color: #c6c0c0;
  color: #fff;
  border-radius: .5rem;
  border: none;
  padding: .8rem 1rem;
}

.container_button_free button:hover {
  background-color: #a39a9a;
}

.container_button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.container_button button {
  font-size: .6rem;
  border: none;
  background-color: #3e5e3f;
  color: #FFFFFF;
  padding: 1rem .9rem;
  border-radius: .5rem;
  font-family: 'Poppins', sans-serif;
}

.container_button button:hover {
  background-color: #597d5a;
}

.perfil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.8rem;
  font-size: 15px;
  padding-top: 1.5rem;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

@media(min-width: 759px) and (max-width: 1119px) {
  .comparison_table {
    table-layout: fixed;
    width: 93%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    margin-left: 1.5rem;
  }
  .title_premium {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 500;
    width: initial !important;
  }
  .height_plans,
  .detail_right {
    width: initial !important;
  }
  .align {
    text-align: center !important;
    font-family: 'Poppins', sans-serif;
  }
  .comparison_table th:nth-child(2n),
  .comparison_table td:nth-child(2n) {
    background: none !important;
  }
  .detail_bottom td {
    font-size: 15px !important;
  }
  .premium {
    font-size: 1.2rem !important;
  }
  .start,
  .free_title {
    font-size: 1rem !important;
  }
  .button_free {
    width: 170px;
    height: 48px;
    border: none;
    border-radius: 0.5rem;
    background-color: gray;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    line-height: 1rem;
  }
  
  .button_start {
    width: 170px;
    height: 48px;
    border: none;
    border-radius: 0.5rem;
    background-color: #4582A1;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
  }
  
  .button_premium {
    width: 170px;
    height: 48px;
    border: none;
    border-radius: 0.5rem;
    background-color: #3e5e3f;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
  }
}

@media(min-width: 1200px) {
  .comparison_table {
    table-layout: fixed;
    width: 1000px;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0.75rem;
    font-family: 'Poppins', sans-serif;
    margin-left: 2rem;
  }
  .title_premium {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 500;
    width: initial !important;
  }
  .height_plans,
  .detail_right {
    width: initial !important;
  }
  .align {
    text-align: center !important;
    font-family: 'Poppins', sans-serif;
  }
  td {
    font-size: 0.95rem;
    font-family: 'Poppins', sans-serif;
    color:rgb(33, 33, 33);
  }
  .container_button_free button,
  .container_button button {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
  }
  .detail_bottom td {
    font-size: 15px !important;
  }
  .premium {
    font-size: 1.2rem !important;
  }
  .start,
  .free_title {
    font-size: 1rem !important;
  }
  .button_free {
    width: 190px;
    height: 48px;
    border: none;
    border-radius: 0.5rem;
    background-color: gray;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    line-height: 1rem;
  }
  
  .button_start {
    width: 190px;
    height: 48px;
    border: none;
    border-radius: 0.5rem;
    background-color: #4582A1;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
  }
  
  .button_premium {
    width: 190px;
    height: 48px;
    border: none;
    border-radius: 0.5rem;
    background-color: #3e5e3f;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
  }
}