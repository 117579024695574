.enterpriseRankingCard{
    width:280px;
    height:340px;
    position:relative;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    border-radius:5px;
    padding:10px;
}

.rankingCover{
    height:260px;
    width:100%;
    background-position:center center;
    background-size:cover;
    border-radius: 5px;
}

.rankingTitle{
    font-size:17px;
    margin-top:18px;
    font-weight:600;
}

.rankingPosition{
    background-color: #888;
    font-weight:600;
    border-radius:50%;
    width:42px;
    height:42px;
    position:absolute;
    top:-10px;
    left:-10px;
    z-index:2;
    display:flex;
    align-items:center;
    justify-content:center;
    color:#fff;
    font-size:20px;
}

.enterpriseRankingGrid{
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap:35px;
    margin:30px 0;
}

.construction-log{
    width:100px;
    height:100px;
    background-color:#cdcdcd;
    background-position:center center;
    background-size:cover;
    border-radius:50%;
}

.construction-name{
    font-size:18px;
    font-weight:600;
}

.dash-btn-new-enterprise{
    display:flex;
    cursor:pointer;
    transition:0.3s;
}

.dash-btn-campaign{
    display:flex;
    cursor:pointer;
    transition:0.3s;
}

.dash-btn-broker-view{
    display:flex;
    cursor:pointer;
    transition:0.3s;
}

.col-container-btn{
    display:flex;
    align-items:center;
    gap:20px;
}

.dash-btn-units{
    display:flex;
    cursor:pointer;
    transition:0.3s;
}

@media(max-width:768px){
    .col-container-btn{
        flex-direction:column!important;
        padding-top:20px;
        align-items: flex-start;
        gap:10px;
    }
}

.dash-btn-units:hover,
.dash-btn-new-enterprise:hover,
.dash-btn-campaign:hover{
    transform:scale(1.03);
}

.dash-btn-new-enterprise .dash-icon{
    height:35px;
    width:35px;
    background-color:#99CF97;
    color:#2C512B;
    border-radius:5px 0 0 5px;
    display:flex;
    align-items:center;
    justify-content:center;

    i{
        font-size:20px;
    }
}

.dash-btn-campaign .dash-icon{
    height:35px;
    width:35px;
    background-color:#333;
    color:#f4f4f4;
    border-radius:5px 0 0 5px;
    display:flex;
    align-items:center;
    justify-content:center;

    i{
        font-size:20px;
    }
}

.dash-btn-broker-view .dash-icon{
    height:35px;
    width:35px;
    background-color:#888;
    color:#cdcdcd;
    border-radius:5px 0 0 5px;
    display:flex;
    align-items:center;
    justify-content:center;

    i{
        font-size:20px;
    }
}

.dash-btn-units .dash-icon{
    height:35px;
    width:35px;
    background-color:#6EC0D2;
    color:#237486;
    border-radius:5px 0 0 5px;
    display:flex;
    align-items:center;
    justify-content:center;

    i{
        font-size:20px;
    }
}

.dash-btn-campaign  .dash-label{
    height:35px;
    width:165px;
    background-color:#999;
    color:#333;
    border-radius:0 5px 5px 0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.dash-btn-broker-view .dash-label{
    height:35px;
    width:165px;
    background-color:#333;
    color:#fff;
    border-radius:0 5px 5px 0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.dash-btn-new-enterprise .dash-label{
    height:35px;
    width:165px;
    background-color:#2C512B;
    color:#fff;
    border-radius:0 5px 5px 0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.dash-btn-units .dash-label{
    height:35px;
    width:135px;
    background-color:#237486;
    color:#fff;
    border-radius:0 5px 5px 0;
    display:flex;
    align-items:center;
    justify-content:center;
}

.atual-campaign{
    width:300px;
    height:300px;
    text-align: center;
    margin:30px auto 60px;

    & h3{
        font-size: 18px;
    }

    & img {
        width:300px;
        height:300px;
        object-fit: cover;
        border-radius: 10px;
        margin:10px 0;
    }
}