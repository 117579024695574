.feed-buttons-container{
  height:35px;
  display:flex;
  gap:20px;
  padding:0 20px;
}

.feed-buttons-container .feed-option{
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:#cdcdcd;
  border-bottom:1px solid #cdcdcd;
  padding:0 10px;
  border-radius:8px 8px 0 0;
  box-shadow:0 0 3px rgba(0,0,0,0.2);
  cursor:pointer;
  color:#888;

  @media(max-width:768px){
    font-size:12px;
  }
}

.feed-buttons-container .feed-option.active{
  background-color:#fff;
  color:#333;
}

.order-list{
  display:flex;
  flex-direction:column;
  background-color:#f1f1f1c0;
  gap:20px;
  overflow:auto;
  height:520px;
  padding:20px;
  border-radius: 10px;
  border:solid 1px #cfcdcd5d;

  &::-webkit-scrollbar {
    width: .5em;
  }
  
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 4px 2px rgba(0, 0, 0, 0.2);
    background-color: #cdcdcd;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #3E5E3F;
    outline: none;
  }



  @media(min-width:768px){
    height:640px;

    &.your{
      height:560px;
    }
  }
}

.order-list .order-list-item{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  gap:15px;
  padding:12px;
  border-radius:8px;
  background-color: #fff;
  box-shadow:0 2px 5px rgba(0,0,0,0.2);
}

.order-list .order-list-item .order-cover{
  width:80px;
}

.order-list .order-list-item .order-cover .photo{
  width:100%;
  height:80px;
  border-radius:8px;
  background-color:#cdcdcd;
  background-position:center center;
  background-size:cover;
}

.order-list .order-list-item .order-content{
  flex:5;

  & h3{
    font-size:15px;
    margin:0;
  }
}

.order-list .order-list-item .order-content .order-content-container{
  border:solid 1px #cdcdcd;
  border-radius:8px;
  padding:10px;
  margin-top:5px;
  display:flex;
  flex-direction:column;
  gap:10px;

  & small{
    text-align:right;
    display:block;
  }
}

.order-list .order-list-item .order-action{
  flex:1;
  // min-height:100%;
  margin-top:10px;
  display:flex;
  align-items: center;
  justify-content:flex-start;
  gap:15px;

  & span{
    background-color: #da2c3e;
    width:40px;
    height:40px;
    color:#fff;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius:8px;
    font-size:20px;
    cursor:pointer;
  }
}

.category-container{
  display:flex;
  gap:10px;

  @media(max-width:768px){
    flex-wrap:wrap;
  }
}

.category-container .category-item{
  border:solid 1px #cdcdcd;
  background-color:#f4f4f4;
  height:35px;
  padding:0 15px;
  border-radius:8px;
  display:flex;
  align-items: center;
  font-size:18px;
  font-weight:700;
  cursor:pointer;
}

.category-container .category-item.active{
  background-color:#3E5E3F;
  color:#fff;
}

.row-range-order{
  gap:15px 25px;
  padding:20px 0 0;
  flex-wrap:wrap;
  width:100%;
  max-width:1080px;
  margin:0!important;

  @media(max-width:768px){
    flex-direction:column;
  }
}

.row-range-order .col-range-order{
  min-width:calc(50% - 25px);
  border:solid 1px #cdcdcd;
  border-radius:8px;
  padding:10px 15px;

  @media(max-width:768px){
    min-width:100%;
  }
}

// .new-order-create{
//   position:absolute;
//   right:10px;
//   top:10px;
// }


.order-content-container .order-type{
  display:flex;
  gap:10px;

  & span{
    background-color: #3E5E3F;
    border-radius:20px;
    padding:7px 10px 5px;
    color:#fff;
    font-weight:800;
    font-size:11px;
  }
}

.order-content-container .order-localization{
  & span{
    font-weight:800;
  }
}

.order-content-container .order-observation{
  font-weight:800;
}

.order-content-container .order-range{
  display:flex;
  gap:5px;
  flex-wrap:wrap;
}

.order-content-container .order-range .order-item-range{
  border:solid 1px #3E5E3F;
  border-radius:8px;
  display:flex;

  & span{
    font-weight:800;
  }

  & i{
    font-size:18px;
    color:#3E5E3F;
  }

  & span:nth-child(1){
    background-color: #fff;
    height:30px;
    width:30px;
    border-radius:6px;
    display:flex;
    align-items:center;
    justify-content:center;
  }

  & span:nth-child(2){
    height:30px;
    border-radius:6px;
    display:flex;
    align-items:center;
    padding:0 10px;
  }
}

@media(max-width:768px){
  .order-list .order-list-item .order-cover{
    width:50px;
  }
  
  .order-list .order-list-item .order-cover .photo{
    width:100%;
    height:50px;
  }
  
  .order-list .order-list-item .order-content{
    flex:5;
  
    & h3{
      font-size:15px;
      margin:0;
    }
  }

  .order-list .order-list-item .order-content .order-content-container{
    width:100%;
  }

  .order-content-container .order-range{
    flex-wrap:wrap;
  }

  .order-list .order-list-item{
    flex-direction:column;
    gap:15px;
    padding-bottom:20px;
    border-bottom:solid 1px #cdcdcd;
    font-size:14px;
  }

  .order-content-container .order-range .order-item-range span:nth-child(2){
    font-size:12px;
  }
  .order-list .order-list-item .order-action{
    min-height: auto;
  }
}

.card-title-container{
  // display:flex;
  // justify-content: space-between;
  padding-bottom:8px;
  background-color: #fff;

  & h4{
    font-size: 20px;
    font-weight: 700;
  }

  & span{
    display:flex;
    gap:10px;
  }

  & button{
    width:320px;
  }

  @media(max-width:768px){
    flex-direction: column;
    align-items:center;

    & span{
      flex-direction: column;
      margin-top:-10px;
      margin-bottom:15px;
    }

    & button{
      width:280px;
    }
  }
}

.feed-title-container{
  display:flex;
  align-items: center;
  gap:8px;

  & img{
    width:35px;
  }

  & h3{
    font-weight:600;
    font-size:18px;
    font-family: 'Poppins';
  }
}

.order-filter{
  display:flex;
  align-items: center;
  gap:10px;
  width:100%;
  margin-bottom: 10px;
  justify-content: flex-end;

  & select{
    max-width:160px;
  }
}