.plan-term{
    z-index:99999;
    position:fixed;
    padding:20px;
    background-color: #fff;
    transform: translate(-50%, -500%);
    top:50%;
    left:50%;
    border-radius:8px;
    transition:0.2s;
    width:640px;
    max-width: 100%;

    &.active{
        transform: translate(-50%, -50%);
    }

    & .plan-term-title{
        text-align: center;
        margin-bottom:30px;

        & h2{
            font-size: 22px;
            font-weight: 500;
        }

        & h4{
            font-size: 18px;
            font-weight: 500;
            margin-top:15px!important;
        }
    }

    & .plan-term-body{
        max-height: 60vh;
        overflow: auto;
        padding:20px;
        background-color: #f4f4f4;

        @media(max-width:768px){
            max-height: 40vh;
        }

        & h3{
            font-size: 18px;
            font-weight: 600;
            margin-bottom:20px!important;
            margin-top:10px!important;
        }

        & h5{
            font-size: 16px;
            font-weight: 600;
            color:rgb(58, 143, 37);
            margin-bottom:20px!important;
        }

        & p{
            font-size: 16px;
            margin-bottom:10px!important;

            & b{
                font-weight: 600;
            }

            & span{
                color:rgb(58, 143, 37);
            }
        }

        &::-webkit-scrollbar {
            width: 10px; /* width of the entire scrollbar */
          }
          
          &::-webkit-scrollbar-track {
            background: #f4f4f4; /* color of the tracking area */
          }
          
          &::-webkit-scrollbar-thumb {
            background-color: #888; /* color of the scroll thumb */
            border-radius: 5px; /* roundness of the scroll thumb */
            border: 3px solid #f4f4f4; /* creates padding around scroll thumb */
          }
    }

    & .plan-term-footer{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap:10px;

        & .plan-term-accept{
            display: flex;
            align-items: center;
            gap:5px;
            font-size: 16px;
            font-weight:600;

            & input{
                width:20px;
                height:20px;
            }
        }

        & .plan-term-ctas{
            display: flex;
            justify-content: flex-end;
        }
    }
}

.plan-term-overlay{
    display:none;
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: black;
    opacity:0.6;
    z-index:99998;

    &.active{
        display:block;
    }
}