.container_home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 11.5rem;
  height: 650px;
  position: relative;
  overflow-x: clip !important;
  padding-top: 30px;
  background-image: url('../assets/background\ lp.png');
  /* background-image: url('./../../../assets/images/popups/banner_evento_bg.jpg'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 2rem;
}

/* @media(max-width:768px){
  .container_home {
    background-image: url('./../../../assets/images/popups/banner_evento_bg_mobile.png');
  }
} */

.container_home_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 360px;
  text-align: center;
  font-weight: 500;
  font-size: 1.3rem;
  color: #282828;
  font-family: 'Poppins', sans-serif;
}

.arrow {
  position: relative;
  top: 220px;
}

.number {
  width: 100px;
  text-align: right;
  padding-right:10px;
}
.title_number {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  font-size: 2rem;
}
.span {
  position: relative;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.none {
  display: none;
}

.cadore {
  width: 500px;
  position: absolute;
  top: 120px;
  right: 75px;
}

.form {
  width: 300px;
  background-color: rgb(239, 239, 239, .5);
  padding: 2rem .8rem;
  border-radius: .5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@keyframes form_blurred {
  0% { opacity: .8; transform: scale(1); }
  25% { opacity: .9; transform: scale(1.1); }
  50% { opacity: 1; transform: scale(1.2); }
  75% { opacity: .9; transform: scale(1.1); }
  100% { opacity: 1; transform: scale(1); }
}

.form_blurred {
  width: 300px;
  background-color: rgb(239, 239, 239, .5);
  padding: 2rem .8rem;
  border-radius: .5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  -webkit-animation: form_blurred 1s linear ;
  -moz-animation: form_blurred 1s linear ;
  -ms-animation: form_blurred 1s linear ;
  -o-animation: form_blurred 1s linear ;
  animation: form_blurred 1s linear ;
}

.title_form {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #3e5e3f;
}

.subtitle {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem !important;
}

.container_inputs {
  display: flex;
  flex-direction: column;
  gap: .3rem;
}

.input_phone {
  margin-top: 1rem !important;
}

.container_inputs input {
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.container_button button {
  font-family: 'Poppins', sans-serif;
  background-color: #3e5e3f;
  border: none;
  padding: .5rem 4rem;
  color: #fff;
  border-radius: .3rem;
}

.what_perfil {
  text-align: center;
  margin-top:20px!important;
}

.perfil {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.what_perfil {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_button_perfil {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.container_inputs_second {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.button_broken,
.button_real_state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: #f9f9f9;
  color: #000;
  border: 1px solid #cccbcb;
  width: 120px;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: .3rem;
}

.button_broken,
.button_real_state {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.button_broken_active,
.button_real_state_active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: #3e5e3f;
  color: #fff;
  width: 120px;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: .3rem;
  gap: .5rem;
}

.icon_broker {
  width: 26px;
}

.icon_real_state {
  width: 22px;
}

.container_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container_inputs_modal {
  display: flex;
  flex-direction: column;
}

.container_inputs_modal input {
  width: 300px;
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

.container_inputs_modal_second {
  display: flex;
  gap: 1rem;
}

.container_inputs_second {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 0;
}

.container_inputs_second input {
  width: 140px;
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

.button_confirm {
  width: 220px;
  margin-top: 1rem;
}

.modal_title {
  font-size: 13px!important;
  text-align: center!important;
  /* padding-top: 70px; */
  font-weight: 600!important;
  padding-bottom:15px;
}


@media(min-width: 580px) and (max-width: 768px) {
  .cadore {
    width: 500px;
    position: absolute;
    top: 84px;
    right: 264px;
  }
}

@media(min-width: 769px) and (max-width: 1119px) {
  .container {
    text-align: center;
  }
  .container_home {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 500px;
    gap: 0rem;
    margin-bottom: 0px;
    padding-top: 0rem;
    padding-left: 0rem;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .container_home_title {
    width: 438px;
    text-align: center;
    font-size: 23px;
    padding-bottom: 12rem;
  }
  .none {
    display: none;
  }
  .number {
    width: 100px;
    text-align: right;
    padding-right:10px;
  }
  .title_number {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
  }
  .span {
    position: relative;
  }
  .container_inputs_modal input {
    width: 380px;
    height: 38px;
    border: 1px solid #ececec;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: .3rem;
  }
  .cadore {
    width: 600px;
    position: absolute;
    top: 188px;
    right: 522px;
  }
}

@media(min-width:1200px) and (max-width: 1270px) {
  .cadore {
    width: 850px !important;
    position: absolute;
    left: -272px;
    top: 120px;
  }
}

@media(min-width: 1271px) and (max-width: 1480px){
  .container_home {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 500px;
    gap: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 0px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: -6rem;
  }
  .container_home_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
    color: #282828;
    width: 600px;
    padding-top: 0rem;
    padding-bottom: 18rem;
    margin-left: 10rem;
  }
  .container_home img {
    width: 350px;
  }
  .number {
    width: 100px;
    text-align: right;
    padding-right:10px;
  }
  .title_number {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
  }
  .none {
    display: none;
  }
  .cadore {
    width: 850px !important;
    position: absolute;
    left: -155px;
    top: 120px;
  }
}

@media(min-width: 1481px) {
  .container_home {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 500px;
    gap: 4rem;
    padding-bottom: 4rem;
    margin-bottom: 0px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: -6rem;
  }
  .container_home_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 1.4rem;
    color: #282828;
    width: 600px;
    padding-top: 3rem;
    padding-bottom: 18rem;
  }
  .container_home img {
    width: 350px;
  }
  .number {
    width: 100px;
    text-align: right;
    padding-right:10px;
  }
  .title_number {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
  }
  .teste {
    position: relative;
  }
  .none {
    display: none;
  }
  .cadore {
    width: 800px !important;
    position: absolute;
    left: 80px;
    top: 145px;
  }
}

@media(min-width: 2560px) {
  .cadore {
    width: 800px !important;
    position: absolute;
    left: 380px;
    top: 145px;
  }
}

@media(max-width:768px){
  .register-modal{
    max-width:100%;
  }
}

/* .arte_evento{
  width:500px!important;
  object-fit: cover;
  margin-top:33px;
}

.content_info_event{
  display:flex;
  height:500px;
  flex-direction:column;
  gap:10px;
  justify-content:flex-end;
  width:420px;
  padding-left:80px;
  padding-bottom:5px;
}

.content_info_event small{
  color:#fff;
}

.content_info_event p{
  color:#fff;
  font-size:18px;
}

.content_info_event p span{
  font-size:24px;
  font-weight:700;
}

.content_info_event i{
  color:#c4aa84;
  font-size:34px;
}

@media(max-width:1270px){
  .arte_evento{
    width:570px!important;
    margin-top:33px;
  }
  
  .content_info_event{
    height:650px;
    width:320px;
    padding-left:20px;
    padding-bottom:100px;
  }

  .container_home {
    flex-direction: row;
    gap:30px;
  }
}

@media(max-width:1120px){
  .arte_evento{
    width:370px!important;
    margin-top:33px;
  }
  
  .content_info_event{
    height:400px;
    width:320px;
    padding-left:20px;
    padding-bottom:20px;
  }

  .container_home {
    flex-direction: row;
    gap:30px;
  }
}

@media(max-width:768px){
  .arte_evento{
    width:320px!important;
    margin-top:33px;
  }
  
  .content_info_event{
    height:400px;
    width:320px;
    padding-left:20px;
    padding-bottom:40px;
  }

  .container_home {
    flex-direction: column;
    gap:10px;
  }
} */