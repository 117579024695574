.container_reference {
  height: 235px;
  background-color: #f5f4f4;
  margin-top: 6rem;
}

.title {
  text-align: center;
  padding: 2rem 30px 5px!important;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
  font-size:1.7rem;
  margin-bottom: 10px!important;
}

.refence_info {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding-bottom: 1.5rem;
}

.reference {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  width: 150px;
  height: 70px;
  border-radius: .5rem;
}

.reference:last-child {
  margin-right: 1rem;
}

.reference img {
  width: 80px;
}

.wrapper {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 0 20%;
}

@media(min-width: 759px) and (max-width: 1119px){
  .title {
    padding: 2rem 12rem;
    font-weight: 600;
    font-size: 2rem;
  }
  .container_reference {
    /* margin: 6rem 20rem; */
    border-radius: .5rem;
  }
  .wrapper {
    justify-content: center;
  }
  .refence_info {
    margin-left: 0px;
  }
}

@media(min-width: 1200px) {
  .title {
    padding: 0rem;
  }
  .container_reference {
    height: 250px;
    background-color: #f5f5f5;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    border-radius: .5rem;
    border: 1px solid #ccc;
    padding: 0 3rem;
  }
  .refence_info {
    margin-left: 0px;
    padding-bottom: 0px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reference {
    margin-left: 1rem;
  }
}