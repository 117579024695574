#table-six-properties .img-six-properties{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100px;
}

#table-six-properties .img-six-properties img{
    width: 100%;
}

#table-six-properties div table tbody tr td:nth-child(3){
    width: 30%;
}

#table-six-properties .download-six-properties{
    font-size: 22px;
}

#partnershipPage .align-btn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

#partnershipPage .modal-download{
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.388);
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 99999;
    display: none;
}

.open-modal{
    display: flex!important;
}