.cart-plan-content{
    display:flex;
    flex-direction: column;

    @media(max-width:768px){
        padding-bottom:40px;
    }
}

.cart-plan-content .home-plans-card{
    display:flex;
    flex-direction: column;
    gap:5px;
    padding:10px;
    width:100%;
    justify-content: flex-start;
    align-items:flex-start;
    cursor:default;
    transition:0.2s;
    position:relative;
    z-index:1;
  
    & .home-plans-title{
      & h3{
        font-size: 26px;
        font-weight: 800;
      }
    }
  
    & .home-plans-subtitle{
      & p{
        color:#333;
        font-size: 16px;
        max-width:260px;
        text-align: left;
        margin:0 auto;
        font-weight: 600;
      }
    }
  
    & .home-plans-price{
      & h3{
        font-size:48px;
        font-weight: 800;
        color:#333;
    
        & small{
          font-size: 18px;
        }
      }
    }
  
    & .home-plans-cta{
      & button{
        width:280px;
        white-space: nowrap;
        height: 45px;
        background:#3E5E3F;
        border:none;
        font-size:18px;
        font-weight: 600;
        color:#fff;
  
        &:hover{
          background-color: #273f28;
        }
      }
    }
  
    &.start{
      & .home-plans-cta{
        & button{
          background:#356BA9;
    
          &:hover{
            background-color: #1e3d61;
          }
        }
      }
    }
  
    &.free{
      & .home-plans-cta{
        & button{
          background:#333;
    
          &:hover{
            background-color: #222;
          }
        }
      }
    }
  
    & .home-plans-selected-year{
      display:flex;
      align-items: center;
      gap:5px;
  
      & p{
        color:#888;
        font-size:18px;
        text-decoration: line-through;
      }
  
      & span{
        background-color: #709b71;
        color:#fff;
        padding:3px 8px;
        border-radius: 20px;
        font-size: 16px;
        font-weight:800;
        animation: pulse 2s infinite;
      }
    }
  
    & .home-plans-selected{
      & p{
        color:#333;
        font-size:18px;
        margin-bottom:-20px!important;
      }
    }
  
    & .home-plans-observation{
      & p{
        font-size: 14px;
        color:#333;
        max-width:280px;
        text-align: center;
        margin:0 auto;
        line-height: 1.4;
      }
  
      & a{
        font-weight:600;
        margin-top: -10px!important;
        display: block;
      }
    }
  
    & .home-plans-rec{
      display:flex;
      flex-direction: row;
      align-items: center;
  
      & .home-plans-rec-option{
        width:100px;
        height:35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:#fff;
        color:#333;
        border:solid 1px #333;
        cursor:pointer;
        text-transform: uppercase;
        font-weight: 800;
  
        &.active{
          background-color: #333;
          color:#fff;
        }
        &:hover{
          background-color: #555;
          color:#fff;
        }
      }
    }
  }
  
//   .cart-plan-content.horizontal .home-plans-card{
//     width:360px;
//   }
  
  .list-open{
    display:none;
  
    @media(max-width:1024px){
      background-color: #dadee2;
      width:100%;
      display:flex;
      align-items: center;
      justify-content: space-around;
      gap:8px;
      padding:5px;
      border-radius:0 0 20px 20px;
      cursor:pointer;
      height:40px;
      border-top:none;
  
      & span{
        font-size: 18px;
        font-style: uppercase;
      }
  
      & i{
        font-size: 24px;
        transition:0.2s;
      }
  
      &.active{
        & i{
          transform: rotate(180deg);
        }
      }
    }
  }
  
  .home-plans-list{
    padding:10px;
  
    & h5{
      font-size: 18px;
      color:#888;
      font-style: italic;
      margin-bottom:20px!important;
    }
  
    & ul{
      list-style: none;
      padding-left: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  
      & li{
        font-size: 16px;
        display: flex;
        padding:4px 0;
        font-weight: 400;
        color:#333;
        gap:8px;
  
        &.high{
          font-size: 16px;
          color:#3E5E3F;
          font-weight: 800;
        }
  
        & i{
          font-size:16px;
          margin-top:-2px;
        }
      }
    }
  }
  
  @media(max-width:1024px){
    .home-plans-list{
      height:0;
      overflow: hidden;
      padding:0;
  
      &.active{
        height:auto;
        padding:20px;
      }
    }
  }
  
  .cart-plan-content.horizontal .home-plans-list{
    width:100%;
  
    h4{
      color:#3E5E3F;
      margin-bottom:10px!important;
      font-size: 18px;
      margin-top:0px!important;
    }
  }
  
  .cart-plan-content.horizontal .home-plans-list ul{
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
  
  .home-plans-select-type{
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap:30px;
    margin-top:60px;
  
  
    & h2{
      color:#3E5E3F;
      font-size:28px;
      font-weight:500;
    }
  
    & h3{
      font-size:32px;
      font-weight: 600;;
    }
  
    & p{
      color:#333;
      font-size: 18px;
    }
  
    & .home-plans-type-options{
      display:flex;
      gap:20px;
  
      & .home-plans-type-button{
        width:120px;
        height:40px;
        background-color: #888;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        font-size: 18px;
        cursor:pointer;
  
        &.active{
          background-color: #3E5E3F;
        }
      }
    }
  }