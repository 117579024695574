.userType.imoveis .userNumbers .card{
  height:40px;
  border-radius: 8px;
  width:200px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color:#fff;
  font-size: 14px;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  transition:0.3s;
  padding-left:15px;
}

.userType.imoveis .userNumbers{
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  gap:10px;
}

.userType.imoveis .immobileNumbers .card{
  height:30px;
  border-radius: 15px;
  width:auto;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color:#fff;
  font-size: 12px;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  transition:0.3s;
  padding:10px;
  text-align: center;
  background-color: #cdcdcd;
  cursor:pointer;
}

.cardGraphic{
  height:30px;
  border-radius: 15px;
  width:auto;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color:#fff;
  font-size: 12px;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  transition:0.3s;
  padding:10px;
  text-align: center;
  background-color: #cdcdcd;
  cursor:pointer;
}

.userType.imoveis .immobileNumbers{
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap:10px;
  margin-bottom:30px;
}

.userType.imoveis  .pieChart{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.userType.imoveis  .item.total{
  display:flex;
  padding:5px 15px;
  border-radius: 25px;
  color:#888;
  flex-direction: column;
  gap:20;
  font-size: 20px;
  font-weight: 300;
}

.userType.imoveis  .item.total span{
  font-size: 28px;
  color:rgb(98, 201, 14);
  font-weight: 800;
}

.userType.imoveis .totalImmobileValue{
  align-items: flex-end;
  display: flex;
  gap:20px;
  margin-top: 40px;
}

.userType.imoveis .pieChart{
  margin-bottom:60px!important;
}