.payment{
    display: flex;
    flex-direction: column;
    height: 470px;
}

.payment h1{
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 10px!important;
    color: #3e5e3f;
}

.payment .input-line{
    display: flex;
    gap: 5px;
}

.payment .input-line input{
    width: 100%;
}


.payment select,
.payment input{
    border: 1px solid lightgrey;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
    outline: none;
    background-color: white;
    transition: .3s;
    color: rgb(103, 103, 103);
}

.payment select:focus,
.payment input:focus{
    border: 1px solid #3e5e3f;
}

.payment img{
    width: 50%;
}

.payment .copy-btn{
    width: max-content;
    cursor: pointer;
    transition: .3s;
    outline: none;
}

.payment .copy-btn svg{
    width: 20px;
}

.coppied{
    background-color: #0c9510!important;
    outline: none;
    border: 1px solid #3e5e3f;
    border-radius: 3px;
    transition: .3s;
}

.coppied svg{
    color: #fff;
}

.img-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-content button{
    background-color: unset;
    border: 1px solid var(--green);
    border-radius: 6px;
    transition: .3s;
    outline: none;   
    cursor: pointer;
    margin: 30px 0px 5px 0px;
    margin-right: auto;
}

.img-content button:hover{
    background-color: #3e5e3f24;
    border: 1px solid var(--green);
}

.img-content button svg{
    width: 50px;
}

.img-content span{
    margin-right: auto!important;
    margin-bottom: 20px!important;
    font-size: 12px!important;
    color: #3e5e3f;
}