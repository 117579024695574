.accordion {
  margin-bottom: 1rem;
  padding: 0 2.5rem;
  width:100%;
}

.accordionHeader {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.accordionIcon {
  font-size: 1.2rem;
}

.accordionContent {
  padding: 1rem;
  border: 1px solid #f5f5f5;
  border-top: none;
  font-size: .875rem;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

@media(min-width: 759px) and (max-width: 1119px) {
  .accordion {
    width: 600px;
  }
  .accordionHeader {
    justify-content: space-between;
    gap: 10rem;
  }
}

@media(min-width: 1200px) {
  .accordion {
    width: 900px;
  }
  .accordionHeader {
    padding: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20rem;
    border-bottom: 1px solid;
  }
}