.container_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #fff;
}

.logo {
  width: 100px;
  margin-left: 3rem;
}

.login {
  text-decoration: none;
  color: #3e5e3f; 
  border: 1px solid #3e5e3f;
  border-radius: 2rem;
  padding: .2rem .7rem;
  font-size: .9rem;
  margin-right: .5rem;
  font-family: 'Poppins', sans-serif;
  font-weight:500;
}

.login:hover {
  background-color: #3e5e3f;
  color: #FFF;
}

.menu {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 3rem;
  cursor: pointer;

}

.details {
  background-color: #3e5e3f;
  border-radius: 4px;
  display: block;
  height: 2px;
  margin: 4px 0 4px -3px;
  width: 22px;
}

.menu_itens {
  position: absolute;
  background-color: #FFF;
  width: 200px;
  right: 44px;
  z-index: 100;
  top: 58px;
  border-radius: .5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.links {
  padding: .5rem 1rem;
  margin-bottom: .5rem;
}

.links li {
  list-style-type: none;
  font-size: .9rem;
  margin-bottom: .5rem;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.links li a {
  text-decoration: none;
  color: #000;
}

.links li a:hover {
  color: #3e5e3f;
}

.close {
  display: flex;
  justify-content: flex-end;
}

.icon {
  margin-right: 10px !important;
  margin-top: 10px !important;
}

.overlay{
  position:fixed;
  z-index:99;
  top:60px;
  bottom:0;
  right:0;
  left:0;
  width:100%;
  height:100%;
  background-color: rgba(0,0,0,0.7);
}

/* MODAL */

.form {
  width: 300px;
  margin-right: -8rem;
  background-color: #efefef;
  padding: 2rem .8rem;
  border-radius: .5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.title_form {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #3e5e3f;
}

.subtitle {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem !important;
}

.container_inputs {
  display: flex;
  flex-direction: column;
  gap: .3rem;
}

.input_phone {
  margin-top: 1rem !important;
}

.container_inputs input {
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.container_button button {
  font-family: 'Poppins', sans-serif;
  background-color: #3e5e3f;
  border: none;
  padding: .5rem 4rem;
  color: #fff;
  border-radius: .3rem;
}

.what_perfil {
  text-align: center;
}

.perfil {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.what_perfil {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_button_perfil {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.container_inputs_second {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.button_broken,
.button_real_state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: #f9f9f9;
  color: #000;
  border: 1px solid #cccbcb;
  width: 120px;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: .3rem;
}

.button_broken,
.button_real_state {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.button_broken_active,
.button_real_state_active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: #3e5e3f;
  color: #fff;
  width: 120px;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: .3rem;
  gap: .5rem;
}

.icon_broker {
  width: 26px;
}

.icon_real_state {
  width: 22px;
}

.container_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container_inputs_modal {
  display: flex;
  flex-direction: column;
}

.container_inputs_modal input {
  width: 380px;
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

@media(max-width:768px){
  .container_inputs_modal input {
    width: 100%;
  }

  .container_inputs_modal {
    width: 100%;
  }
}

.container_inputs_modal_second {
  display: flex;
  gap: 1rem;
}

.container_inputs_second {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 0;
}

.container_inputs_second input {
  width: 180px;
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

@media(max-width:768px){
  .container_inputs_second input {
    width: 100%;
  }
}

.button_confirm {
  width: 220px;
  margin-top: 1rem;
}