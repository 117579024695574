.Filters h3{
  font-size: 16px;
  text-align: left;
}

.sectionFilter1, .sectionFilter2, .sectionFilter3, .sectionFilter4, .sectionFilter5, .sectionFilter6, .sectionFilter7{
  width:100%;
}

.sectionFilter1 .option{
  width:100%;
  height:80px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color:#f4f4f4;
  border:solid 1px #cdcdcd;
  color:#333;
  font-size:12px;
  cursor:pointer;
  flex-direction: column;
}

.sectionFilter1 .option.active{
  background-color:#3E5E3F;
  color:#fff;
  border:none;
}

.sectionFilter1 .option .icon{
  width:30px;
  height:30px;
  margin-bottom:5px;
  background-position:center center;
  background-size:cover;
}

.sectionFilter1 .option#house .icon{
  background-image: url('./../../../images/Icons/house_green.png');
}

.sectionFilter1 .option.active#house .icon{
  background-image: url('./../../../images/Icons/house_white.png');
}

.sectionFilter1 .option#apartment .icon{
  background-image: url('./../../../images/Icons/building_green.png');
}

.sectionFilter1 .option.active#apartment .icon{
  background-image: url('./../../../images/Icons/building_white.png');
}

.sectionFilter1 .option#outros .icon{
  background-image: url('./../../../images/Icons/terrain_green.png');
}

.sectionFilter1 .option.active#outros .icon{
  background-image: url('./../../../images/Icons/terrain_white.png');
}

.sectionFilter1 .option#commercial .icon{
  background-image: url('./../../../images/Icons/shop_green.png');
}

.sectionFilter1 .option.active#commercial .icon{
  background-image: url('./../../../images/Icons/shop_white.png');
}

.sectionFilter2 .locationList{
  list-style: none;
  padding-left:0;
  display:inline-flex;
  width: calc(100% - 40px);
  gap:5px;
  max-width:100%;
  flex-wrap: wrap;
}

.sectionFilter2 .badge-soft-success{
  display:flex;
  padding:3px;
  gap:10px;
  font-size: 12px;
  cursor:pointer;
}


.sectionFilter3 .colInputPrice{
  display: flex;
  flex-direction: column;
}

.sectionFilter3 .colInputPrice input{
  height:40px;
  border-radius:5px;
  border:solid 1px #cdcdcd;
  text-align: right;
  padding:0 8px;
}

.sectionFilter4{
  margin-bottom:20px;
}

.sectionFilter4 .colInputPrice{
  display: flex;
  flex-direction: column;
}

.sectionFilter4 .colInputPrice input{
  height:40px;
  border-radius:5px;
  border:solid 1px #cdcdcd;
  text-align: right;
  padding:0 8px;
}

.sectionFilter3{
  margin-bottom:20px;
}

.sectionFilter7 .negociation-container{
  display: inline-flex;
  gap:10px;
  flex-wrap:wrap;
  margin:10px 0 20px;
}

.sectionFilter7 .negociation-container .option{
  background-color: #f4f4f4;
  border-radius:10px;
  padding:8px 10px;
  color:#333;
  font-size: 12px;
  cursor:pointer;
}

.sectionFilter7 .negociation-container .option.active{
  background-color: #3E5E3F;
  color:#fff;
}

.sectionFilter5 .rooms-container{
  display:inline-flex;
  flex-wrap:wrap;
  gap:8px;
  justify-content: flex-start;
  align-items: flex-start;
  width:100%;
  margin:10px auto 20px;
}

.sectionFilter5 .rooms-container .option{
  background-color: #f4f4f4;
  border-radius:10px;
  width:32px;
  height:32px;
  color:#333;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor:pointer!important;
}

.sectionFilter5 .rooms-container .option.active{
  background-color: #3E5E3F;
  color:#fff;
}

.sectionFilter6 .garages-container{
  display:inline-flex;
  flex-wrap:wrap;
  gap:8px;
  justify-content: flex-start;
  align-items: flex-start;
  width:100%;
  margin:10px auto 20px;
}

.sectionFilter6 .garages-container .option{
  background-color: #f4f4f4;
  border-radius:10px;
  width:32px;
  height:32px;
  color:#333;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor:pointer!important;
}

.sectionFilter6 .garages-container .option.active{
  background-color: #3E5E3F;
  color:#fff;
}

@media(min-width: 1100px) {
  .none-text-filter {
    display: none;
  }
  .teste {
    justify-content: center;
  }
}