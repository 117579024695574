.bg {
  background-color: #cdcdcd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
}

.bg_logo {
  display: flex;
  align-items: center;
  background-color: #FFF;
  opacity: .7;
  height: 60px;
}

.bg_logo img {
  width: 60px;
  padding: .5rem 0;
}

.title h1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: #FFF;
  margin-top: 6rem;
}

/* SEARCH */

.icon_lupa {
  position: absolute;
  right: 292px;
}

.container_search {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(.7);
}

.container_search div { 
  height: 55px;
  margin-top: 3rem;
  position: relative;
}

/* HOUSE */

.search_house {
  display: flex;
  align-items: center;
}

.search_house select {
  border: none;
  background-color: #FFF;
  width: 170px;
  height: 40px;
  padding-left: 2rem;
  margin-left: 2rem;
}

.icon_house {
  position: absolute;
  right: 138px;
}

/* VACANCIES */

.search_vacancies {
  display: flex;
  align-items: center;
}

.search_vacancies select {
  border: none;
  background-color: #FFF;
  width: 140px;
  height: 40px;
  padding-left: 2rem;
  margin-left: 2rem;
}

.icon_car {
  position: absolute;
  right: 108px;
}


/* BEDROOM */

.search_bedroom {
  display: flex;
  align-items: center;
}

.search_bedroom select {
  border: none;
  background-color: #FFF;
  width: 140px;
  height: 40px;
  padding-left: 2rem;
  margin-left: 2rem;
}

.icon_bedroom {
  position: absolute;
  right: 108px;
}

/* PRICE */

.search_price {
  display: flex;
  align-items: center;
}

.search_price input {
  border: none;
  background-color: #FFF;
  width: 200px;
  height: 40px;
  padding-left: 2rem;
  margin-left: 2rem;
}

.search_price input::placeholder {
  color: #000;
}

.icon_price {
  position: absolute;
  right: 170px;
}

/* IMOBILE */

.search_imobile {
  display: flex;
  align-items: center;
}

.search_imobile input {
  border: none;
  background-color: #FFF;
  width: 290px;
  height: 40px;
  padding-left: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.search_imobile input::placeholder {
  color: #000;
}

/* BUTTON */

.button_search_third {
  height: 55px;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-left: 2rem;
}

.container_button button {
  background-color: #f9af24;
  border: none;
  padding: .5rem 2.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: .3s;
}

.container_button button:hover {
  background-color: #000;
  color: #f9af24;
}


/*! CONSTRUCTOR SITE */

.semLogo{
  background-color: #cdcdcd;
  color:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  margin-left: 2rem;
}

.container_logo{
  position:relative;
}

.inputLogo{
  background-color: #acaaaa;
  border-radius: 50%;
  position: absolute;
  bottom:0px;
  height:25px;
  width:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

.actionIcons{
  position: absolute;
  left:20px;
  top:120px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.actionIconsButton {
  position: absolute;
  left: 995px;
  top: 155px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIconsButton:hover {
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top:-30px;
  left:auto;
}

.container_button .actionIcons{
    top: -100px;
    left: -80px;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.actionIconsTitle{
  position: absolute;
  left: 500px;
  top: 70px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIconsTitle:hover{
  opacity:1;
}

.titleContainer .actionIconsTitle{
  right:calc(50% - 67px);
  top:-30px;
  left:auto;
}

.container_button .actionIconsTitle{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIconsTitle label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIconsTitle span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIconsTitle i{
  font-size: 18px;
  cursor:pointer;
}

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:auto;
  font-family: 'Poppins';
  margin-top: 2rem;
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

.imageOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
}

.imageOptions label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  margin-bottom: 10px !important;
  border: 1px solid #f4f4f4;
  padding: 1rem !important;
  border-radius: .3rem;
}

.imageOptions label:hover {
  background-color: #f0f0f0;
}

.imageOptions input[type="radio"] {
  margin-right: 10px;
}

.imageOptions .imagePreview {
  width: 160px;
  height: 80px;
  object-fit: cover;
  border-radius: .3rem;
}

.selected {
  border: 5px solid #c4c4c4 !important;
}

.title_options {
  font-size: 1.2rem;
  margin: 1rem 0 !important;
}