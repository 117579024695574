.container_footer {
  height: auto;
  padding-top: 4rem;
  background-color: #f5f4f4;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  overflow-y: clip;
  /* position: relative; */
  gap: 1rem;
  padding-bottom: 1rem;
  position:relative;
}

.container_footer::before{
  content:'';
  width:100%;
  height:100px;
  background-color: #fff;
  position:absolute;
  top:0;
  z-index:1;
}

.photos_footer_mobile {
  position: absolute;
  top: 42px;
  left: 41px;
  width: 180px;
}

.info_footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.container_img img {
  width: 80px;
  height: 80px;
}

.info {
  font-size: .8rem;
  text-align: left;
}

.info p {
  margin-bottom: 0px;
}

.telephone {
  margin-left: 20px!important;
  padding-bottom: 1rem!important;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.container_address {
  display: flex;
}

.address {
  margin-left: 1rem;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.container_email {
  display: flex;
  align-items: center;
  margin-top: .5rem;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.container_email a {
  text-decoration: underline;
  color: #212529;
}

.email {
  margin-left: 1rem;
}

.nets {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: -12px;
}

.img {
  width: 24px !important;
  height: 24px !important;
}

.none {
  display: none;
}

.link {
  margin-left: 1.5rem;
  text-decoration: solid;
}

.link:hover {
  color: green;
}

.container_phrase {
  text-align: center;
  font-size: 16px;
  color: #3e533f;
  font-family: 'Poppins';
  margin-bottom: 2rem;
  display:flex;
  align-items: center;
  justify-content: flex-start;
  margin-top:-100px;
}

.container_phrase img{
  max-width:calc(100% - 220px);
  position: relative;
  z-index:2;
  margin-top:40px;
}

.container_phrase p{
  max-width:260px;
  font-weight: 600;
  padding-top:100px!important;
  margin-left:-60px!important;
}

/* .photos_footer {
  position: absolute;
  top: 47px;
  left: 500px;
  width:27%;
} */

.container_img {
  margin-left: 12rem;
  margin-top: 1rem;
}

@media(min-width: 759px) and (max-width: 1119px) {
  .container_footer {
    display: flex;
    flex-direction: row!important;
  }
  .info_footer {
    display: flex;
    flex-direction: row;
    gap: 10rem;
  }
  .container_img img  {
    width: 80px;
    height: 80px;
  }
  .container_address {
    display: flex;
    margin-bottom: .5rem;
  }
  .address {
    margin-left: .2rem;
  }
  .container_email {
    display: flex;
    align-items: center;
  }
  .email {
    margin-left: .2rem;
    padding-top: 0px;
  }
  .nets {
    display: flex;
    align-items: center;
    justify-content: initial;
    gap: 2rem;
    margin-top: .5rem;
  }
  .img {
    width: 24px;
  }
  .container_img {
    margin-left: 6rem;
    margin-top: 1rem;
  }

  .container_phrase {
    margin-top:-60px;
  }
  .container_footer::before{
    height:70px;
  }
}

@media(min-width: 759px) and (max-width:1000px){
  .container_phrase img{
    display:none;
  }
  .container_phrase {
    margin-top:0px;
  }
  .container_phrase p{
    padding-top:0!important;
    margin-left:0!important;
    padding-left:50px!important;
  }
  .container_footer::before{
    display:none;
  }
  .container_img img {
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    top: 100px;
    left: 120px;
  }
}

@media(min-width: 1120px) and (max-width: 1399px) { 
  .container_footer {
    display: flex;
    flex-direction: row;
  }
  .info_footer {
    display: flex;
    flex-direction: row;
  }
  .container_img{
    width:120px;
  }
  .container_img img {
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    top: 150px;
    left: 120px;
  }
  .info_footer {
    gap: 10rem;
  }
  .nets {
    display: flex;
    align-items: center;
    justify-content: initial;
    gap: 1rem;
    margin-top: .5rem;
  }
  .img {
    width: 24px;
  }
  .container_phrase {
    text-align: center;
    font-size: 20px;
    color: #3e533f;
    font-family: 'Poppins';
  }
  .container_phrase {
    margin-top:-60px;
  }
  .container_footer::before{
    height:70px;
  }
  
}

@media(min-width: 1400px) and (max-width: 1480px) {
  .container_footer {
    display: flex;
    flex-direction: row;
  }
  .info_footer {
    display: flex;
    flex-direction: row;
  }
  .container_img{
    width:120px;
  }
  .container_img img {
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    top: 220px;
    left: 180px;
  }
  .info_footer {
    gap: 10rem;
  }
  .nets {
    display: flex;
    align-items: center;
    justify-content: initial;
    gap: 1rem;
    margin-top: .5rem;
  }
  .img {
    width: 24px;
  }
  .container_phrase {
    text-align: center;
    font-size: 20px;
    color: #3e533f;
    font-family: 'Poppins';
  }
}


@media(min-width: 1481px) {
  .container_footer {
    display: flex;
    flex-direction: row;
  }
  .info_footer {
    display: flex;
    flex-direction: row;
  }
  .container_img{
    width:120px;
  }
  .container_img img {
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    top: 220px;
    left: 220px;
  }
  .info_footer {
    gap: 10rem;
  }
  .nets {
    display: flex;
    align-items: center;
    justify-content: initial;
    gap: 1rem;
    margin-top: .5rem;
  }
  .img {
    width: 24px;
  }
  .container_phrase {
    text-align: center;
    font-size: 20px;
    color: #3e533f;
    font-family: 'Poppins';
  }
  .container_phrase img{
    margin-left:-50px;
  }
}

@media(max-width:768px){
  .container_footer::before{
    display:none;
  }
  .container_img {
    margin-left: 0!important;
    margin-top: 0!important;
  }
  .container_phrase p{
    margin-left:10px!important;
  }
  .container_phrase img{
    margin-left:-50px;
  }
}