.bg {
  background-color: #cdcdcd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding_mobile {
  margin-bottom: -4rem;
}

.bg h1 {
  text-align: center;
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: 600;
}

.container_search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 335px;
  margin-left: .3rem;
}

.container_search input {
  width: 300px;
  height: 50px;
  border: 1px solid #e8e8e8;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(142, 141, 141, 0.1) 0px 4px 6px -1px, rgba(143, 143, 143, 0.06) 0px 2px 4px -1px;
  border-radius: 2rem;
  padding-left: 1.5rem;
}

.container_search input::placeholder {
  font-size: .6rem;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 3rem;
  padding: .4rem;
  position: relative;
  right: 48px;
}

/* MODAL */

.container_modal_filter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_modal_filter button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .2rem;
  border: none;
  padding: .4rem 1.2rem;
  border-radius: .5rem;
  background-color: rgb(224, 224, 224);
  font-size: .8rem;
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: -10px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: rgb(236, 236, 236);
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  max-width: 500px;
  position: relative;
  z-index: 1001;
}

.modalContent img{
  width: 32px;
}

.container_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* FILTER */

.container_category {
  display: flex;
  margin-top: 1rem;
}

.container_category {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid gray;
  border-radius: .5rem;
  padding: 1rem .5rem;
  margin-bottom: 1rem;
  width: 105px;
}

.category_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.category_item p {
  font-size: .8rem;
  margin-top: .5rem;
}

.vacancies,
.bedrooms {
  margin-top: 2rem;
}

.numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.3rem;
  margin-top: 1rem;
}

.numbers span {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: .4rem;
  padding: .2rem .8rem;
  width: 38px;
}

.value {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.value input {
  width: 270px;
  border: 1px solid #e9e9e9;
  border-radius: .5rem;
  margin-top: 1rem;
  padding-left: .5rem;
  height: 42px;
}

.selected {
  border: 2px solid #007bff !important; 
}

/* CARDS */

.bg_second {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 230px;
  margin: 1.5rem 3rem 0rem 2.1rem;
  border-radius: 0.5rem;
  width: 250px;
}

.container_immobile {
  background-color:rgb(236, 236, 236);
  margin: 1.5rem 3rem 0rem 2.1rem;
  position: relative;
  top: -70px;
  width: 250px;
  border-radius: 0 0 .5rem .5rem;
}

.title_immobile {
  text-align: left;
  margin-left: 1rem;
  margin-bottom: .5rem;
}

.immobile {
  font-size: .8rem;
  font-weight: 500;
  padding-top: 1.5rem !important;
  padding-right: 0.5rem !important;
  height: 63px;
}

.config_immobile {
  display: flex;
  justify-content: center;
  gap: 1rem;
  font-size: .7rem;
  padding-top: .5rem;
}

.container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
}

.container_price_slide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
  padding-bottom: 1rem;;
}

.container_price_slide p {
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  padding: .1rem 1.5rem;
  font-weight: 600;
}

.view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 .5rem;
}

.container_location {
  display: flex;
  align-items: center;
}

.icon_maps {
  margin-bottom: 1rem;
}

.location {
  font-size: .7rem;
  margin-bottom: .9rem !important;
}

.view_immobile {
  margin-bottom: .8rem !important;
  border-radius: 1rem;
  padding: .1rem 1rem !important;
  font-size: .7rem;
  color: #fff;
  background-color: #000;
}

/* ACTIONS */

.actionIcons{
  position: absolute;
  left: 50px;
  top: 100px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
  z-index: 99999;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top: 5px;
  left:auto;
}

.container_button .actionIcons{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.titleEdit {
  text-align: center;
  padding: 2rem 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 28px;
  margin:auto;
  font-family: 'Poppins';
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

.fake_pages {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4rem;
  margin-right: 2rem;
  gap: 1rem;
}

.arrow_page {
  background-color: #cdcdcd;
  padding: 0.5rem;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
}

.page_now {
  background-color: #cdcdcd;
  padding: 0.5rem;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
}

.page_next {
  background-color: #f6f6f6;
  padding: 0.5rem;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
}