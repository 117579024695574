.bg {
  background-image: url('../../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 320px;
}

.bg_logo {
  display: flex;
  align-items: center;
  background-color: #FFF;
  opacity: .7;
}

.bg_logo img {
  width: 50px;
  padding: .5rem 0;
}

.title h1 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #FFF;
  margin-top: 6rem;
}

/* SEARCH */

.container_search {
  background-color: #343434;
  width: 100%;
}

/* HOUSE */

.search_imobile {
  display: flex;
  justify-content: center;
}

.search_imobile input {
  height: 42px;
  margin: 1rem 1rem 0 1rem;
  width: 90%;
  padding-left: 2.5rem;
  border: none;
}

.search_imobile input::placeholder {
  color: #101010;
}

.icon_lupa {
  position: absolute;
  left: 30px;
  top: 350px;
}

/* IMOBILE */

.type_imobile {
  display: flex;
  justify-content: center;
}

.type_imobile select {
  height: 42px;
  margin: .5rem;
  width: 90%;
  padding-left: 2.5rem;
  border: none;
}

.icon_house {
  position: absolute;
  left: 30px;
  top: 400px;
}

/* VACANCIES AND BEDROOMS */

.container_vacancies_bedrooms {
  display: flex;
  justify-content: center;
  gap: .5rem;
}

.search_vacancies select,
.search_bedrooms select {
  height: 42px;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  width: 131px;
  padding-left: 2.5rem;
  border: none;
}

.icon_car {
  position: absolute;
  left: 30px;
  top: 450px;
}

.icon_bedroom {
  position: absolute;
  right: 118px;
  top: 450px;
}

/* PRICE */

.search_price {
  display: flex;
  justify-content: center;
}

.search_price input {
  height: 42px;
  margin: 0rem 1rem 1rem 1rem;
  width: 90%;
  padding-left: 2.5rem;
  border: none;
}

.search_price input::placeholder {
  color: #101010;
}

.icon_price {
  position: absolute;
  left: 30px;
  top: 500px;
}

/* BUTTON */

.container_button {
  display: flex;
  justify-content: center;
}

.container_button button {
  background-color: #f9af24;
  color: #FFF;
  border: none;
  margin-bottom: 1rem;
  height: 42px;
  width: 90%;
  font-weight: 600;
}

/*! CONSTRUCTOR SITE */

.semLogo{
  background-color: #cdcdcd;
  color:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  margin-left: 2rem;
}

.logo {
  margin-top: .3rem;
  /* margin-left: 2rem; */
  width: 70px;
}

.container_logo{
  position:relative;
}

.inputLogo{
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top:0px;
  height:25px;
  width:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

.actionIcons{
  position: absolute;
  left:20px;
  top:120px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top:-30px;
  left:auto;
}

.container_button .actionIcons{
    /* right: -70px; */
    top: 535px;
    left: auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.actionIconsTitle{
  position: absolute;
  left: 30px;
  top: 200px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIconsTitle:hover{
  opacity:1;
}

.titleContainer .actionIconsTitle{
  right:calc(50% - 67px);
  top:-30px;
  left:auto;
}

.container_button .actionIconsTitle{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIconsTitle label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIconsTitle span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIconsTitle i{
  font-size: 18px;
  cursor:pointer;
}

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:auto;
  font-family: 'Poppins';
  margin-top: 4rem;
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

.imageOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
}

.imageOptions label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  margin-bottom: 10px !important;
  border: 1px solid #f4f4f4;
  padding: 1rem !important;
  border-radius: .3rem;
}

.imageOptions label:hover {
  background-color: #f0f0f0;
}

.imageOptions input[type="radio"] {
  margin-right: 10px;
}

.imageOptions .imagePreview {
  width: 160px;
  height: 80px;
  object-fit: cover;
  border-radius: .3rem;
}

.selected {
  border: 5px solid #c4c4c4 !important;
}

.title_options {
  font-size: 1.2rem;
  margin: 1rem 0 !important;
}