/*! NAVBAR */

.bg_logo {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  opacity: .7;
}

.bg_logo img {
  width: 50px;
  padding: .5rem 0;
}

.container_filter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_filter button {
  width: 100%;
  border: none;
  background-color: #f9af24;
  color: #FFF;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

/* SEARCH */

.container_search {
  background-color: #101010;
  width: 100%;
}

/* HOUSE */

.search_imobile {
  display: flex;
  justify-content: center;
}

.search_imobile input {
  height: 42px;
  margin: 1rem 1rem 0 1rem;
  width: 90%;
  padding-left: 2.5rem;
  border: none;
}

.search_imobile input::placeholder {
  color: #101010;
}

.icon_lupa {
  position: absolute;
  left: 33px;
  top: 278px;
}

/* IMOBILE */

.type_imobile {
  display: flex;
  justify-content: center;
}

.type_imobile select {
  height: 42px;
  margin: .5rem;
  width: 90%;
  padding-left: 2.5rem;
  border: none;
}

.icon_house {
  position: absolute;
  left: 35px;
  top: 328px;
}

/* VACANCIES AND BEDROOMS */

.container_vacancies_bedrooms {
  display: flex;
  justify-content: center;
  gap: .5rem;
}

.search_vacancies select,
.search_bedrooms select {
  height: 42px;
  margin: 0rem 0.5rem 0.5rem 0.5rem;
  width: 112px;
  padding-left: 2.5rem;
  border: none;
}

.icon_car {
  position: absolute;
  left: 36px;
  top: 376px;
}

.icon_bedroom {
  position: absolute;
  right: 104px;
  top: 376px;
}

/* PRICE */

.search_price {
  display: flex;
  justify-content: center;
}

.search_price input {
  height: 42px;
  margin: 0rem 1rem 1rem 1rem;
  width: 90%;
  padding-left: 2.5rem;
  border: none;
}

.search_price input::placeholder {
  color: #101010;
}

.icon_price {
  position: absolute;
  left: 38px;
  top: 426px;
}

/* BUTTON */

.container_button_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 1rem 1rem;
}

.container_button_nav button {
  margin-bottom: 1rem;
  width: 100px;
  height: 38px;
  border: none;
}

.close {
  background-color: #f6f6f6;
  color: rgb(98, 98, 98);
  font-weight: 600;
}

.search {
  background-color: #f9af24;
  color: #FFF;
  font-weight: 600;
}

.hide {
  display: none;
}

/*! SWIPER */

.title {
  text-align: center;
  margin-top: 2rem;
  font-weight: 500;
}

/* CAROUSEL */

.container_carousel {
  max-width: 1200px;
  margin: auto;
  padding-top: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}

/* CONTAINER CAROUSEL */

.container_imobile {
  width: 310px;
  height: 320px;
  margin-left: 3.4rem;
}

.bg_teste {
  background-image: url('../../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 240px;
  margin-left: .2rem;
}

.container_config {
  padding: 0.8rem;
  background-color: #F6F6F6;
  width: 240px;
  margin: 0 -1rem;
  position: absolute;
  top: 125px;
  margin-left: .2rem;
}

.name {
  font-size: .9rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.price {
  font-weight: 600;
  margin-bottom: .5rem !important;
  margin-top: .5rem !important;
}

.price span {
  font-size: 1.2rem;
}

.container_config_imobile {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.container_config_imobile span {
  display: flex;
  align-items: center;
  font-size: .8rem;
  margin-bottom: 1rem;
}

.details::after {
  content: "";
  display: block;
  height: 1px;
  width: 114px;
  background-color: #c6c4c4;
}

.container_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
  font-size: .8rem;
}

.view {
  background-color: #f9af24;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem .5rem !important;
  cursor: pointer;
  transition: .3s;
}

.view:hover {
  color: #f9af24;
  background-color: #000;
}

/* BUTTON */

.container_button {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.container_button button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  border: none;
  background-color: #f9af24;
  color: #FFF;
  padding: .5rem 0;
  font-weight: 600;
}

/*! CONSTRUCTOR SITE */

.semLogo{
  background-color: #cdcdcd;
  color:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  margin-left: 2rem;
}

.logo {
  margin-top: .3rem;
  width: 70px;
}

.container_logo{
  position:relative;
}

.inputLogo{
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top:0px;
  height:25px;
  width:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}

.actionIcons{
  position: absolute;
  left:20px;
  top: 80px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top:-30px;
  left:auto;
}

.container_button .actionIcons{
    /* right: -70px; */
    top: 535px;
    left: auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.actionIconsTitle{
  position: absolute;
  left:360px;
  top:120px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIconsTitle:hover{
  opacity:1;
}

.titleContainer .actionIconsTitle{
  right:calc(50% - 67px);
  top:-30px;
  left:auto;
}

.container_button .actionIconsTitle{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIconsTitle label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIconsTitle span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIconsTitle i{
  font-size: 18px;
  cursor:pointer;
}

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:auto;
  font-family: 'Poppins';
  padding-bottom: 2rem;
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}