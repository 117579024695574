.banner-checkout-black-friday{
    width:100%;
    background-position: center center;
    background-size: cover;
    display: flex;
    margin-bottom:20px;

    & .home-black-friday-col{
        padding:0 10px;
        height:140px;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:nth-child(1){
            width:55%;
        }

        &:nth-child(2){
            width:45%;
        }

        & .coupon{
            color:#fff;
            font-weight: 600;
            font-size: 16px;
            margin-top:-5px;
            & span{
                color:#00FF96;
            }
        }

        & b{
            color:white;
            font-weight: 700;
            font-family: 'Poppins';
            font-size: 26px;
        }

        & p{
            font-size: 7px;
            color:#fff;
        }

        & img{
            max-width:100%;
        }

        & .black-friday-detail{
            max-width:65%;
            margin-top:-15px;
        }
    }

}

@media(min-width:768px){
    .banner-checkout-black-friday{
        & .home-black-friday-col{
            padding:0 20px;
            height:320px;

            &:nth-child(1){
                width:45%;
            }
    
            &:nth-child(2){
                width:55%;
            }

            & .coupon{
                font-size: 36px!important;
                font-family: 'Poppins';

                & span{
                    color:#00FF96;
                }
            }

            & b{
                font-size: 56px!important;
            }

            & p{
                font-size: 16px!important;
            }
        }
    }
}