.video-list-container{
    padding:20px 0;
}

#bunny-player{
    height:220px!important;

    @media(min-width:1024px){
        height:620px!important;
    }
}

.close-video-modal{
    border:none;
    position:absolute;
    background-color: transparent;
    top:0px;
    right:0px;
    cursor:pointer;
    font-size: 30px;
    color:#333;
}

.video-list-container .video-list-title{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    margin-bottom: 30px;

    @media(max-width:1024px){
        flex-direction: column;
    }

    .video-list-construction{
        display:flex;
        align-items: center;
        gap:10px;

        & img{
            width:90px;
            height:90px;
            object-fit:cover;
            border-radius:100%;
            box-shadow:0 2px 4px 2px rgba(0,0,0,0.2);
        }

        & h2{
            font-weight:300;
            font-size:28px;

            @media(max-width:1024px){
                font-size:22px;
            }
            
            & small{
                font-weight:500;
                font-size: 14px;
                color:#888;

                @media(max-width:1024px){
                    display:block;
                }
            }
        }

        & h3{
            font-weight:600;
            font-size: 18px;

            @media(max-width:1024px){
                font-size: 15px;
            }
        }

        .video-list-construction-info{

        }

    }
}

.video-list-container .video-list-videos{
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    gap: 15px;
    margin-top:30px;

    & .video-title{
        font-size: 17px;
        text-align: center;
        font-weight:600;
    }

    & .video-openned:hover{
        transform: scale(1.03);
    }

    & .video-item{
        display:flex;
        flex-direction:column;
        gap:10px;
        justify-content:center;
        text-align: center;
        position:relative;
        margin-bottom:8px;
        transition:0.2s;

        & .video-open, & .video-close{
            width:100%;
            height:100%;
            position:absolute;
            top:0;
            left:0;
            display:flex;
            align-items: center;
            justify-content: center;
            z-index:10;
        }

        & .video-close{
            background-color: rgba(0,0,0,0.3);
        }

        & .video-open i{
            font-size: 78px;
            color:#fff;
        }

        & .video-close i{
            font-size: 78px;
            color:#333;
        }
    }
}

.video-list-certificate{

    @media(max-width:768px){
        margin-top:10px;
    }

    & span{
        background-color: #3e5e3fe8;
        padding:8px 18px;
        border-radius:12px;
        color:white;
        font-size: 18px;
        font-weight:500;
        cursor:pointer;

        &:hover{
            background-color: #3E5E3F;
        }
    }

    .video-list-no-certificate {
        max-width:200px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap:10px;
        
        & span{
            background-color: #3e5e3f80;
            cursor: not-allowed;

            &:hover{
                background-color: #3e5e3f80;
            }
        }
    }
}