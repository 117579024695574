#noPremiumOverlay{
  position: relative;
  top:0;
  bottom:0;
  left:0;
  right:0;
  height:100%;
  width:100%;
  background-color: rgba(0,0,0,0.7);
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding:20px;
  flex-direction: column;
  text-align: center;
}

#noPremiumOverlay i{
  color:#fff;
  font-size: 32px;
  margin-bottom: 10px;
}

#noPremiumOverlay p{
  color:#fff;
  font-size: 20px;
}

#noPremiumOverlay button{
  margin-top: 20px;
}