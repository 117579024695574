.container_imgs {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(.79);
  margin-top: -1rem;
}

.container_imgs img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/* .container_imgs img {
  width: 100%;
} */

/*? CONFIG */

.container_config_imobile_about {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg_config {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  gap: 4rem;
  padding: 2rem;
  background-color: #f6f6f6;
  border: 1px solid #e2e2e2;
  width: 770px;
}

.container_house {
  width: 400px;
}

.name {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem !important;
}

.config {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: .8rem;
  margin-bottom: 1rem;
}

.location_imobile {
  font-size: .8rem;
  margin-bottom: 1rem !important;
}

.container_buttons_imobile {
  display: flex !important;
  align-items: center;
  gap: .5rem;
}

.container_buttons_imobile button {
  width: 50%;
  background-color: #f9af24;
  color: #FFF;
  border: none;
  padding: .3rem;
  cursor: pointer;
}

.container_buttons_imobile button:hover {
  background-color: #FFF;
}

.share {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.icons_imobile {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.container_price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.price_imobile {
  font-size: 1.5rem;
  font-weight: 300;
}

.price_imobile span {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.others {
  font-size: .8rem;
}

.about_title {
  font-size: 1.2rem;
  margin-bottom: 2rem !important;
}

.about_imobile {
  font-size: .9rem;
}

/*? SWIPER */

.more_imobiles {
  text-align: center;
  font-weight: 500;
  margin: 4rem 0;
}

.container_imobile {
  width: 350px;
  /* height: 300px; */
  height: 400px;
  margin-left: 1rem;
  transform: scale(.8);
}

.bg {
  background-image: url('../../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
}

.container_config {
  padding: .8rem;
  background-color: #F6F6F6;
}

.name {
  font-size: .9rem;
  font-weight: 500;
  margin-bottom: 1rem !important;
}

.price {
  font-weight: 600;
  margin-bottom: 1rem !important;
}

.price span {
  font-size: 1.2rem;
}

.container_config_imobile {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.container_config_imobile span {
  display: flex;
  align-items: center;
  font-size: .8rem;
  margin-bottom: 1rem;
}

.details::after {
  content: "";
  display: block;
  height: 1px;
  width: 320px;
  background-color: #c6c4c4;
}

.container_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
  font-size: .8rem;
}

.view {
  background-color: #f9af24;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem 1rem !important;
  cursor: pointer;
  transition: .3s;
}

.view:hover {
  color: #f9af24;
  background-color: #000;
}

/* CAROUSEL */

.container_carousel {
  max-width: 1200px;
  margin: auto;
  /* padding-top: 3rem; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

/* BUTTON */

.button_view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6rem 0;
}

.button_view button {
  font-size: 1rem;
  font-weight: 500;
  background-color: #f9af24;
  color: #fff;
  border: none;
  padding: .5rem 6rem;
}

/*! CONFIG WEB SITE */

.actionIcons{
  position: absolute;
  left: 240px;
  top: 550px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
  z-index: 1000;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top: -20px;
  left: auto;
}

.container_button .actionIcons{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:auto;
  font-family: 'Poppins';
  margin-top: 2rem;
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

.container_maps {
  width: 770px;
  margin: auto;
  padding-top: 2rem;
}

/* LOGO */

.semLogo{
  background-color: #cdcdcd;
  color:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  margin-left: 2rem;
}

.logo {
  width: 70px;
  margin-left: 0.5rem;
}

.container_logo{
  position:relative;
  display: flex;
  align-items: center;
  background-color: #F6F6F6;
  opacity: .7;
  padding: .5rem 0;
}

.inputLogo{
  background-color: #c6c2c2;
  border-radius: 50%;
  position: absolute;
  bottom:0px;
  height:25px;
  width:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
}