.payment-select{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 470px;
}

.payment-select .payment-type{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 120px;
    height: 120px;
    border: 1px solid lightgrey;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: .3s;
}

.off{
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #7bc87e;
    padding: 3px;
}

.off span{
    font-size: 16px;
    font-weight: 500;
    color: white;
}   

.payment-select .payment-type:hover{
    background-color: #3e5e3f24;
    border: 1px solid var(--green);
}

.payment-select .payment-type img,
.payment-select .payment-type svg{
    width: 40px;
}