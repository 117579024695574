.selected-plan-review-container .plan-card{
    width:100%;
    border-radius:10px;
    text-align:center;
    color:#fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    background-color: #3E5E3F;
  
    & h5{
      color:#333;
      width:100%;
      background-color: #cdcdcd;
      border-radius: 8px 8px 0 0;
      padding:8px 0!important;
    }
  
    & h3{
      color:#fff;
      padding:8px 0!important;
      border-bottom: solid 1px #888;
    }
  
    & .plan-price{
      padding:10px 0;
  
      & span{
        font-weight: 800;
        font-size: 16px;
      }
    }
  }
  
  .selected-plan-review-container .plan{
    text-align: center;
    width:100%;
    margin-top:20px;
    display:flex;
    justify-content: center;
    flex-direction: column;
  
    & button{
      text-decoration: underline!important;
      font-size:16px;
      margin-top:10px!important;
      display:block; 
      color:#888;
      border:none;
      background-color: transparent;
    }
  }

  .selected-plan-review-container .coupon-container{
    display:flex;
    flex-direction: column;
    gap:10px;
    margin-top:15px;

    & .remove-coupon{
      text-decoration: underline;
      cursor:pointer;

      &:hover{
        color:#263a27;
      }
    }

    & .coupon-error{
      color:#da2c3e;
      font-size: 14px;
      font-weight: 600;
    }

    & label{
      font-size: 18px;
      text-align: center;
      font-weight: 600;
    }

    & .coupon{
      display:flex;
      align-items: center;

      & input{
        width:calc(100% - 80px);
        height:40px;
        padding:0 5px;
        border-radius:8px 0 0 8px;
        border:solid 1px #cdcdcd;
        font-size: 18px;
        text-transform: uppercase;
      }
      & span{
        width:80px;
        height:40px;
        background-color: #3E5E3F;
        display:flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        border-radius: 0 8px 8px 0;
        font-size: 16px;
        font-weight: 600;
        cursor:pointer;

        &:hover{
          background-color: #263a27;
        }
      }
    }
  }