#enterpriseProfile{
  display:flex;
  flex-direction:row;
  max-width:1000px;
  margin:auto;
}

#enterpriseProfile .profile{
  width:320px;
  padding-right:80px;
}

#enterpriseProfile .enterpriseContainerButtons{
  width:calc(100% - 320px);
  display:grid;
  gap:20px;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton{
  width:140px;
  height:140px;
  background-color: #3E5E3F;
  border-radius:8px;
  position:relative;
  display:flex;
  flex-direction:column;
  gap:10px;
  justify-content:space-around;
  align-items:center;
  cursor:pointer;
  transition:0.3s;
  font-weight:600;
  text-align:center;
  line-height:1.2;
  font-size:14px;
  padding:10px;
  color:#fff;
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.inactive{
  background-color: #f1f1f1;
  color:#333;
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton:hover{
  transform:scale(1.03);
}

#enterpriseProfile .enterpriseContainerButtons small{
  color:red;
  position:absolute;
  top:8px;
  right:8px;
  font-size:18px;
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton .buttonIcon{
  width:50px;
  height:50px;
  background-position:center center;
  background-size:contain;
}

@media(max-width:768px){
  #enterpriseProfile{
    flex-direction:column;
  }
  #enterpriseProfile .profile{
    margin-bottom:30px;
  }

  #enterpriseProfile .enterpriseContainerButtons{
    grid-template-columns: 1fr 1fr;
    gap:20px;
  }

  #enterpriseProfile .enterpriseContainerButtons .enterpriseButton{
    width:130px;
    height:130px;
  }
  
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.informacoes .buttonIcon {
  background-image: url('./../../../images/Icons/informacoes_active.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.informacoes.inactive .buttonIcon {
  background-image: url('./../../../images/Icons/informacoes_inactive.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.caracteristicas .buttonIcon {
  background-image: url('./../../../images/Icons/caracteristicas_active.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.caracteristicas.inactive .buttonIcon {
  background-image: url('./../../../images/Icons/caracteristicas_inactive.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.pagamento .buttonIcon {
  background-image: url('./../../../images/Icons/pagamento_active.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.pagamento.inactive .buttonIcon {
  background-image: url('./../../../images/Icons/pagamento_inactive.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.fotos .buttonIcon {
  background-image: url('./../../../images/Icons/fotos_active.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.fotos.inactive .buttonIcon {
  background-image: url('./../../../images/Icons/fotos_inactive.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.plantas .buttonIcon {
  background-image: url('./../../../images/Icons/plantas_active.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.plantas.inactive .buttonIcon {
  background-image: url('./../../../images/Icons/plantas_inactive.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.divulgacao .buttonIcon {
  background-image: url('./../../../images/Icons/material_active.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.divulgacao.inactive .buttonIcon {
  background-image: url('./../../../images/Icons/material_inactive.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.videos .buttonIcon {
  background-image: url('./../../../images/Icons/videos_active.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.videos.inactive .buttonIcon {
  background-image: url('./../../../images/Icons/videos_inactive.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.obra .buttonIcon {
  background-image: url('./../../../images/Icons/obra_active.png');
}

#enterpriseProfile .enterpriseContainerButtons .enterpriseButton.obra.inactive .buttonIcon {
  background-image: url('./../../../images/Icons/obra_inactive.png');
}


.enterpriseTypeUnitsContainer{
  display:flex;
  flex-direction:column;
  max-width:1000px;
  margin:auto;
}

.enterpriseTypeUnitsContainer .typeUnitsTitle{
  font-size:18px;
  font-weight:600;
}

.typeUnitsCardsGrid{
  width:100%;
  display:grid;
  gap:20px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  margin-top:20px;
}

.typeUnitsCardsGrid .typeUnitsCard{
  background-size:cover;
  background-position:center center;
  cursor:pointer;
  border-radius:8px;
  background-color:#f4f4f4;
  width:180px;
  height:200px;
  transition:0.3s;
  position:relative;

  .overlay{
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    border-radius:8px;
    background-color:rgba(0,0,0,0.7);
  }

  .content{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:0px;
    position:absolute;
    top:-20px;
    left:50%;
    transform:translate(-50%, 0);
    text-align:center;
    color:#fff;
    z-index:1;
  }
}

.typeUnitsCardsGrid .typeUnitsCard.new{
  width:140px;
  background-color: #628ADC;
  margin-top:80px;
  .content{
    width:100%;
  }
}

.typeUnitsCardsGrid .typeUnitsCard:hover{
  transform:scale(1.03);
}

@media(max-width:768px){
  .typeUnitsCardsGrid .typeUnitsCard{
    width:130px;
  }
  .typeUnitsCardsGrid{
    grid-template-columns: 1fr 1fr;
  }
}

.page-content .speedometer{
  height:90px!important;
}

.page-content .termometer-container{
  margin-top:-20px;
  display:block;
}

.typeUnitsCardsGrid .type-score{
  width:100%;
  display:flex;
  justify-content:center;
  align-items: center;
}

.actions-row{
  max-width:1020px;
  margin: 40px auto!important;
  justify-content:flex-end;
  align-items:flex-end;

  @media(max-width:768px){
    align-items:flex-start;
    justify-content:space-between;
    gap:10px;
    margin: 40px auto 0;
    flex-wrap:wrap;

    .desktop-available{
      display:none;
    }
  }
}