.typeUserOption{
  width:100%;
  height:60px;
  border-radius:5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border:solid 1px #cdcdcd;
  cursor:pointer;
  gap:5px;
  font-size: 18px;
}

.typeUserOption.corretor.C{
  background-color: #3E5E3F;
  color:#fff;
}

.typeUserOption.imobiliaria.I{
  background-color: #3E5E3F;
  color:#fff;
}







.logoutSendDocs{
  background-color:#fff;
  margin-bottom:-30px;
  padding:80px 0;
}

.logoutSendDocs .container{
  max-width:1024px;
  margin:0 auto;
  background-color:#f4f4f4;
  padding:20px;
}

.logoutSendDocs .container-send-docs-button,
.logoutSendDocs .container-send-docs-title{
  max-width:1024px;
  margin:0 auto;
  padding:30px 10px;
}

.logoutSendDocs .perfil-container{
  display:flex;
  gap:20px;
  align-items:center;
}

.logoutSendDocs .perfil-container .option{
  display:flex;
  gap:10px;
  align-items:center;
  border-radius:5px;
  border:solid 1px #cdcdcd;
  background-color:#fff;
  padding:4px 12px;
  cursor:pointer;

  & img{
    width:30px;
  }
}

.logoutSendDocs .perfil-container .option.active{
  background-color:#333;
  color:#fff;
}

#construction-register input{
  height:40px;
  border-radius:5px;
  border:solid 1px #cdcdcd;
  width:100%;
}

#construction-register label{
  width:100%;
}

#construction-register .col{
  position:relative;
}

#construction-register .ri-error-warning-line{
  position:absolute;
  right:20px;
  top:27px;
  color:red;
  font-size:19px;
}

#construction-register .required-field{
  color:red;
}
