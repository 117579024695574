.partnership {
  height: 290px;
  background: linear-gradient(93deg, rgba(244,244,244,1) 50%, rgba(255,255,255,1) 100%);
  margin-top: 3rem;
  text-align: center;
  position: relative;
  overflow-x: clip;
}

.container_home_title {
  padding-top: 4.5rem;
  font-weight: 500;
  font-size: 1.5rem !important;
  margin-bottom: 0px;
  position: relative;
  z-index: 1;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.title_number {
  font-weight: 500;
  font-size: 1.5rem !important;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.brokers {
  position: relative;
  /* left: 55px; */
}

.number {
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  width: 90px;
  display: block;
  /* position: absolute; */
}

@media(max-width:768px){
  .container_home_title {
    font-size: 1.3rem !important;
  }
  .title_number {
    font-size: 1.3rem !important;
  }
  .number {
    font-size: 1.3rem;
    width:55px!important;
  }
}

.button {
  margin-top: 1rem;
  padding: 1rem;
  border: none;
  border-radius: .5rem;
  color: #fff;
  background-color: #3e5e3f;
  width: 260px;
  font-family: 'Poppins', sans-serif;
}

.button:hover {
  background-color: #597d5a;
}

.rounded_primary_rectangle {
  border-radius: 30px;
  width: 294px;
  height: 293px;
  background-color: transparent;
  border: 1px solid #3e5e3f;
  rotate: 306deg;
  position: absolute;
  left: -226px;
  top: 77px;
  z-index: 1;
}

.rounded_rectangle {
  border-radius: 32px;
  width: 200px;
  height: 296px;
  border: 1px solid rgb(231, 231, 231);
  rotate: 122deg;
  position: absolute;
  right: -190px;
  top: -70px;
}

.none {
  display: none;
}

@media(min-width: 1200px) {
  .partnership {
    height: 300px;
    background: linear-gradient(93deg, rgba(244,244,244,1) 50%, rgba(255,255,255,1) 100%);
    margin-top: 3rem;
    text-align: center;
    position: relative;
    overflow-x: clip;
  }
  
  .container_home_title {
    padding-top: 4.5rem;
    font-weight: 500;
    font-size: 2rem !important;
    color: #282828;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
  }
  
  .title_number {
    font-weight: 500;
    font-size: 2rem !important;
    font-family: 'Poppins', sans-serif;
  }
  
  .number {
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
  }
  
  .button {
    margin-top: 1rem;
    padding: 1rem;
    border: none;
    border-radius: .5rem;
    color: #fff;
    background-color: #3e5e3f;
    width: 260px;
  }
  
  .button:hover {
    background-color: #597d5a;
  }
  
  .rounded_primary_rectangle {
    border-radius: 30px;
    width: 294px;
    height: 293px;
    background-color: transparent;
    border: 1px solid #3e5e3f;
    rotate: 306deg;
    position: absolute;
    left: -226px;
    top: 77px;
    z-index: 1;
  }
  
  .rounded_rectangle {
    border-radius: 32px;
    width: 200px;
    height: 296px;
    border: 1px solid rgb(231, 231, 231);
    rotate: 122deg;
    position: absolute;
    right: -190px;
    top: -70px;
  }
  .brokers {
    position: relative;
    /* left: 75px; */
  }
}