.ProfileToolOwner{
  background-color: #fff;
  border-radius:10px;
  padding:20px;
  box-shadow: 0 0 7px #cdcdcd;
}

.ProfileToolOwner .profile-photo{
  border:solid 2px #cdcdcd;
  width:80px;
  height:80px;
  border-radius:8px;
  padding:5px;
}

.ProfileToolOwner .profile-photo .user-photo{
  background-image: url('./../../assets/images/users/default-user-image.png');
  background-position: center center;
  background-size:cover;
  width:100%;
  height:100%;
  border-radius:8px;
}

.ProfileToolOwner .profile-name{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:100%;
}

.ProfileToolOwner .profile-name p{
  margin:0;
  font-size: 16px!important;
}

.ProfileToolOwner .profile-name h3{
  margin:0;
  font-size:26px;
  font-weight:300;
}

.ProfileToolOwner .row .col.interactions span{
  background-color: #f4f4f4;
  width: 50px;
  height:50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius:10px;
} 

.ProfileToolOwner .col{
  display:flex;
  align-items:center;
}

.ProfileToolOwner .row .col p{
  font-size: 14px;
  margin:10px 0 5px;
}

.ProfileToolOwner .row .col small{
  font-size: 12px;
}