#wtp-contact {
  position: fixed;
  bottom: 20px;
  right: 20px;
  animation-duration: 1s;
  animation-name: intro;
  transition: 0.5s;
  z-index: 99999999;
}

@keyframes intro {
  from {
    opacity: 0;
    bottom: -10px;
  }

  to {
    opacity: 1;
    bottom: 20px;
  }
}

#wtp-contact:hover {
  transform: scale(1.05);
}

.wtp-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor:pointer;
}

.wtp-text {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 8px;
  margin-right: 14px;
  transition: 0.3s;
  background-color: #fff;
}

.wtp-text::after {
  content: "";
  width: 16px;
  height: 32px;
  position: absolute;
  top: 50%;
  margin-top: -16px;
  right: -18px;
  background-image: url('./../../assets/images/Icons/seta.png');
  background-size: 32px 32px;
  /* transform: rotate(360deg); */
}

.wtp-text{
  position:relative;
}

.close-whats{
  position:absolute;
  left:-10px;
  top: -5px;
  background-color: #3E5E3F;
  border-radius:50%;
  width:25px;
  height:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  box-shadow: -2px 2px 5px rgba(0,0,0,0.4);
  color:#fff;
  cursor:pointer;
}

.close-whats i{
  line-height: 1;
  font-size: 18px;
}

.wtp-status {
  background-color: #2ecc71;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-top: 35px;
  margin-left: -15px;
}

.wtp-photo {
  background-image: url('./../../assets/images/whats.jpg');
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}

.modalForm{
  position: fixed;
  bottom:90px;
  right:20px;
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0,0,0,0.6);
  padding:20px;
  min-width:200px;
  width: 300px;
  z-index:99;
}