.title {
  text-align: center;
  margin-top: 4rem;
  font-weight: 500;
}

/* CAROUSEL */

.container_carousel {
  max-width: 1200px;
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* CONTAINER CAROUSEL */

.container_imobile {
  width: 310px;
  height: 300px;
  margin-left: .2rem;
  transform: scale(.8);
  height: 330px;
}

.bg {
  background-image: url('../../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
}

.container_config {
  padding: .8rem;
  background-color: #F6F6F6;
}

.name {
  font-size: .9rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.price {
  color: #000;
  font-weight: 600;
  margin-bottom: .5rem !important;
}

.price span {
  font-size: 1.2rem;
}

.container_config_imobile {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.container_config_imobile span {
  display: flex;
  align-items: center;
  font-size: .8rem;
  margin-bottom: .5rem !important;
}

.details::after {
  content: "";
  display: block;
  height: 1px;
  width: 284px;
  background-color: #c6c4c4;
}

.container_view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: .5rem;
  font-size: .8rem;
}

.view {
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem 1rem !important; 
  cursor: pointer;
  transition: .3s;
}

/* BUTTON */

.container_button {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.container_button button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  border: none;
  background-color: #000;
  color: #FFF;
  padding: .5rem 0;
  font-weight: 600;
}

/*! CONFIG WEB SITE */
.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin: 2rem auto 0rem;
  color:#101010;
  font-family: 'Poppins';
}

.actionIcons{
  position: absolute;
  left: 700px;
  top: 735px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer,
.container_button{
  width:auto;
  text-align: center;
  position:relative;
}

.titleContainer .actionIcons{
  right:calc(50% - 55px);
  top:-30px;
  left:auto;
}

.container_button  .actionIcons{
  top:0px;
  left: 1px;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}