#pageConstructor .containerLayoutsOptions{
  display:flex;
  flex-direction: row;
  gap:20px;
  align-items: center;
  justify-content: space-between;
}

#pageConstructor .containerLayoutsOptions .layoutOption{
  width:300px;
  border: solid 1px #cdcdcd;
  padding:10px;
  border-radius: 5px;
}

#pageConstructor .containerLayoutsOptions .layoutOption .title{
  border:solid 1px #cdcdcd;
  padding:15px;
  background-color: #fff;
  border-radius: 5px 5px 0 0 ;
}

#pageConstructor .containerLayoutsOptions .layoutOption .title h3{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

#pageConstructor .containerLayoutsOptions .layoutOption .body{
  border:solid 1px #cdcdcd;
  border-top:none;
  padding:15px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #fff;
  flex-direction: column;
  text-align: center;
}

#pageConstructor .containerLayoutsOptions .layoutOption .body a{
  text-decoration: underline!important;
  margin:auto;
  margin-top:10px;
}

#pageConstructor .containerLayoutsOptions .layoutOption .cta{
  border:solid 1px #cdcdcd;
  padding:15px;
  border-top:none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0 0 5px 5px  ;
}

#pageConstructor .containerLayoutsOptions .layoutOption.active{
  border: solid 1px #3E5E3F;
  background-color: #3E5E3F;
}



// Step 2

#pageConstructor .inputSiteFsText{
  width:100%;
  border:none;
  border-bottom: solid 1px #cdcdcd;
  font-size: 18px;
  color:#333;
}

#pageConstructor #homeTitle{
  position: absolute;
  top:50px;
}

#pageConstructor #homeTitleColor{
  position: absolute;
  top:150px;
  z-index:3;
}

#pageConstructor #homeBackground{
  position: absolute;
  top:270px;
}

#pageConstructor #homeButtonColor{
  position: absolute;
  top:370px;
}

#instagram,
#youtube,
#facebook{
  width:35px;
  height:35px;
  background-color: #cdcdcd;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor:pointer;
}

#instagram i,
#youtube i,
#facebook i{
  font-size: 22px;
  color:#333;
}

#instagram.active,
#youtube.active,
#facebook.active{
  background-color: #3E5E3F;
}

#instagram.active i,
#youtube.active i,
#facebook.active i{
  color:#fff;
}

.swiper-button-prev,
.swiper-button-next {
  color: #333 !important;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 1.5rem !important;
}


.searchInput{
  max-width:240px;
  height:40px;
}

.text_sweet_website {
  margin-bottom: 1rem !important;
}

.container_button_sweet {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.button_close_sweet {
  background: #a5a5a5 !important;
  color: black !important;
  border: none !important;
  width: 94px !important;
}

.text_dominio {
  margin-left: .8rem !important;
  margin-top: 1rem !important;
  margin-bottom: 3rem !important;
  font-size: .8rem;
}

.checked_layout {
  color: green !important;
  font-size: 3rem !important;
}

.aproved { 
  font-size: 1.2rem !important;
  font-weight: 600 !important;
}

.container_checked_layout {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.layout_valid {
  display: flex !important;
  align-items: center !important;
}

.atention { 
  background-color: #343a40 !important;
  color: #FFFFFF80;
  border: 1px solid #f1f1f1;
  border-radius: 0.25rem;
  width: 600px !important;
  padding: 1rem;
}

.atention_link {
  margin-left: -.5rem !important;
}

.atention_link a {
  font-weight: 600 !important;
}

.atention p {
  margin: .2rem 0 !important;
}

.text_dominio_information {
  display: flex !important;
  align-items: center !important;
  gap: 1rem !important;
  margin-top: 1rem !important;
}

.button_public {
  width: 250px !important;
  margin-left: 1rem !important;
  margin-top: 2rem !important;
}

.fail_url {
  margin-bottom: 1rem !important;
}

.customRow::after {
  content: "";
  display: block;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
  margin-top: 1rem;
}

.customPadding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.link_website {
  color: #fff !important;
}

.list_immobiles {
  z-index: 999;
}

.container_buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 0.75rem;
  margin: 2rem 0;
}

.button-delete-all {
  margin-right: 1rem !important;
  background-color: #ababab !important;
  border: none !important;
}

@media(max-width:560px){
  .searchInput{
    width:100%;
    margin-top:20px;
  }
  #pageConstructor .containerLayoutsOptions {
    overflow-x: auto;
    white-space: nowrap;
  }
  #pageConstructor .containerLayoutsOptions .layoutOption {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-left: 10px;
  }
  .title_layout {
    margin-bottom: 1rem !important;
  }
  .input_site {
    margin-left: 0rem !important;
  }
  .button-delete-all {
    margin-right: 0px !important;
    margin-bottom: 1rem !important;
  }
}