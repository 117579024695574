.checkout-cart-section{
    width:100%;
    max-width:1024px;
    margin:auto;
  
    & h3{
      font-size: 22px;
    }
  
    @media(max-width:768px){
      & h3{
        font-size: 16px;
      }
    }

  }
  
  .checkout-cart-section .top-bar{
    justify-content: space-between;
    align-items: center;
    width:100%;
    height:80px;
  
    & img{
      width:160px;
    }
  
    & .col:nth-child(2){
      display:flex;
      align-items: center;
      gap:4px;
  
      & i{
        font-size:32px;
      }
  
      & span{
        font-size: 20px;
        font-weight: 800;
      }
    }
  
    @media(max-width:768px){
      & img{
        width:120px;
      }
  
      & .col:nth-child(2){
        padding:0;
  
        & i{
          font-size:22px;
        }
    
        & span{
          font-size: 14px;
        }
      }
    }
  }
  
  
  .checkout-cart-section .checkout-cart-item-label{
    align-items: center;
  
    font-weight: 800;
    font-size:14px;
    padding:10px 0;
    border-bottom: solid 1px #cdcdcd;
    margin-bottom:20px;
  
    @media(max-width:768px){
      display:none;
    }
  }
  
  .checkout-cart-section .checkout-cart-item{
    align-items: center;
    padding:10px 0;
    background-color: #f4f4f4;
  
    & p{
      font-weight:900;
      font-size:18px;
    }
  
    & label{
      display:none;
    }
  
    @media(max-width:768px){
      gap:10px;
      margin-top: 20px;
  
      & label{
        display:block;
      }
  
      & .value{
        display:none;
      }
    }
  }
  
  .checkout-cart-section .checkout-cart-values-container{
    justify-content: space-between;
  
    & .col{
      padding:0;
    }
  
    @media(max-width:768px){
      width:calc(100% + 23px);
  
      & .col{
        width:100%;
      }
    }
  }
  
  .checkout-cart-section .checkout-cart-values-container .checkout-cart-values{
    width:100%;
    background-color: #fff;
    margin-top:-22px;
    border-radius:0 0 8px 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    padding:20px;
    display:flex;
    flex-direction:column;
    align-items: center;
  
    & .atual-price{
      font-size:26px;
      border-bottom: solid 1px #cdcdcd;
      color:#333;
      width:100%;
      text-align: center;
      font-weight:800;
    }
  
    & span{
      text-align: center;
      font-weight: 800;
    }
  
    & .economy{
      width:100%;
      display:flex;
      flex-direction: column;
      align-items: center;
  
      & span{
        background-color: #628adc;
        color:#fff;
        font-weight:800;
        border-radius: 8px 8px 0 0;
        display:block;
        width:100%;
        text-align: center;
      }
  
      & p{
        background-color: #f4f4f4;
        color:#888;
        font-size: 18px;
        font-weight:800;
        border-radius: 0 0 8px 8px;
        display:block;
        width:100%;
        text-align: center;
      }
    }
  
    & button{
      background-color: #3E5E3F;
      color:#fff;
      min-height:40px;
      border-radius: 8px;
      border:none;
      width:100%;
      font-weight:800;
      font-size: 18px;
      margin-top:20px;
    }
  
    @media(max-width:768px){
      width:100%;
    }
  }

  .checkout-cart-section .coupon-container{
    display:flex;
    flex-direction: column;
    gap:10px;
    margin-top:15px;

    & .remove-coupon{
      text-decoration: underline;
      cursor:pointer;

      &:hover{
        color:#263a27;
      }
    }

    & .coupon-error{
      color:#da2c3e;
      font-size: 14px;
      font-weight: 600;
    }

    & label{
      font-size: 18px;
      text-align: center;
      font-weight: 600;
    }

    & .coupon{
      display:flex;
      align-items: center;

      & input{
        width:calc(100% - 80px);
        height:40px;
        padding:0 5px;
        border-radius:8px 0 0 8px;
        border:solid 1px #cdcdcd;
        font-size: 18px;
        text-transform: uppercase;
      }
      & span{
        width:80px;
        height:40px;
        background-color: #3E5E3F;
        display:flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        border-radius: 0 8px 8px 0;
        font-size: 16px;
        font-weight: 600;
        cursor:pointer;

        &:hover{
          background-color: #263a27;
        }
      }
    }
  }