.bg {
  background-color: rgb(46, 46, 46);
  position:relative;
}

.logo {
  width: 80px;
}

.semLogo{
  background-color: #cdcdcd;
  color:#333;
  width:80px;
  height:80px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.container_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem  1rem 2rem 1rem;
  text-align: right;
}

.info {
  color: #FFF;
  font-weight: 500;
}

.address {
  margin-top: 1rem;
}

.nets {
  display: flex;
  gap: .5rem;
  justify-content: flex-end;
  margin-top: .5rem;
  cursor:pointer;
}

.logo_fast {
  width: 100px;
}

.fast {
  padding: 2rem 0 2rem 2rem;
}

.fast p {
  margin-bottom: .5rem;
  font-size: .7rem;
}

@media(min-width: 1200px) {
  .container_info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30rem;
  }
}

.container_logo{
  position:relative;
}

.inputLogo{
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom:-10px;
  height:25px;
  width:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity:0.8;
  right:-20px;
  cursor:pointer;
}

.inputLogo:hover{
  opacity:1;
}

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:50px auto 0;
  color:#101010;
  font-family: 'Poppins';
}

.actionIcons{
  position: absolute;
  left:calc(50% - 120px);
  top:-20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.addSocialLinks{
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  border:solid 1px cyan;
  opacity:0.7;
  color:#333;
}

.actionIcons:hover, .addSocialLinks:hover{
  opacity:1;
}

.info{
  width:auto;
  text-align: right;
  position:relative;
}


.info .actionIcons{
  right:0;
  top:-30px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
  color:#333;
}

.colorPickerText i,
.colorPickerBg i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerText,
.colorPickerBg{
  position: absolute;
  right:0px;
  bottom:30px;
}