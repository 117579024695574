.modal {
  background-image: url('../assets/bg_cadore_modal.jpg');
  background-position: center;
  background-size: cover;
  height: 500px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.title {
  color: #595959;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 3rem;
}

.subtitle {
  font-size: 1rem;
  color: #3e5e3f;
  padding-right: 5rem !important;
}

.subtitle_second {
  font-size: 1rem;
  color: #3e5e3f;
  padding-right: 18.6rem !important;
  margin-top: 1rem !important;
}

.container_inputs {
  display: flex;
  flex-direction: column;
}

.input {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.input input {
  width: 430px;
  height: 38px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin-top: 2rem;
  width: 250px;
  border: none;
  padding: .8rem 1rem;
  background-color: #3e5e3f;
  color: #fff;
  font-weight: 600;
  border-radius: .3rem;
  font-size: 1rem;
  cursor: point
  ;
}


/* MODAL */


.modal-new{
  width:calc(100%-60px)!important;
}

.form {
  width: 300px;
  background-color: rgb(239, 239, 239, .5);
  padding: 2rem .8rem;
  border-radius: .5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.title_form {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #3e5e3f;
}

/* .subtitle {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem !important;
} */

.container_inputs {
  display: flex;
  flex-direction: column;
  gap: .3rem;
}

.input_phone {
  margin-top: 1rem !important;
}

.container_inputs input {
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_button button {
  font-family: 'Poppins', sans-serif;
  background-color: #3e5e3f;
  border: none;
  color: #fff;
  border-radius: 0.3rem;
  font-weight: 400;
  font-size: .9rem;
}

.what_perfil {
  text-align: center;
}

.perfil {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.what_perfil {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_button_perfil {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.container_inputs_second {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.button_broken,
.button_real_state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: #f9f9f9;
  color: #000;
  border: 1px solid #cccbcb;
  width: 120px;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: .3rem;
}

.button_broken,
.button_real_state {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.button_broken_active,
.button_real_state_active {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  background-color: #3e5e3f;
  color: #fff;
  width: 120px;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: .3rem;
  gap: .5rem;
}

.icon_broker {
  width: 26px;
}

.icon_real_state {
  width: 22px;
}

.container_input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container_inputs_modal {
  display: flex;
  flex-direction: column;
}

.container_inputs_modal input {
  width: 300px;
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

.container_inputs_modal_second {
  display: flex;
  gap: 1rem;
}

.container_inputs_second {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 0;
}

.container_inputs_second input {
  width: 140px;
  height: 38px;
  border: 1px solid #ececec;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: .3rem;
}

.button_confirm {
  width: 220px;
  margin-top: 1rem;
}

.modal_title {
  font-size: 1rem;
  text-align: center;
}