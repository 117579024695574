.compatible-modal{
    width:100%;
    max-width:460px;
    position:fixed;
    top:50%;
    left:50%;
    background-color: #fff;
    z-index:2;
    transform:translate(-50%, -600%);
    transition:0.3s;
    padding:45px 20px 30px;
    border-radius:8px;
    text-align: center;
  }
  
  .compatible-modal.active{
    transform:translate(-50%, -50%);
  }
  
  .compatible-modal-overlay{
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.7);
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:1;
    position:fixed;
    display:none;
  }
  
  .compatible-modal-overlay.active{
    display:block;
  }
  
  .compatible-modal .close-modal{
    position:absolute;
    top:15px;
    right:15px;
    cursor:pointer;
  
    & i{
      font-size:20px;
    }
  }
  
  .compatible-modal .title{
    margin-bottom:20px;
  
    & h3{
      font-size:20px;
    }
  }
  
  .compatible-modal p{
    text-align: center;
    font-size:18px;
  }
  
  .compatible-modal button{
    margin-top:20px;
  }