.title {
  text-align: center;
  padding: 4rem 2rem 2rem 2rem!important;
  font-size: 2rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.container_accordion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}