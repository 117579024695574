//Import Icon scss
@import "../scss/icons.scss";

//Import Dark theme
//  @import "../scss/bootstrap-dark.scss";
//  @import "../scss/app-dark.scss";

//import Light Theme
 @import "../scss/bootstrap.scss";
 @import "../scss/app.scss";

// RTL mode
// @import "../scss/custom/rtl/bootstrap-rtl";
// @import "../scss/custom/rtl/components-rtl";
// @import "../scss/custom/rtl/float-rtl";
// @import "../scss/custom/rtl/general-rtl";
// @import "../scss/custom/rtl/pages-rtl";
// @import "../scss/custom/rtl/plugins-rtl";
// @import "../scss/custom/rtl/spacing-rtl";
// @import "../scss/custom/rtl/structure-rtl";
// @import "../scss/custom/rtl/text-rtl";

body {
  white-space: pre-line;
  * {
    outline: none;
  }
}

.accordian-bg-products {
  background-color: $gray-300;
}

.nav-link {
  cursor: pointer;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  padding: 0px;
  margin: 2em 0;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1058 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}

input[type="range"] {
  -webkit-appearance: auto !important;
}

.chat-conversation-height {
  min-height: 500px;
}

.expand-cell {
  cursor: pointer !important;
}

//modal close icon
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

// kanban board
.react-kanban-column {
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.react-kanban-card-adder-button {
  width: 100%;
  margin-top: 5px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgb(204, 204, 204);
  transition: all 0.3s ease 0s;
  border-radius: 3px;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.react-kanban-card-adder-form {
  box-sizing: border-box;
  max-width: 250px;
  min-width: 250px;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  margin-bottom: 7px;
}

// calendar
.fc-daygrid-event {
  color: $white !important;
}
.card-columns .card {
  margin-bottom: 24px;
}

@media (min-width: 576px){
  .card-columns {
      -webkit-column-count: 3;
      column-count: 3;
      grid-column-gap: 1.25rem;
      -webkit-column-gap: 1.25rem;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
}
}
.react-bootstrap-table-pagination{
  --bs-gutter-x: 0;
}

.sweet-alert h2{
  font-size: 18px!important;
}

// .sweet-alert div{
//   margin:10px auto!important;
// }

.dahsboard-loading{
  width:100%;
  height:100vh;
  position:fixed;
  display:flex;
  align-items: center;
  justify-content: center;
  top:0;
  left:0;

  & img{
    width:160px;
    animation: float 3s ease-in-out infinite;
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.go-back-button{
  padding:25px 10px 20px;
  display:block;

  svg{
    width:40px;
    height:35px;
    color:#0F6E44;
  }

  @media(min-width:768px){
    display:none;
  }
}

.need-update-property-item{
  display:flex;
  width:100%;
  flex-wrap:wrap;
  padding:8px 0;
  border-bottom:solid 1px #cdcdcd;
  align-items: flex-start;
  gap:10px;
  position:relative;

  & .property-photo{
    width:100px;
    height:100px;
    background-color: #cdcdcd;
    border-radius: 5px;

    & img{
      width:100%;
      height:100%;
      object-fit:cover;
      border-radius: 5px;
    }
  }
  & .property-info{
    width:calc(100% - 110px);
    & .sku{
      text-align: left;
      color:#333;
    }
    & .property-unity{
      font-size:16px;
      text-align: left;
    }
    & .property-original-price{
      font-size:18px;
      color:#333;
    }
    & .property-promo-price{
      display:flex;
      align-items: center;
      gap:8px;

      & .original-price{
        font-size:15px;
        color:#cdcdcd;
        text-decoration: line-through;
      }
      & .promo-price{
        font-size:18px;
        color:#333;
      }
    }

    & .property-actions{
      display:flex;
      width:100%;
      justify-content: flex-end;
      gap:20px;
      margin-top:10px;
      position:absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -60%);
    
      & button{
        height:38px;
      }
    }
  }


  @media(max-width:768px){
    padding-bottom: 60px;

    & .property-actions{
      top: auto!important;
      bottom: -10px!important;
      justify-content: center!important;

      & .btn-1,
      & .btn-2{
        width:45%;

        & button{
          width:100%;
        }
      }
    }
  }
}

.fast-academy-banner{
  background-color: #3E5E3F;
  background-image: url('./../images/fast-academy-bg.jpg');
  background-size:130%;
  background-position:center;
  position:relative;

  & span{
    position:absolute;
    top:0;
    left:20px;
    padding:2px 10px;
    color:#333;
    background-color: #f1ce41;
    border-radius: 0 0 10px 10px;
    font-weight: 600!important;
    font-size: 18px;
  }
}