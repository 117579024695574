.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}

.logo img {
  width: 70px;
  height: 30px;
}

.bg {
  display: none;
  background-color: #F6F6F6;
}

.container_input {
  display: flex;
  flex-direction: column;
  color: #00000051;
}

.container_input div {
  display: flex !important;
  align-items: center !important;
  gap: 0.2rem !important;
  margin-left: 0.8rem !important;
}

.input {
  display: flex;
  align-items: center;
  margin-left: .5rem;
  gap: .5rem;
}

.input_text {
  height: 55px;
}

.input_text input {
  border: none;
  background-color: transparent;
  padding-top: .9rem;
  margin-left: 1.5rem;
}

.input_text input::placeholder {
  color: #0000008a;
  width: max-content;
  font-size: 0.9rem;
  font-weight: 600;
}

.input_text input[type=text]{
  outline:none
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: .2rem;
}

.button {
  width: 130px;
  height: 45px;
  border: none;
  border-radius: .5rem;
  background-color: #1919bf;
  color: #FFF;
  font-size: 1.1rem;
  cursor: pointer;
}

.button_close {
  width: 130px;
  height: 45px;
  border: none;
  border-radius: .5rem;
  background-color: gray;
  color: #FFF;
  font-size: 1.1rem;
  cursor: pointer;
}

.icon_location {
  position: relative;
  top: 16px;
  left: 20px;
}

.icon_firstArrow {
  position: relative;
  top: 18px;
  left: 20px;
}

.icon_secondArrow {
  position: relative;
  top: 18px;
  left: 20px;
}

.icon_dollar {
  position: relative;
  top: 18px;
  left: 20px;
}

.lupa {
  position: relative;
  top: -2px;
  left: 20px;
}

.text_container {
  font-family: 'Arial', sans-serif;
  font-size: 1.5rem;
  position: relative;
}

.text_animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(50, end) infinite alternate;
}

.expanded {
  display: block;
}

.container_filter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter {
  background-color: #1919bf;
  color: #FFF;
  width: 350px;
  text-align: center;
  padding: .5rem !important;
  border-radius: .5rem;
}

.filter p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .4rem;
}

@keyframes typing {
  0% {
    width: 0;
  }
  100% {
    width: 80%;
  }
}

