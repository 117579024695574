.brokersWrapper {
  height: 560px;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  /* -webkit-overflow-scrolling: touch; */
  scroll-padding: 0 20%;
}

.container_broker {
  scroll-snap-align: center;
  height: 530px;
  width: 310px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid #eae6e6;
  border-radius: 1rem;
  box-shadow: #F0F0F0 6px 6px 6px;
  padding: 2rem;
  margin-left: 1rem;
}

.container_broker:last-child {
  margin-right: 1rem;
}

.container_broker img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: .5rem;
}

.title {
  text-align: center;
  padding: 4rem 2rem 3rem!important;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
  margin:auto!important;
}

.broker_name {
  font-size: 1.5rem;
  color: #3e5e3f;
  font-weight: 500;
  padding-right: 2.4rem!important;
  margin-bottom: .5rem!important;
  font-size: .9rem;
  font-family: 'Poppins', sans-serif;
}

.profession {
  padding-right: 6rem!important;
  font-size: .9rem;
  font-weight: 600;
  margin:5px 0!important;
}

.broker_text {
  width: 255px;
  height: 100px;
  margin-top: 1.5rem!important;
  font-size: .9rem;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.link {
  background: none;
  border: none;
  color: #3e5e3f;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

.link img {
  width: 28px;
  height: 28px;
}

.link:hover {
  color: #3e5e3f;
}

.link span {
  font-size: .8rem;
  display: flex;
  align-items: center;
  gap: .5rem;
}

@media(min-width: 1200px) {
  .title {
    padding: 3rem 31rem;
    font-size: 2rem;
    font-weight: 500;
  }
  .brokersWrapper {
    justify-content: center;
    gap: 1rem;
  }
  .container_broker {
    margin-left: 0px;
  }
}

