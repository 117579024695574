.title  {
  text-align: center;
  font-weight: 600;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.bg {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
  width: 320px;
  margin: 1.5rem 3rem 0rem 3rem;
  border-radius: .5rem .5rem 0 0;
}

.container_immobile {
  background-color:rgb(236, 236, 236);
  margin: 0 3rem;
  position: relative;
  border-radius: 0 0 .5rem .5rem;
  width: 320px;
}

.title_immobile {
  text-align: left;
  margin-bottom: .5rem;
  padding: 15px 1rem 0;
}

.immobile {
  font-size: .8rem;
  font-weight: 500;
  line-height: 1.2;
  font-family: 'Poppins';
  color:#101010;
  height: 30px;
}

.location {
  font-size: .7rem;
  margin-bottom: 1.2rem;
  font-family: 'Poppins';
  font-weight: 500;
  color:#101010;
}

.icon_maps {
  color:#101010;
}

.container_location {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .2rem;
}

.config_immobile {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: .8rem;
  padding-top: .5rem;
  color:#101010;
  font-family: 'Poppins';
  height: 25px;
}

.container_price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
  padding-bottom: 1rem;
}

.container_price p {
  border: 1px solid #cdcdcd;
  border-radius: 2rem;
  padding: 5px 15px!important;
  font-weight: 600;
  font-family: 'Poppins';
  font-size: 1rem;
  color:#101010;
  margin-top: .5rem !important;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  padding-bottom: 4rem;
}

.container_button button {
  padding: .5rem 2rem;
  font-size: 1rem;
  border: none;
  color: #FFF;
  background-color: var(--primary-color);
  border-radius: 1.5rem;
  border: 1px solid var(--primary-color);
  font-family: 'Poppins';
}

.container_button button:hover {
  background-color: #FFF;
  border: 1px solid var(--primary-color);
  color: var(--primary-color); 
  cursor: pointer;
}

.view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding-bottom: 20px!important;
  margin: 0 1rem;
}

.view_immobile {
  /* border: 1px solid var(--primary-color); */
  border-radius: 1rem;
  padding: .1rem 1.5rem;
  font-size: .7rem;
  color: #fff;
  background-color: #101010;
  font-family: 'Poppins';
  cursor:pointer;
  width:90px;
  height:25px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
  font-family: 'Poppins';
}


.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px !important;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:50px auto 0;
  color:#101010;
  font-family: 'Poppins';
}

.actionIcons{
  position: absolute;
  left:20px;
  top:20px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
  z-index: 2;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer,
.container_button{
  width:auto;
  text-align: center;
  position:relative;
}

.titleContainer .actionIcons{
  right:calc(50% - 55px);
  top:-30px;
  left:auto;
}

.container_button  .actionIcons{
  right:calc(50% - 185px);
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

/* .cardContainer{
  width:400px;
} */
