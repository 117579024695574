#Calendar .fc-daygrid-event-dot{
  display: none;
}

.scheduleEvent p{
  font-size:14px;
  margin-bottom:10px;
  background-color: #fff;
  border:solid 1px #cdcdcd;
  padding:8px 15px!important;
  min-height:38px;
  border-radius: 5px;
  margin-bottom:10px!important;
  cursor: default;
  display: flex;
  align-items: center;
  line-height:1.2;
}

.boxNewEvent:nth-child(1){
  background-color: #f4f4f4;
  border-radius: 5px;
  border:solid 1px #cdcdcd;
  width:100%;
  text-align: center;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hourContainer .hour-container{
  border:solid 1px #cdcdcd;
  background-color: #f4f4f4;
  border-radius:10px;
  padding:10px;
  margin:0 0 20px;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
  // height:0;
}

.hourContainer .hour-container.active{
  height:auto;
}

.hourContainer .hour-container.active .hour{
  display: flex;
}

.hourContainer .hour-container .hour{
  border-radius:10px;
  background-color: #dfdede;
  text-align: center;
  color:#888;
  font-size:12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  transition:0.3s;
  height:30px;
}

.hourContainer .hour-container .hour:hover{
  background-color: #3E5E3F;
  color:#fff;
  transform: scale(1.4);
}

.hourContainer .hour-container .hour.active{
  background-color: #3E5E3F;
  color:#fff;
  transform: scale(1.4);
}

.visitTypeBtn{
  background-color: #dfdede;
  text-align: center;
  color: #888;
  height:38px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  font-weight: 600;
  cursor:pointer;
}

.visitTypeBtn.active{
  background-color: #3E5E3F;
  color:#fff;
}

.schedulingNumbers{
  text-align: center;
  padding:10px 0;
  background-color: #f4f4f4!important;
  margin-top:20px!important;
  margin-bottom:0!important;
}

.schedulingNumbers h3{
  font-size: 16px;
}

.schedulingNumbers h1{
  font-size: 30px;
  margin-top:15px!important;
}