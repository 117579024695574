.title {
  text-align: center;
  padding: 0rem 2rem 0rem 2rem;
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.container_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
}

.container_buttons button {
  width: 120px;
  background-color: #c6c0c0;
  color: #fff;
  border-radius: .5rem;
  border: none;
  padding: .8rem 1rem;
  font-family: 'Poppins', sans-serif;
}

.container_buttons button:hover {
  background-color: #979090;
}

.active {
  width: 120px;
  background-color: #3e5e3f !important;
  color: #fff;
  border-radius: .5rem;
  border: none;
  padding: .8rem 1rem;
}

.active:hover {
  background-color: #597d5a !important;
}

.plans {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
  margin-bottom: 1rem;
}

.container_free {
  display: flex;
  flex-direction: column;
  width: 370px;
  /* height: 780px; */
  height: 820px;
  border: 1px solid #e9e6e6;
  border-radius: .5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.container_premium_anual {
  display: flex;
  flex-direction: column;
  width: 370px;
  /* height: 790px; */
  height: 820px;
  border: 1px solid #e9e6e6;
  border-radius: .5rem;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.container_premium_trimestral {
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 820px;
  border: 1px solid #e9e6e6;
  border-radius: .5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position:relative;
}

/* .container_premium_anual::before {
  background-image: url('../assets/anual.png');
  background-size: contain;
  background-repeat: no-repeat;
  content: '';
  position: absolute;
  z-index: 2;
  right:-18px;
  top: 10px;
  width: 90px;
  height: 80px;
} */

/* .container_premium_trimestral::before {
  background-image: url('../assets/trimestral.png');
  background-size: contain;
  background-repeat: no-repeat;
  content: '';
  position: absolute;
  z-index: 2;
  right: -18px;
  top: 10px;
  width: 90px;
  height: 80px;
} */

.header_free,
.header_premium,
.header_start {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header_free::before {
  content: "";
  display: block;
  height: 4px;
  width: 60px;
  background-color: black;
  margin-top: 1rem;
}

.header_free button:hover {
  background-color: #979090;
}

.header_premium::before {
  content: "";
  display: block;
  height: 4px;
  width: 60px;
  background-color: #3e5e3f;
  margin-top: 1rem;
}

.header_start::before {
  content: "";
  display: block;
  height: 4px;
  width: 60px;
  background-color: #4582A1;
  margin-top: 1rem;
}

.container_advantage {
  margin-bottom: 2rem;
}

.free {
  font-size: 1.5rem;
  margin-top: .5rem!important;
  margin-bottom: .5rem!important;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.type {
  font-size: 2.5rem;
  margin-bottom: 1rem!important;
  margin-top:10px!important;
}

.header_free button {
  width: 190px;
  height: 48px;
  border: none;
  border-radius: .5rem;
  background-color: gray;
  color: #FFF;
  font-family: 'Poppins', sans-serif;
}

.header_premium button {
  width: 190px;
  height: 48px;
  border: none;
  border-radius: .5rem;
  background-color: #3e5e3f;
  color: #FFF;
  font-family: 'Poppins', sans-serif;
}

.header_start button {
  width: 190px;
  height: 48px;
  border: none;
  border-radius: .5rem;
  background-color: #4582A1;
  color: #FFF;
  font-family: 'Poppins', sans-serif;
}

.header_premium button:hover {
  background-color: #597d5a;
}

.header_start button:hover {
  background-color: #5996b4;
}

.advantage {
  margin-top: 1rem!important;
  margin-bottom: .5rem!important;
  padding-left: 3rem!important;
  font-weight: 600;
}

.type_advantage {
  font-size: .9rem;
  padding-left: 1.5rem!important;
  margin-top: 0rem;
  margin-bottom: .5rem!important;
}

.type_advantage_premium {
  font-size: .9rem;
  padding-left: 1.5rem!important;
  margin-top: 0rem;
  margin-bottom: .5rem!important;
}

.check {
  width: 16px;
  margin-right: .5rem;
}

.no_type_advantage {
  font-size: .9rem;
  padding-left: 3rem!important;
  text-decoration: line-through;
  color: rgb(176, 176, 176);
  margin-bottom: .5rem!important;
}

.times {
  color: #3e5e3f;
  font-size: 1rem;
  margin-bottom: -12px!important;
  margin-right: 90px!important;
  margin-top:10px!important;
}

.price {
  color: #3e5e3f;
  font-size: 2.5rem;
  margin-bottom: 0.8rem!important;
}

.container_start {
  display: flex;
}

.times_start {
  color: #4582A1;
  font-size: 1rem;
  margin-bottom: -12px!important;
  /* margin-right: 90px!important; */
  margin-top:10px!important;
}

.price_start {
  color: #4582A1;
  font-size: 2.5rem;
  margin-bottom: -.5rem !important
}

.price_premium {
  color: #3e5e3f;
  font-size: 2.5rem;
  margin-bottom: -.5rem !important;
}

.times_premium {
  color: #3e5e3f;
  font-size: 1rem;
  margin-bottom: -12px!important;
  /* margin-right: 90px!important; */
  margin-top:10px!important;
}

.cents {
  font-size: 1.5rem;
}

.details_free {
  text-align: center;
  background: linear-gradient(93deg, rgba(244,244,244,1) 50%, rgba(255,255,255,1) 100%);
  padding: .6rem 4rem!important;
  line-height: 1.2;
  font-size: .9rem;
  font-family: 'Poppins', sans-serif;
  font-weight:500;
}

.details_premium {
  text-align: center;
  background: linear-gradient(93deg, rgba(234,247,235,1) 0%, rgba(255,255,255,1) 100%);
  padding: .7rem 2rem!important;
  line-height: 1.2;
  font-size: .9rem;
  font-family: 'Poppins', sans-serif;
  font-weight:500;
}

.details_start {
  text-align: center;
  background: linear-gradient(90deg, rgba(137,190,217,1) 0%, rgba(165,207,228,1) 0%, rgba(255,255,255,1) 100%);
  padding: .7rem 2rem!important;
  line-height: 1.2;
  font-size: .9rem;
  font-family: 'Poppins', sans-serif;
  font-weight:500;
}

.container_buttons_pay {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.button_start_mensal {
  background-color: #e9e9e9;
  font-size: .7rem;
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
  border: 1px solid #cdcdcd;
  cursor: pointer;
}

.button_start_mensal_active {
  width: 90px;
  background-color: #4582A1;
  color: #fff;
  font-size: .7rem;
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
  border: 1px solid #cdcdcd;
  text-align: center;
  cursor: pointer;
}

.button_premium_mensal_active {
  width: 90px;
  background-color: #3e5e3f;
  color: #fff;
  font-size: .7rem;
  padding: 0.1rem 0.5rem;
  border-radius: 1rem;
  border: 1px solid #cdcdcd;
  text-align: center;
  cursor: pointer;
}

.tooltip {
  margin-left: .5rem;
}

.perfil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.8rem;
  font-size: 15px;
  padding-top: 1.5rem;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.link_buy {
  text-decoration: none;
  color: #FFF;
}

.link_buy:hover {
  color: #FFF;
}

@media(min-width: 320px) and (max-width: 759px) {
  .title {
    padding: 3rem 2rem 0rem 2rem;
  }
  .container_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .container {
    gap: 2rem;
  }
  .container_free,
  .container_premium_anual {
    width: 320px;
    height: auto;
  }
  .container_premium_trimestral {
    width: 340px;
    height: auto;
  }
} 