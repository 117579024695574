.bg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0;
  background-color: #101010;
}

.container_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: #f6f6f6;
  padding: 2rem;
  border-radius: .5rem;
}

.people {
  width: 200px;
}

.title {
  text-align: left;
  /* margin-top: 2rem; */
  color: #101010;
  font-size: 28px;
}

.text,
.textExpand {
  text-align: left;
  padding: 2rem 2.5rem 4rem 2.5rem;
}


@media(min-width: 1200px) {
  .bg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-image: none;
    padding: 3rem 0;
  }
  .people {
    height: 250px;
    width: 250px;
    margin-right:20px;
    object-fit: cover;
    border-radius: 5px;
  }
  .title {
    text-align: left;
    /* margin-left: 2.3rem;
    margin-top: 6rem; */
  }
  .text,
  .textExpand {
    max-width: 600px;
    padding: 1rem 0 6rem 2rem;
  }
}

.aboutMore{
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 600;
  text-decoration: underline;
  cursor:pointer;
  margin-top:10px;
}

.text{
  height:175px;
  overflow:hidden;
}

.textExpand{
  height:auto;
  overflow:hidden;
}

.titleEdit {
  text-align: left;
  padding: 0;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:0;
  color:#101010;
  font-family: 'Poppins';
}

.actionIcons{
  position: absolute;
  left:20px;
  top:20px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer{
  width:auto;
  text-align: left;
  position:relative;
}

/* .container_button{
  margin:-30px;
} */

.titleContainer .actionIcons{
  right:auto;
  top:-30px;
  left:auto;
}

.container_button  .actionIcons{
  right:calc(50% - 185px);
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

/* ACTIONS */

.actionIconsFirst {
  position: absolute;
  left: 80px;
  top: 875px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIconsFirst:hover{
  opacity:1;
}

.imageOptions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 10px;
}

.imageOptions label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  margin-bottom: 10px !important;
  border: 1px solid #f4f4f4;
  padding: 1rem !important;
  border-radius: .3rem;
}

.imageOptions label:hover {
  background-color: #f0f0f0;
}

.imageOptions input[type="radio"] {
  margin-right: 10px;
}

.imageOptions .imagePreview {
  width: 160px;
  height: 80px;
  object-fit: cover;
  border-radius: .3rem;
}

.selected {
  border: 5px solid #c4c4c4 !important;
}

.title_options {
  font-size: 1.2rem;
  margin: 1rem 0 !important;
}