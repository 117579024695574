.LastDownloadOwnerTool{
  background-color: #fff;
  border-radius:10px;
  padding:20px;
  box-shadow: 0 0 7px #cdcdcd;
  height:380px;
}

.LastDownloadOwnerTool .title h3{
  font-size:18px;
  font-weight:500;
  margin:0;
}

.LastDownloadOwnerTool .row{
  display: flex;
  flex-direction: row;
}

.LastDownloadOwnerTool .content{
  height:310px;
  overflow-y: auto;
  overflow-x: hidden;
}

.LastDownloadOwnerTool .content::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

.LastDownloadOwnerTool .content::-webkit-scrollbar-track {
  background: #f4f4f4;        /* color of the tracking area */
}

.LastDownloadOwnerTool .content::-webkit-scrollbar-thumb {
  background-color: #888;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #f4f4f4;  /* creates padding around scroll thumb */
}

.LastDownloadOwnerTool .row.label{
  font-size: 12px;
  margin:5px 0;
  font-weight: 500;
}

.LastDownloadOwnerTool .row .col:nth-child(2){
  display: flex;
  justify-content: center;
}

.LastDownloadOwnerTool .row.tit:first-child .col{
  width:auto;
}

.LastDownloadOwnerTool .row .col:nth-child(1){
  display: flex;
  align-items: center;
}

.LastDownloadOwnerTool .content .row .col:nth-child(1){
  display: flex;
  align-items: center;
}

.LastDownloadOwnerTool .content .row .col:nth-child(1) p{
  margin:0;
  font-size: 14px;
  font-weight: 500;
}

.LastDownloadOwnerTool .row.item{
  background: linear-gradient(-180deg, rgba(255,255,255,1) 0%, rgba(244,244,244,1) 100%);
  border-radius: 10px;
  padding:10px;
  margin-bottom:5px;
  border:solid 1px #eceaea;
}

.LastDownloadOwnerTool .row .col .cover{
  background-color: #0F6E43;
  border-radius: 50%;
  width:45px;
  height:45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:0;
  font-size: 19px;
  font-weight: 500;
  margin-left:10px;
  color:#fff;
}

.LastDownloadOwnerTool .content .image{
  width:120px;
  height:50px;
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
}

.LastDownloadOwnerTool .content .immobile{
  display: flex;
  flex-direction: row;
  gap:10px;
  align-items: center;
}