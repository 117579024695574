.home-plans-section{
    display:flex;
    flex-direction: row;
    gap:20px;
    flex-wrap:wrap;
    justify-content: center;
    width:100%;
    max-width:1240px;
    margin:0 auto;
    position:relative;
    padding:140px 15px 60px;
  
    @media(max-width:1024px){
      gap:40px;
      padding:30px 15px 0px;
    }
  }
  
  .home-plans-section .home-plan-content{
    width:32%;
    position:relative;
    background-color: #EFF3F6;
    border:solid 1px #cdcdcd;
    border-radius:20px;
    height: fit-content;
    transition:0.2s;
  
    &.horizontal{
  
      @media(min-width:768px){
        display:flex;
        width:100%;
        flex-direction:row;
        gap:20px;
        justify-content: center;
      }
    }
  
    &.premium{
      box-shadow: 0 0 6px 1px rgba(0,0,0,0.3);
      border-radius:20px;
      @media(min-width:768px){
        margin-top:-60px;
        border-radius:0 0 20px 20px;
      }
    }
  
    &.premium.horizontal{
      border-radius:20px;
    }
  
    &:hover{
      transform:translate(0, -8px);
    }
  
    & .recomended{
      background-color: #3E5E3F;
      color:#fff;
      width:100%;
      padding-bottom:5px;
      margin:0 auto;
      height:40px;
      border-radius: 20px 20px 0 0;
      align-items: center;
      justify-content:center;
      font-size:16px;
      display:none;
      margin-top:-38px;
      margin-bottom:-5px;
      font-weight:800;
      position:relative;
      z-index:0;
      text-transform: uppercase;
  
      @media(max-width:1024px){
        margin-top:0;
      }
  
      &.premium{
        display:flex;
      }
    }
  
    &.horizontal .recomended{
      display:none;
    }
  
    @media(max-width:1024px){
      width:100%;
    }
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  
  .home-plans-section .home-plans-card{
    display:flex;
    flex-direction: column;
    gap:20px;
    padding:20px;
    max-width:380px;
    justify-content: space-between;
    align-items:center;
    cursor:default;
    transition:0.2s;
    position:relative;
    z-index:1;
  
    & .home-plans-title{
      & h3{
        font-size: 42px;
        font-weight: 800;
      }
    }
  
    & .home-plans-subtitle{
      & p{
        color:#333;
        font-size: 16px;
        max-width:260px;
        text-align: center;
        margin:0 auto;
        font-weight: 600;
      }
    }
  
    & .home-plans-price{
      & h3{
        font-size:48px;
        font-weight: 800;
        color:#333;
    
        & small{
          font-size: 18px;
        }
      }
    }
  
    & .home-plans-cta{
      & button{
        width:280px;
        white-space: nowrap;
        height: 45px;
        background:#3E5E3F;
        border:none;
        font-size:18px;
        font-weight: 600;
        color:#fff;
  
        &:hover{
          background-color: #273f28;
        }
      }
    }
  
    &.start{
      & .home-plans-cta{
        & button{
          background:#356BA9;
    
          &:hover{
            background-color: #1e3d61;
          }
        }
      }
    }
  
    &.free{
      & .home-plans-cta{
        & button{
          background:#333;
    
          &:hover{
            background-color: #222;
          }
        }
      }
    }
  
    & .home-plans-selected-year{
      display:flex;
      align-items: center;
      gap:5px;
  
      & p{
        color:#888;
        font-size:18px;
        text-decoration: line-through;
      }
  
      & span{
        background-color: #709b71;
        color:#fff;
        padding:3px 8px;
        border-radius: 20px;
        font-size: 16px;
        font-weight:800;
        animation: pulse 2s infinite;
      }
    }
  
    & .home-plans-selected{
      & p{
        color:#333;
        font-size:18px;
        margin-bottom:-20px!important;
      }
    }
  
    & .home-plans-observation{
      & p{
        font-size: 14px;
        color:#333;
        max-width:280px;
        text-align: center;
        margin:0 auto;
        line-height: 1.4;
      }
  
      & a{
        font-weight:600;
        margin-top: -10px!important;
        display: block;
      }
    }
  
    & .home-plans-rec{
      display:flex;
      flex-direction: row;
      align-items: center;
  
      & .home-plans-rec-option{
        width:100px;
        height:35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color:#fff;
        color:#333;
        border:solid 1px #333;
        cursor:pointer;
        text-transform: uppercase;
        font-weight: 800;
  
        &.active{
          background-color: #333;
          color:#fff;
        }
        &:hover{
          background-color: #555;
          color:#fff;
        }
      }
    }
  }
  
  .home-plans-section.horizontal .home-plans-card{
    width:360px;
  }
  
  .list-open{
    display:none;
  
    @media(max-width:1024px){
      background-color: #dadee2;
      width:100%;
      display:flex;
      align-items: center;
      justify-content: space-around;
      gap:8px;
      padding:5px;
      border-radius:0 0 20px 20px;
      cursor:pointer;
      height:40px;
      border-top:none;
  
      & span{
        font-size: 18px;
        font-style: uppercase;
      }
  
      & i{
        font-size: 24px;
        transition:0.2s;
      }
  
      &.active{
        & i{
          transform: rotate(180deg);
        }
      }
    }
  }
  
  .home-plans-list{
    padding:20px;
  
    & h5{
      font-size: 18px;
      color:#888;
      font-style: italic;
      margin-bottom:20px!important;
    }
  
    & ul{
      list-style: none;
      padding-left: 0;
  
      & li{
        font-size: 18px;
        display: flex;
        padding:4px 0;
        font-weight: 400;
        color:#333;
        gap:8px;
  
        &.high{
          font-size: 20px;
          color:#3E5E3F;
          font-weight: 800;
        }
  
        & i{
          font-size:22px;
          margin-top:-2px;
        }
      }
    }
  }
  
  @media(max-width:1024px){
    .home-plans-list{
      height:0;
      overflow: hidden;
      padding:0;
  
      &.active{
        height:auto;
        padding:20px;
      }
    }
  }
  
  .home-plan-content.horizontal .home-plans-list{
    @media(min-width:768px){
      width:calc(100% - 380px);
    }
  
    h4{
      color:#3E5E3F;
      margin-bottom:20px!important;
      font-size: 18px;
      margin-top:40px!important;
    }
  }
  
  .home-plan-content.horizontal .home-plans-list ul{
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
  
  .home-plans-select-type{
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap:30px;
    margin-top:60px;
  
  
    & h2{
      color:#3E5E3F;
      font-size:28px;
      font-weight:500;
    }
  
    & h3{
      font-size:32px;
      font-weight: 600;;
    }
  
    & p{
      color:#333;
      font-size: 18px;
    }
  
    & .home-plans-type-options{
      display:flex;
      gap:20px;
  
      & .home-plans-type-button{
        width:120px;
        height:40px;
        background-color: #888;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        font-size: 18px;
        cursor:pointer;
  
        &.active{
          background-color: #3E5E3F;
        }
      }
    }
  }

  .home-plans-promo{
    max-width: 100%;

    & .home-black-friday{
      width:100%;
      background-position: center center;
      background-size: cover;
      display: flex;

      & .home-black-friday-col{
        padding:0 10px;
        height:90px;
        display:flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:nth-child(1){
          width:45%;
        }

        &:nth-child(2){
          width:55%;
        }

        & .coupon{
          color:#fff;
          font-weight: 600;
          font-size: 16px;
          margin-top:-5px;

          & span{
            color:#00FF96;
        }
        }

        & b{
          color:white;
          font-weight: 700;
          font-family: 'Poppins';
          font-size: 26px;
        }

        & p{
          font-size: 7px;
          color:#fff;
        }

        & img{
          max-width:100%;
        }
      }
    }
  }