.thank-you-title{
    text-align:center;
    border-radius:6px;
    padding:80px 15px;
    background-position: center center;
    background-size:cover;
    margin-bottom:15px;
  
    & h1{
      font-size:22px;
      color:#fff;
    }
  }
  
  .thank-you-status{
    text-align: center;
    max-width:600px;
    margin:40px auto;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    gap:20px;
  
    & h3{
      font-size: 18px;
    }
  }