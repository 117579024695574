

.speedometer{
  height:205px!important;
  margin-top:10px!important;
}

.form-check-input{
  background-color: #f4f4f4!important;
}

.form-check-input:checked{
  background-color: #3E5E3F!important;
}

.container-text-muted{
  padding:20px 0;
}

input[switch] + label:after{
  background-color: #fff!important;
}

.modal-body{
  padding: 20px!important;
  width:100% !important;
}

@media(max-width:768px){
  .nav-link{
    padding:0!important;
  }
  .form-check-input.form-check-input{
    padding:5px 0;
  }
  .speedometer{
    transform: scale(0.80);
    margin-left: -15px!important;
  }
}

.moreCaptationLimit{
  color:#3E5E3F!important;
  background-color: #fff!important;
  border:solid 1px #3E5E3F!important;
}

.moreCaptationLimit:hover{
  color:#fff!important;
  background-color: #3E5E3F!important;
}


#addImovel .optionButton{
  background-color: #cdcdcd;
  color:#333;
  height:40px;
  width:200px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor:pointer;
}

#addImovel .optionButtonContainer{
  display: flex;
  gap:20px;
  padding:10px 0 30px;
}

#addImovel .optionButton.active{
  background-color: #3E5E3F;
  color:#fff;
}

@media(max-width:768px){
  #addImovel .optionButtonContainer{
    flex-direction:column;
    align-items: center;
    width:100%;
  }
}

.check-list-container{
  display:grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 15px;
  margin:30px 0;
}

.check-list-container .input-check-container{
  display:flex;
  gap:5px;
}

.check-list-container .input-check-container input{
  width:20px;
  height:20px;
}