.account-container .account-row{
    display:flex;
    align-items: center;
    padding:20px 0 20px;
  }
  
  .account-container .account-row:nth-child(1){
    justify-content: space-between;
    border-bottom:solid 1px #cdcdcd;
    padding-top:0;
  }
  
  .account-container .account-row .logged{
    display:flex;
    align-items: center;
    gap:8px;
  
    & .step{
      background-color: #3E5E3F;
      color:#fff;
      font-size: 20px;
      width:30px;
      height:30px;
      display:flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-weight: 800;
    }
  
    & i{
      font-size: 34px;
      color:#3E5E3F;
    }
  
    & h4{
      font-size: 18px;
      font-weight: 800;
    }
  }
  
  .account-container .account-row .alter{
    & span{
      text-decoration: underline;
      cursor:pointer;
    }
  }
  
  .account-container .login-account{
    display:flex;
    flex-direction:column;
    gap:10px;
  
    & button{
      border:solid 1px #cdcdcd;
      height: 40px;
      width:180px;
      font-size:18px;
      background-color: #fff;
      color:#333;
      border-radius:8px;
  
      &:hover{
        background-color: #3E5E3F;
        color:#fff;
      }
    }
  
    & p{
      font-size:16px;
  
      & span{
        text-decoration: underline;
        cursor:pointer;
        font-weight: 800;
        
        &:hover{
          color:#101010;
        }
      }
    }
  }
  
  .payment-container{
    display:flex;
    flex-direction:column;
    gap:15px;
    margin-bottom:60px;
  }
  
  .payment-container .payment-row{
    display:flex;
    align-items: center;
    padding:20px 0 20px;
  }
  
  .payment-container .payment-row:nth-child(1){
    justify-content: space-between;
    border-bottom:solid 1px #cdcdcd;
    padding-top:0;
  }
  
  .payment-container .payment-row .logged{
    display:flex;
    align-items: center;
    gap:8px;
  
    & .step{
      background-color: #3E5E3F;
      color:#fff;
      font-size: 20px;
      width:30px;
      height:30px;
      display:flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-weight: 800;
    }
  
    & i{
      font-size: 34px;
      color:#3E5E3F;
    }
  
    & h4{
      font-size: 18px;
      font-weight: 800;
    }
  }
  
  .payment-container .payment-row .logged.inactive{
    display:flex;
    align-items: center;
    gap:8px;
  
    & .step{
      background-color: #888;
    }
  
    & h4{
      color:#888;
    }
  }
  
  .payment-method-container{
    display:flex;
    align-items: center;
    gap:20px;
    max-width:100%;
  }
  
  .payment-method-container .payment-method-option{
    display:flex;
    flex-direction:column;
    align-items: center;
    gap:10px;
    text-align:center;
    background-color: #fff;
    border-radius:8px;
    width:220px;
    height:90px;
    flex-wrap:wrap;
    cursor:pointer;
    position:relative;

    & .year-pix-offer{
      position:absolute;
      bottom: 0;
      right:0;
      background-color: #fff;
      width:40px;
      height:20px;
      border-radius: 0 0 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 600;
    }
  
     & span{
      width:100%;
      height:100%;
      background-size: cover;
      background-position:center center;
      border-radius:5px;
     }
  
    &.credit-card{
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap:8px;
      border:solid 1px #888;
  
      & img{
        width:40px;
      }
  
      & p{
        font-size:18px;
      }
    }
  
    &.active{
      border:solid 3px #628adc;
    }
  }
  
  .credit-card-container{
    width:100%;
  }
  
  .checkout-cart-section .reverse{
    @media(max-width:768px){
      flex-direction: column-reverse;
    }
  }
  
  .code-pix-container{
    text-align: left;
  
    .code-pix{
      padding:10px;
    }
  
    & .input-code{
      width:100%;
      display:flex;
      align-items: center;
      gap:5px;
    }
  
    & input{
      width:calc(100% - 45px);
      height:40px;
      border-radius:6px;
      border:solid 1px #cdcdcd;
      background-color: #f4f4f4;
      padding:0 5px;
    }
  
    & button{
      height:40px;
      width:40px;
      display:flex;
      align-items: center;
      justify-content: center;
      font-size:18px;
      border-radius:5px;
      border:none;
      background-color: #3E5E3F;
      color:#fff;
    }
  }
  
  .paid-pix-btn{
    width:100%;
    max-width:360px;
    background-color: #fff;
    color:#3E5E3F;
    border:solid 1px #3E5E3F;
    border-radius: 5px;
    margin-top: 15px;
    cursor:pointer;
    height:40px;
    font-size: 18px;
    font-weight:600;
  
    &:hover{
      background-color: #3E5E3F;
      color:#fff;
    }
  }