.SchedulingsOwner{
  background-color: #fff;
  border-radius:10px;
  padding:20px;
  box-shadow: 0 0 7px #cdcdcd;
  height:380px;
}

.SchedulingsOwner .title h3{
  font-size:18px;
  font-weight:500;
  margin:0;
}

.SchedulingsOwner .row.items{
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
}

.SchedulingsOwner .row.items .col{
  flex:1;
}

.SchedulingsOwner .title .legenda{
  display: flex;
  flex-direction: row;
  gap:30px;
}

.SchedulingsOwner .title .legenda p{
  font-size:12px;
  display:flex;
  gap:5px;
  margin:0;
}

.SchedulingsOwner .title .legenda p.your::before{
  content:'';
  display:block;
  width:20px;
  height:20px;
  background-color: #f4f4f4;
}

.SchedulingsOwner .title .legenda p.other::before{
  content:'';
  display:block;
  width:20px;
  height:20px;
  background-color: #c4dab9;
}

.SchedulingsOwner .content .item-row{
  display: flex;
  width:calc(100% - 20px);
  flex-wrap:wrap;
  align-items: center;
  padding:10px;
  margin:10px 0;
  border-radius:8px;
}

.SchedulingsOwner .content .item-row .col:nth-child(1){
  flex:1;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
}

.SchedulingsOwner .content .item-row .col:nth-child(2){
  flex:3;
  font-size: 13px;
  font-weight: 500;
}

.SchedulingsOwner .content .item-row .col:nth-child(3){
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SchedulingsOwner .content .item-row .col:nth-child(3) .label{
  color:#fff;
  font-size: 10px;
  font-weight: 500;
  padding:3px 8px;
  border-radius: 3px;
}

.SchedulingsOwner .content .item-row .col:nth-child(3) .label.pendente{background-color: #f1ce41;}
.SchedulingsOwner .content .item-row .col:nth-child(3) .label.confirmado{background-color: #62c90e;}
.SchedulingsOwner .content .item-row .col:nth-child(3) .label.cancelado{background-color: #da2c3e;}
.SchedulingsOwner .content .item-row .col:nth-child(3) .label.parceria{background-color: #628adc;}
.SchedulingsOwner .content .item-row .col:nth-child(3) .label.concluido{background-color: #0f6e43;}