.construction-banner-container{
    width:100%;
    max-width: 1280px;
    margin:60px auto;
    background-position: center center;
    background-size:cover;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    height:480px;
  
    & img{
      width:100%;
    }
  
    & .construction-banner-image{
      width:500px;
      height:480px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    & .construction-banner-content{
      display:flex;
      width:calc(100% - 600px);
      height:100%;
      flex-direction:column;
      justify-content:center;
      align-items: center;
      gap:20px;
  
      & h2{
        color:#fff;
        font-family:'Poppins';
        font-weight: 300;
  
        & b{
          font-weight: 600;
        }
      }
    }
  
    & button{
      width:220px;
      height:45px;
      background-color: #fff;
      border:none;
      border-radius: 10px;
      font-size: 20px;
      color:#333;
  
      &:hover{
        background-color: #3E5E3F;
        color:#fff;
      }
    }
  }
  
  @media(max-width:768px){
    .construction-banner-container{
      flex-direction: column;
      height:auto;
      gap:0;
      padding-bottom:20px;
      background-position: -100px center;
      margin:30px auto 0;
  
      & .construction-banner-image{
        width:100%;
        padding:0 20px;
        height:360px;
      }
    
      & .construction-banner-content{
        width:100%;
  
        & h2{
          color:#333;
          margin-top:-20px!important;
          font-size:20px;
        }
  
        & button{
          margin:20px 0;
        }
      }
    }
  }