.bg_desktop {
  background: linear-gradient(180deg, rgba(234,247,235,1) 100%, rgba(255,255,255,1) 100%);
  /* margin-top: 3rem; */
}

.title {
  text-align: center;
  padding: 3rem 2rem 2rem 2rem!important;
  font-weight: bold;
  font-weight: 500;
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;
}

.circle {
  display: flex;
  align-items: baseline;
  width: 262px;
  gap: 1rem;
}

.circle span {
  margin-top: 2rem;
}

.bureaucracy {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #F0F0F0;
  padding: 2rem 0;
  margin-top: 3rem;
}

.container_bureaucracy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .5rem;
}

.bureaucracy_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: green;
  margin-bottom: 0px;
}

.bureaucracy_subtitle {
  font-size: .8rem;
  margin-bottom: 0px;
  width: 300px;
}

.container_img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details {
  content: "";
  display: block;
  width: 320px;
  height: 2px;
  background: #C9C9C9;
  margin: 8px auto;
}

.container_img img {
  width: 300px;
}

.teste p{
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
  font-size: 16px;
}

@media(min-width: 759px) and (max-width: 1119px) {
  .bg_desktop {
    margin: 0 4rem 4rem;
    background: linear-gradient(180deg, rgba(234,247,235,1) 100%, rgba(255,255,255,1) 100%);
    border-radius: .5rem;
  }
  .container_bureaucracy {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .bureaucracy {
    background-color: transparent;
  }
  .bureaucracy_title {
    line-height: 1;
  }
  .bureaucracy_subtitle {
    font-size: .9rem;
    width: 400px;
    text-align: center;
  }
  .bureaucracy_subtitle span {
    font-weight: bold;
  }
  .details {
    display: none;
  }
  .container_img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container_img img {
    width: 400px;
  }
  .bureaucracy {
    margin-top: 0rem;
    padding: 0rem;
  }
}

@media(min-width: 1200px) {
  .circle {
    display: flex;
    gap: 1rem;
    align-items: baseline;
  }
  .bg_desktop {
    margin: 0;
    background: linear-gradient(93deg, rgba(234,247,235,1) 0%, rgba(255,255,255,1) 100%);
  }
  .bureaucracy_subtitle {
    width: 400px;
    text-align: center;
  }
  .details {
    width: 450px;
  }
  .container_bureaucracy {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .bureaucracy {
    padding: 2rem 0rem;    
  }
  .container_easy {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-bottom: 3rem; */
  }
  .container_img img {
    width: 450px;
  }
}