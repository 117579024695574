.google-map.all .map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

.google-map.all {
  width: 789px;
  max-width:1360px;
  height: 80vh;
  z-index:99999;
  position: relative;
}

.modal-header {
  height: 60px !important;
}

@media(max-width:768px){
  .google-map.all {
    width: 340px;
    max-width:1360px;
    height: 90vh;
    z-index:99999;
    position: relative;
  }
}

.google-map.all .pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.google-map.all .pin-icon {
  font-size: 4rem;
}

.google-map.all .pin-text {
  font-size: 1.3em;
}

.google-map.all .raio {
  width: 8px;
  height: 8px;
  background-color: #2f734c;
  border-radius: 50%;
  position:relative;
}

.google-map.all .raio:hover {
  z-index:99999999999999;
}

.google-map.all .raio.zoom {
  width: 16px;
  height: 16px;
  background-color: #2f734c41;
  border: solid 1px #2f734c;
  border-radius: 50%;
  position:relative;
}


.google-map.all .raio.zoom::after {
  content:'';
  position:absolute;
  top:4px;
  left:4px;
  width: 8px;
  height: 8px;
  background-color: #2f734c;
  border-radius: 50%;
  transition:0.3s;
}


.google-map.all .raio:hover span,
.google-map.all .raio.zoom2 span{
  display:flex;
}

.google-map.all .raio.zoom2:hover .cover{
  width:80px;
  height:60px;
  background-size:cover;
  background-position: center center;
  display:block;
  border-radius:10px;
}

.google-map.all .raio .imovelCard{
  display: none;
}

.google-map.all .raio.zoom2:hover .imovelCard{
  width:90px;
  border-radius:10px;
  padding:3px 0;
  background-color:#fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow:0 0 8px rgba(0,0,0,0.4);
}

.google-map.all .raio.zoom2:hover .imovelCard .infos{
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap:5px;
  width:calc(100% - 10px);
  margin:5px;
  font-size:10px;
  color:#fff;
}

.google-map.all .raio.zoom2:hover .imovelCard .infos span{
  font-size: 16px;
  color:#fff;
  margin:0;
}

.google-map.all .raio.zoom2:hover .imovelCard .address{
  padding:0 5px;
  width:calc(100% - 10px);
  font-size: 10px;
  line-height: 1.2;
}

.google-map.all .raio.zoom2:hover .imovelCard .infos .bed,
.google-map.all .raio.zoom2:hover .imovelCard .infos .garage{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap:5px;
  background-color: #2f734c;
  border-radius: 10px;
  padding:0 5px;
  height:15px;
}



.cityQtd .pinCity{
  font-size:13px;
  background-color: #2f734c9a;
  border-radius:15px;
  color: #fff;
  width:60px;
  height:30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.4);
}

.cityQtd .pinCity:hover{
  background-color: #2f734c;
}

.cityQtd .pinCity .material-symbols-rounded{
  font-size:15px;
  margin-right:3px;
}

.google-map.all .raio .price{
  background-color: #2f734c;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 500;
  padding:4px 0;
  display:none;
  flex-direction: row;
  min-width: 75px;
  color: #fff;
  justify-content: center;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  align-items: center;
  position:relative;
  z-index:4;
  margin-left:-2.5px;
  margin-top:-2.5px;
}

.google-map.all .raio .price:hover{
  background-color: #00BB4C;
  color: #fff;
}

@media screen and (min-width: 799px) {
  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }
}