.indicator-list-item{
    display:flex;
    flex-wrap:wrap;
    border-bottom:solid 1px #cdcdcd;
    padding:10px;
  
    &:hover{
      background-color: #f4f4f4;
    }
  
    & .origin{
      flex:2;
    }
    & .indicator{
      flex:4;
    }
    & .indicator-type{
      flex:2;
  
      & span{
        background-color: #333;
        color:#fff;
        padding:1px 8px;
        border-radius:10px;
        font-size:11px;
      }
    }
    & .user{
      flex:5;
    }
    & .user-type{
      flex:2;
  
      & span{
        background-color: #333;
        color:#fff;
        padding:1px 8px;
        border-radius:10px;
        font-size:11px;
      }
    }
    & .user-plan{
      flex:2;
  
      & span{
        padding:1px 8px;
        border-radius:10px;
        font-size:11px;
  
        &.free{
          background-color: #cdcdcd;
          color:#333;
        }
        &.start{
          background-color: #628adc;
          color:#fff;
        }
        &.premium{
          background-color: #3E5E3F;
          color:#fff;
        }
      }
    }
    & .date{
      flex:2;
    }
    & .edit{
      flex:1;
      text-align: center;
  
      & i{
        font-size:20px;
        cursor:pointer;
        transition:0.2s;
      }
    }
  
    @media(max-width:768px){
      flex-wrap:nowrap;
      overflow:auto;
      gap:20px;
  
      & .indicator, & .user{
        white-space: nowrap;
      }
    }
  }
  
  .indicator-list-item.title div{
    font-weight:800;
    font-size:13px;
  }
  
  .indicator-list-item.title{
    background-color: #f4f4f4;
  
    @media(max-width:768px){
      display:none!important;
    }
  }