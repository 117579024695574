.instagram-content{
    width: 100%;
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.instagram-content h1{
    margin-bottom: 40px;
    font-size: 2rem;
}

.instagram-content h1 span{
    font-weight: bold;
}

.instagram-content .insta-grid{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    cursor: pointer;
}

.instagram-content .insta-grid .insta-card:hover .hovered-card{
    transition: .3s;
    background-color: rgba(0, 0, 0, 0.744);
}

.instagram-content .insta-grid .insta-card .hovered-card{
    width: 100%;
    height: 100%;
    background-color: unset;
    display: flex;
    transition: .3s;
    justify-content: center;
    align-items: center;
}

.instagram-content .insta-grid .insta-card:hover .hovered-card p{
    color: white;
    max-width: 200px;
    opacity: 1;
    transform: translateY(0%);
    transition: .3s;
}

.instagram-content .insta-grid .insta-card .hovered-card p{
    opacity: 0;
    display: flex;
    max-width: 200px;
    transition: .3s;
    transform: translateY(34%);
}

.instagram-content .insta-grid .mySwiper{
    max-width: 401px!important;
    width: 100%;
}

.instagram-content .swiper {
    width: 350px !important;
}

.play-instagram {
    background-image: url('../assets/iconplay.png');
    background-position: center;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
}