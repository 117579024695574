.contentPopUp{
    width:100%;
    height:410px;
    background-position: center center;
    background-size: cover;
    border-radius:5px;
    position:relative;
}

.contentPopUp .closeModal{
    position: absolute;
    right:15px;
    top:15px;
    cursor:pointer;
    font-size: 20px;
}

.title {
    display: flex !important;
    justify-content: center !important;
    .title-new-plataform {
        color: #3E5E3F;
        font-size: 1.3rem;
        font-weight: 400;
        text-align: center;
    }
    .title-fs {
        color: #3E5E3F;
        font-size: 2rem;
        font-weight: 400;
    }
    .subtitle-fs {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .8rem;
        font-weight: 400;
        margin-top: 0.5rem !important;
    }
}

.modal-site .modal-content{
    width:calc(100% - 10px);
}

.modal-site .modal-body{
    position:relative!important;
    overflow:hidden;
 }

 .modal-dialog.modal-site.modal-lg.modal-dialog-centered{
    @media(max-width:768px){
        width:100%;
    }
 }

.subtitle {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    background-size: 100% 100%;
    background-position: center;

    @media(min-width:1024px){
        width:660px;
        max-width:100%;
    }

    .content-text{
        display:flex;
        flex-direction:column;
        gap:5px;
        width:320px;
        max-width:100%;

        @media(max-width:768px){
            width:auto;
        }

        h3{
            color:#30D26C;
            font-size:30px;
            margin-bottom:30px!important;
        }
        h5{
            color:#888;
            font-size:22px;
            font-weight:600;
        }
        img{
            margin-bottom:15px;
            max-width:240px;
        }
        h4{
            color:#888;
            font-size:18px;
            font-weight:400;
            margin-top:-5px!important;
        }
        p{
            margin-top:15px!important;
            font-size:14px!important;
        }
    }
    .subtitle-new-plataform {
        display: flex !important;
        align-items: center !important;
        margin-bottom: 1.5rem !important;
        font-size: 1.2rem;
    }
    .content-new-plataform {
        display: flex !important;
        gap: 1rem;
        p {
            width: 250px;
            margin-bottom: 2rem !important;
            font-size: .8rem !important;
        }
        ul {
            padding-left: 1rem;
            font-size: .8rem !important;
        }
    }
    .content-fs {
        display: flex !important;
        // padding:20px;
        width: 100%;
        align-items: center;

        @media(min-width:1024px){
            width:660px;
            max-width:100%;
        }

        @media(max-width:768px){
            align-items: start;
        }

        flex-wrap:wrap;
        .img-banner {
            width: 280px !important;
            position:absolute;
            right:8px;
            top:55px;

            @media(max-width:1024px){
                width: 280px !important;
                position:absolute;
                right:10px;
                top:50px;
            }

            @media(max-width:768px){
                width: 200px !important;
                position:absolute;
                right:-40px;
                top:55px;
            }

            @media(max-width:380px){
                width: 140px !important;
                position:absolute;
                right:-70px;
                top:75px;
            }
        }
        p {
            font-size: 0.8rem;
            text-align: justify;
            width: 250px;
            margin-bottom: 1rem !important;
            line-height: 1.3;
        }

        i{
            position:absolute;
            top:20px;
            right:15px;
            font-size:28px;
            cursor:pointer;
        }
    }
}

.modal-home {
    .modal-header {
        border-bottom: none !important;
    }
    .modal-content {
        background-image: url('./../../../../assets/images/popups/backgroundbanner.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: none !important;
        .title-home {
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            justify-content: center !important;
            margin-top: 2rem !important;
            h5 {
                color: #3E5E3F;
                font-size: 2rem;
                font-weight: 400;
            }
            p {
                font-size: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                color: #101010;
                margin-top: 1rem !important;
            }
        }
    } 
    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        p {
            font-size: 1.3rem;
            padding-left: 1rem !important;
        }
        ul {
            width: 220px;
            list-style: none;
            padding-left: 1rem;
            li {
                color: #3E5E3F;
                font-size: .8rem;
                width: 200px;
                margin-bottom: .5rem !important;
            }
            li:last-child {
                color: #3E5E3F;
                font-size: .8rem;
                width: 167px;
                margin-bottom: .5rem !important;
            }
        }
    }
    .container-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cadore {
        width: 220px !important;
    }
    .container-button {
        display: flex;
        justify-content: center;
        padding-bottom: 2rem !important;
    }
}

.modal-footer {
    border: none !important;
    display: initial !important;
    .container-buttons {
        display: flex !important;
        justify-content: center !important;
        .button-close {
            width: 138px;
            height: 42px;
            background-color: #bdbbbb !important;
            border: none !important;
            color: #fff !important;
            &:hover {
                background-color: #b7b5b5 !important;
                transition: .6s;
            }
        }
        .button-generate {
            width: 138px;
        }
    }
}

.close {
    top: 10px !important;
    right: 9px !important;
    cursor: pointer;
}

.container-flag {
    display: flex;
    align-content: center;
    gap: .5rem;
    img {
        margin-top: .5rem !important;
    }
}


@media(max-width:768px){
    .contentPopUp{
    height:480px;
    }
    .modal-home {
        .container-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .modal-body {
            padding: 0px !important;
            margin-top: -1rem;
        }
        ul {
            padding-left: 0rem !important;
        }
        p {
            text-align: center;
            padding-left: 0rem !important;
        }
        .cadore {
            width: 180px !important;
        }
        .subtitle-fs {
            font-size: .6rem;
        }
        .container-flag {
            justify-content: center;
        }
        .title-new-plataform {
            color: #3E5E3F;
            font-size: 1.1rem;
            font-weight: 400;
            text-align: center;
        }
        .container-button {
            padding-bottom: 0rem !important;
        }
    }
    .content-fs {
        display: flex !important;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        img {
            width: 180px;
        }
    }
    .modal-body {
        padding: 0px !important;
        width:100% !important;
    }
    .subtitle {
        .content-new-plataform {
            display: flex !important;
            gap: 1rem;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: -2rem !important;
            img {
                width: 120px;
            }
            p {
                width: 250px;
                margin-bottom: 1rem !important;
                font-size: .7rem !important;
            }
            ul {
                padding-left: 1rem;
                font-size: 0.7rem !important;
            }
        }
    }
    .subtitle-new-plataform {
        display: flex !important;
        align-items: center !important;
        margin-bottom: 1.5rem !important;
        font-size: 1rem !important;
        text-align: center;
    }
}

.serie_container{
    background-image:url('./../../../images/popups/bg_serie.png');
    background-position:center center;
    background-size:cover;
    max-width:640px;
    padding:20px;
    border-radius:5px;

    .close{
        font-size:18px;
        color:#fff;
    }
}

.serie_row{
    display:flex;
    gap:10px;
}

.serie_row.logo{
    justify-content: space-between;
    align-items: center;
    max-width:360px;
    margin:auto;

    img{
        width:100px;
    }
}

.serie_left_col{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding:10px 10px 20px;

    button{
        margin-top:-20px;
        background-color: #86EF56;
        color:#254f2c;
        border:none;
        padding:5px 10px;
        border-radius:8px;
        font-weight:900;
        box-shadow:0 0 5px #254f2c;
        transition:0.2s;
        font-size:18px;
    }

    button:hover{
        transform: scale(1.03);
    }
}

.serie_right_col{
    display:flex;
    flex-direction:column;
    gap:5px;
    align-items: flex-start;
    justify-content: center;
    padding:10px 10px 20px;

    h3{
        font-size: 20px;
        color:#fff;
        font-weight:900;
    }
    h5{
        color: #86EF56;
        text-shadow:0 0 5px #254f2c;
        font-size:26px;
        font-weight:900;
        margin:10px 0!important;
    }
    p{
        font-size: 17px;
        color:#fff;
        font-weight:400;
        line-height: 1.2;

        b{
            font-weight:900;
        }
    }

    img{
        display:none;
    }
}

.serie_countdown{
    display:flex;
    gap:8px;
    margin-top:15px;

    span{
        color: #86EF56;
        text-shadow:0 0 5px #254f2c;
        font-size:32px;
        font-weight:900;
    }
}

.serie_countdown_item{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    div{
        background-color: #fff;
        color:#333;
        font-size:32px;
        font-weight:900;
        width:65px;
        height:65px;
        display:flex;
        align-items:center;
        justify-content:center;
        margin-bottom:5px;
    }

    p{
        font-size: 14px;
        text-align:center;
    }
}

@media(max-width:640px){
    .serie_row{
        flex-direction:column;
    }

    .serie_row.logo{
        max-width:100%;
        flex-direction:row;
        justify-content: space-around;
    
        img{
            width:90px;
        }
    }
    .serie_right_col{
        img{
            display:block;
            margin:auto;
        }
        h3{
            margin-top:-20px!important;
            text-align:center;
        }
        h5{
            text-align:center;
            width:100%;
        }
        p{
            text-align:center;
            width:100%;
        }
    }
    .serie_left_col{
        img{
            display:none;
        }
    }
    .serie_countdown{
        justify-content: center;
        width: 100%;
    }
    .react-countdown-simple{
        width: 100%;
    }
}

.modal-tinder{
    & .content-fs .content-img{
        width:55%;

        & img{
            max-width:calc(100% + 120px);
            margin-bottom: -20px;
            margin-left: -20px;
        }
    }
    & .content-fs .content-text{
        width:45%;
        
        & h5{
            color:#fff;
            font-family: 'Poppins';
            font-weight:400;
            font-size:22px;
            text-shadow: 0px 2px 3px rgba(0,0,0,0.5);
        }
        & p{
            color:#fff;
            font-family: 'Poppins';
            font-weight:500;
            font-size:20px;
        }
    }
    .content-fs .content-img .text{
        margin-bottom:-80px;

        & h2{
            color:#fff;
            font-family: 'Poppins';
            text-shadow: 0px 2px 3px rgba(0,0,0,0.5);
        }
    }
    & .ri-close-fill{
        color:#fff;
    }
    & button{
        color:#fff;
        font-family: 'Poppins';
    }

    @media(max-width:768px){
        & .subtitle{
            background-size: 220% 100%;
            background-position: right center;
        }
        & .content-fs .content-img{
            width:100%;
            margin-bottom: 10px;
    
            & img{
                max-width:100%;
                width:100%;
                margin-bottom: 0px;
                margin-left: 0px;
            }
        }
        & .content-fs .content-text{
            width:100%;
        }
        .content-fs .content-img .text{
            margin-bottom:-60px;
        }
    }
}

.content-construtoras{
    display:flex;
    gap:30px;
    align-items: center;
    position:relative;
    padding:20px;

    & p{
        color:#fff;
        font-weight: 800;
    }

    & .content-img{
        // width:400px;

        & img{
            width:480px;
            max-width: 100%;
        }
    }

    & i{
        position: absolute;
        right:0px;
        top:-10px;
        font-size: 24px;
        cursor:pointer;
    }

    & .content-text{
        height:100%;
        display:flex;
        width:auto;
        justify-content: center;
        flex-direction: column;

        & h2{
            color:#fff;
            font-size:26px;
            font-family: 'Poppins';
            font-weight: 300;

            & b{
                font-weight: 600;
            }
        }

        & button{
            width:180px;
            height:40px;
            border:none;
            border-radius:10px;
            background-color: #fff;
            color:#333;
            margin-top:15px;
            font-size:16px;

            &:hover{
                background-color: #3E5E3F;
                color:#fff;
            }

            &:disabled{
                font-size:14px;
                width:240px;
                background-color: #cdcdcd;
                color:#444;
                cursor:default;

                &:hover{
                    background-color: #cdcdcd;
                    color:#444;
                }
            }
        }
    }
}

@media(max-width:768px){
    .content-construtoras{
        flex-direction: column;

        & .content-text{
            align-items: center;
            width:100%;

            & h2{
                text-align: center;
            }
        }
    }
}

.modal-sweet-text{
    margin:20px 0!important;
    font-family: 'Poppins';
    font-weight: 400;

    & b{
        font-weight: 600;
    }
}

.welcome-modal-list{
    margin:20px 0;
    font-family: 'Poppins';

    & li{
        text-align: left;
        margin-bottom:20px;
        font-weight: 400;
        font-size: 14px;

        & b{
            font-weight: 600;
        }

        & a{
            text-decoration: underline!important;
            font-weight: 500;
        }
    }
}

.black-friday-modal-desktop{
    @media(max-width:768px){
        display:none;
    }
}

.black-friday-modal-mobile{
    display:none;

    @media(max-width:768px){
        display:block;
    }
}

.tauf-popup-close{
    position: absolute;
    top:15px;
    right:15px;
    font-size: 32px;
    cursor:pointer;
}