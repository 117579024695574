#imobile .bg {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  margin-right: .5rem;
  border-radius: .5rem;
}

#imobile .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 4rem;
}

#imobile .title {
  font-size: 1.5rem;
  max-width: 600px;
}

#imobile .imobile_price_first {
  border: 1px solid black;
  font-size: 1.2rem;
  text-align: center;
  padding: 0rem 2rem !important;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem !important;
}

#imobile .container_price_imobile {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#imobile .title::after {
  content: "";
  display: block;
  height: 2px;
  width: 160px;
  background-color: var(--primary-color);
  margin-top: .5rem;
}

#imobile .icon_maps {
  margin-bottom: 1rem;
}

#imobile .container_location_share {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  cursor: pointer;
}

#imobile .container_location {
  display: flex;
  align-items: center;
}

#imobile .share {
  display: flex;
  align-items: center;
  gap: .3rem;
  color: #FFF;
  padding: .1rem .3rem !important;
  border-radius: .2rem;
}

#imobile .share p {
  font-size: .7rem;
}

#imobile .container_imobile_config {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

#imobile .config {
  display: flex;
  align-items: center;
  gap: 1rem;
}

#imobile .container_config {
  display: flex;
  align-items: center;
  gap: .3rem;
}

#imobile .container_config p {
  font-size: 1rem;
}

#imobile .about {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

#imobile .container_about {
  max-width: 400px !important;
}

#imobile .config_about {
  border: 1px solid gainsboro !important;
  border-radius: 1rem;
  padding: 1rem 5rem 1rem 1rem;
  font-size: .9rem;
  width: 500px !important;
}

#imobile .characteristics {
  margin: 1rem 0;
}

#imobile .container_price {
  border: 1px solid gainsboro !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2rem 1rem;
  width: 300px;
  height: 365px;
  border-radius: .3rem;
}

#imobile .price {
  border: 1px solid var(--primary-color);
  font-size: 1.2rem;
  text-align: center;
  padding: 0rem .3rem;
  border-radius: .5rem;
  margin-bottom: .5rem;
}

#imobile .condominium,
#imobile .iptu {
  font-size: .9rem;
  text-align: right;
  margin-bottom: .5rem;
}

#imobile .condominium::after,
#imobile .iptu::after {
  content: "";
  display: block;
  height: 1px;
  width: 270px;
  background-color: gainsboro;
  margin-top: .5rem;
}

#imobile .container_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

#imobile .button {
  margin-top: 1rem;
  height: 35px;
  padding: .2rem 2rem;
  text-align: center;
  border: none;
  background-color: var(--primary-color);
  color: #FFF;
  font-weight: 500;
  cursor: pointer;
  border-radius: .2rem;
}

#imobile .container_people {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 1rem;
}

#imobile .people_img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

#imobile .people_contact {
  font-size: .9rem;
}

#imobile .cresci {
  font-size: .7rem;
}

/* SWIPER */

#imobile .title_slide {
  text-align: center;
  margin-top: 4rem !important;
  margin-bottom: 0rem !important;
  font-size: 1.3rem;
  font-weight: 500;
}

#imobile .bg_second {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 180px;
  margin: 1.5rem 3rem 0rem 3rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

#imobile .container_immobile {
  background-color:rgb(236, 236, 236);
  margin: 0 3rem;
  position: relative;
  border-radius: 0 0 .5rem .5rem;
}

#imobile .title_immobile {
  text-align: left;
  margin-left: 1rem;
  margin-bottom: .5rem;
}

#imobile .immobile {
  font-size: .9rem;
  font-weight: 500;
  padding-top: 0.8rem !important;
  height: 39px;
  margin-left: 1rem !important;
}

#imobile .location {
  font-size: .7rem;
  margin-bottom: 1rem !important;
}

#imobile .config_immobile {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: .7rem;
  padding-top: .5rem;
  height: 25px;
}

#imobile .container_price_slide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
  padding-bottom: 1rem;;
  font-weight: 600;
}

#imobile .container_price_slide p {
  border: 1px solid black;
  border-radius: 1rem;
  padding: .1rem 1.5rem !important;
  font-weight: 600;
}

#imobile .view {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.2rem;
}

#imobile .view_immobile {
  margin-bottom: 1rem !important;
  border-radius: 1rem;
  padding: .1rem 1rem !important;
  font-size: .7rem;
  color: #fff;
  background-color: #000;
  margin-top: 0.1rem !important;
}

#imobile .container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
}

#imobile .container_father {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#imobile .container_carousel {
  max-width: 1190px;
  transform: scale(.8);
  cursor: pointer;
}

#imobile .map {
  margin-top: 1rem;
}

/*! CONFIG WEB */

#imobile .titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 34px;
  margin:50px auto 0;
  color:#101010;
  font-family: 'Poppins';
  margin-bottom: -1rem !important;
}

#imobile .actionIcons{
  position: absolute;
  left: 308px;
  top: 525px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
  z-index: 2;
}

#imobile .actionIcons2{
  position: absolute;
  left: 260px;
  top: 10px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
  z-index: 2;
}

#imobile .actionIcons label,
#imobile .actionIcons2, label {
  cursor: pointer;
}

#imobile .actionIcons:hover{
  opacity:1;
}

#imobile .titleContainer,
#imobile .container_button{
  width:auto;
  text-align: center;
  position:relative;
}

#imobile .titleContainer .actionIcons{
  right:calc(50% - 55px);
  /* top:-30px; */
  left:auto;
}

#imobile .container_button  .actionIcons{
  right:calc(50% - 185px);
  top:0px;
  left:auto;
}

#imobile .actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

#imobile .actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

#imobile.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

#imobile .colorPickerTitle i,
#imobile .colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

#imobile .colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

@media(min-width: 1600px) {
  #imobile .container_carousel {
    max-width: 1400px;
  }
}