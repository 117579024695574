.singleProperty .highFeature{
  flex-direction: row;
  display:inline-flex;
  align-items: center;
}

.singleProperty .highFeature i{
  font-size: 20px;
  margin-right:8px;
}

.singleProperty h1{
  font-size: 32px;
  margin:10px 0 0px;
  font-family: "Nunito";
  font-weight: 600;
  max-width:740px;
}

.singleProperty .place{
  padding:8px 0 30px;
  display: flex;
  flex-direction: row;
  gap:8px;
  align-items: center;
}

@media(max-width:768px){
  .singleProperty h1{
    font-size:22px;
  }
  .singleProperty .place p{
    font-size: 12px;
  }
}

.singleProperty .sku{
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 500;
}

.singleProperty .button-items{
  margin:25px -10px 15px;
}

.singleProperty .slidePhoto{
  object-fit: cover;
  height:420px;
  width:100%;
  border-radius: 5px;
}

.singleProperty .price{
  text-align: center;
  margin:10px 0 20px;
}

.singleProperty .offer{
  text-align: center;
  margin:10px 0 0;
}

.singleProperty .price h3{
  font-size: 26px;
}

.singleProperty .offer h3{
  position:relative;
  font-size: 22px;
  color:#cdcdcd;
  text-decoration: line-through;
}

.singleProperty .sellContract{
  text-align: center;
  color:#fff;
  font-size: 12px;
  width: 35%;
  padding: 0;
}

.singleProperty .percentage{
  color:#fff;
  padding:0;
  position: relative;
  font-size: 12px;
  padding-left:6px;
  align-items: center;
  display: flex;
  font-weight:600;
}

.singleProperty .percentage div{
  width:45px;
  font-weight:600;
}


.singleProperty .percentage span{
  background-color: #b5b5b5;
  color:#333!important;
  font-weight:600;
  font-size:11px;
  top:0;
  bottom:0;
  right:0;
  position:absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width:calc(100% - 45px);
}

.singleProperty .condominium,
.singleProperty .iptu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px #cdcdcd;
  margin-top:30px;
  padding-bottom:8px;
  font-size: 16px;
  font-weight:600;
  height:35px;
}

.singleProperty .iptu{
  margin-bottom:30px;
}

.singleProperty .condominium small,
.singleProperty .iptu small{
  font-size: 14px;
  font-weight:600;
}

.singleProperty #actionContainer{
  max-width:300px!important;
  padding:5px 30px 20px;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.singleProperty .agendarVisita{
  border: solid 1px #3E5E3F;
  background-color: #fff;
  color:#3E5E3F!important;
}

.singleProperty .agendarVisita:focus{
  border: solid 1px #3E5E3F;
  background-color: #fff;
  color:#3E5E3F!important;
}

.singleProperty .agendarVisita:hover{
  border: solid 1px #3E5E3F;
  background-color: #3E5E3F;
  color:#fff!important;
}

.singleProperty .highFeature button{
  background-color:#cdcdcd;
  border-radius:20px;
  width:140px;
  border:0;
  padding:5px 0;
}

.singleProperty .highFeature button:focus{
  border-color:#cdcdcd!important;
}

.singleProperty .swiper-button-prev,
.singleProperty .swiper-button-next{
  background-color: #fff;
  border-radius: 50%;
  width:40px;
  height:40px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  opacity:0.7;
}

.singleProperty .swiper-button-prev:hover,
.singleProperty .swiper-button-next:hover{
  opacity:1;
}

.singleProperty .swiper-button-prev::after{
  color: #3E5E3F;
  font-size: 26px;
  font-weight: 600;
  margin-left: -2px;
}

.singleProperty .swiper-button-next::after{
  color: #3E5E3F;
  font-size: 26px;
  font-weight: 600;
  margin-right: -2px;
}

.singleProperty .button-items button i{
  font-size: 18px;
}

.singleProperty .button-items button{
  background-color: #fff;
}

.singleProperty .button-items-verify {
  color: #3E5E3F!important;
}

.singleProperty .button-items-verify .text-accept {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: .8rem !important;
  margin-bottom: -.5rem !important;
  font-weight: 900;
  font-size: 1.1rem;
}

.singleProperty .button-items-verify button {
  display: flex;
  align-items: center;
  gap: .3rem;
  border: 1px solid #3E5E3F;
  color: #3E5E3F !important;
  background-color: transparent;
  margin-top: 1rem;
}

.button-items-verify {
}

.singleProperty .button-items button:nth-child(1),
.singleProperty .button-items button:nth-child(5){
  border-color: #3E5E3F;
  color: #3E5E3F!important;
}

.singleProperty .button-items button:nth-child(2){
  border-color: #628adc;
  color: #628adc!important;
}

.singleProperty .button-items button:nth-child(3){
  border-color: #3E5E3F;
  color: #3E5E3F!important;
}

.singleProperty .button-items button:nth-child(4){
  border-color: #f1ce41;
  color: #f1ce41!important;
}

.singleProperty .button-items button:nth-child(1):hover,
.singleProperty .button-items button:nth-child(5):hover{
  background-color: #3E5E3F;
  color: #fff!important;
}

.singleProperty .button-items button:nth-child(2):hover{
  background-color: #628adc;
  color: #fff!important;
}

.singleProperty .button-items button:nth-child(3):hover{
  background-color: #3E5E3F;
  color: #fff!important;
}

.singleProperty .button-items button:nth-child(4):hover{
  background-color: #f1ce41;
  color: #333!important;
}

.singleProperty .card-header{
  background-color: #fff;
}

.singleProperty .card-header h3{
  font-size: 20px;
}

.singleProperty .card-header .text_muted{
  margin-top:5px;
  color:#888;
}

.singleProperty .float-end.accor-plus-icon{
  position: absolute;
  top: 25px;
  right:0;
}

.singleProperty .tags{
  display: flex;
  gap:10px;
  flex-wrap:wrap;
}

.singleProperty .tags li{
  list-style: none;
  padding: 3px 8px;
  background-color: #f4f4f4;
  color:#333;
  font-weight: 500;
}

.singleProperty .button-edit-imobile {
  border: none;
  font-size: .7rem;
  margin-left: 1rem;
  border-radius: 0.2rem;
  background: #101010;
  color: #fff;
  padding: 0.1rem 0.5rem;
}

.singleProperty .button-edit-imobile:hover {
  background-color: #fff;
  color: #101010;
  border: 1px solid #101010;
  transition: .6s;
}

.singleProperty .more {
  padding-left: 0px;
  font-size: .7rem;
}

@media(max-width:768px){
  .singleProperty .btn-map{
    margin-top: 20px;
    width:100%!important;
  }
  .singleProperty .place {
    padding: 8px 0 15px;
  }
  .singleProperty .slidePhoto{
    height:280px;
  }
}


// Action Componente Public

#broker .image{
  width:120px;
  height:120px;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  margin:0 auto 15px;
}

#broker{
  text-align: center;
}

#broker h3{
  font-size: 16px;
}

#broker p{
  font-size: 12px;
}

#broker .phone,
#broker .email{
  background-color: #f4f4f4;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:200px;
  margin:10px auto;
  padding:5px 10px;
}

#broker .phone p,
#broker .email p{
  font-size: 14px;
}


//Google Maps --------------------------------------------------------------- //

.map-h2 {
  text-transform: uppercase;
  font-size: 1rem;
  padding: 20px;
  padding-left: 10px;
  text-align: center;
}

#singleMap .google-map>div{
  width: 100%;
  height: 25vw!important;
}


.pin {
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--main-blue);
}

.pin-icon {
  font-size: 4rem;
}

.pin-text {
  font-size: 1.3em;
}

.raio {
  width: 60px;
  height: 60px;
  background-color: #2f734c41;
  border: solid 1px #2f734c;
  border-radius: 50%;
  margin-left: -30px;
  margin-top: -30px;
}

@media screen and (min-width: 799px) {
  .map-h2 {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .pin {
    width: 15vw;
  }

  .pin-icon {
    font-size: 10vw;
  }

  #singleMap .google-map>div{
    min-width:480px;
  }
}

@media(max-width:768px){
  #singleMap .google-map>div{
    height: 45vh!important;
    min-width:340px;
  }
}

//------------------------------------PopUps

.popupText{
  text-align: center;
  margin-top:20px;
  width:100%;
}

.popupText p{
  font-size: 16px;
}

.popupText span{
  color: #2f734c;
  cursor:pointer;
}

.popupText span:hover{
  text-decoration: underline;
}

.popupImage{
  width:100%;
}

.popupImage img{
  max-width:100%;
}

.popupButtons{
  width:100%;
  text-align: center;
}


.popupButtons .comlogo{
  border: solid 1px #3E5E3F;
  background-color: #fff;
  color:#3E5E3F!important;
}

.popupButtons .comlogo:focus{
  border: solid 1px #3E5E3F;
  background-color: #fff;
  color:#3E5E3F!important;
}

.popupButtons .comlogo:hover{
  border: solid 1px #3E5E3F;
  background-color: #3E5E3F;
  color:#fff!important;
}

.integrationOptions img{
  width:100px;
  cursor:pointer;
  transition:0.3s;
}

.integrationOptions img:hover{
  transform: scale(1.03);
}

.popupTextContent {
  text-align: center;
  margin-top: 1.5rem !important;
  font-size: .9rem;
  font-weight: 900;
}

.popupTextContent span {
  color: #3E5E3F;
  font-size: .95rem;
}

.modal-header {
  display: flex !important;
  justify-content: center !important;
  .modal-number-contract-title {
    color: #385439;
    font-size: 1.5rem !important;
    font-weight: 400;
  }
}

.modal-number-contract {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 48px;
  }
  h3 {
    color: #3E5E3F !important;
    font-size: 1.1rem;
    margin: 1.5rem 0 !important;
  }
  p {
    color: #888;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  .modal-number-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    button {
      width: 400px !important;
      padding: .4rem 0 !important;
      border: none;
      cursor: pointer;
    }
    .modal-button-first {
      background-color: #3E5E3F;
      border-radius: .3rem;
      color: #fff;
      &:hover {
        background-color: #385439;
      }
    }
    .modal-button-second {
      background-color: transparent;
      border: 1px solid #212121;
      border-radius: .3rem;
      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
  .modal-rest-number {
    color: #000;
  }
  .modal-rest-number-text {
    text-align: center;
    margin-bottom: 2rem !important;
    width: 362px;
    span {
      font-weight: 900;
    }
  }
  .parceria {
    width: 72px;
  }
}

.modal-rest-number-title {
  color: #3E5E3F !important;

}

@media(max-width:768px){
  .integrationOptions img{
    width:80px;
  }
}


.custom-toggle{
  height:40px;
  width:80px;
  border:solid 1px #dbdbdb;
  border-radius:20px;
  background-color:#3E5E3F;
  position:relative;
  cursor:pointer;
  margin-right:10px;
  box-shadow: inset 0 -2px 5px rgba(255, 255, 255, 0.2), inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.custom-toggle::before{
  content:'R$';
  left:10px;
  position:absolute;
  top:50%;
  transform:translate(0, -50%);
  color:#fff;
  font-size:18px;
}

.custom-toggle::after{
  content:'%';
  right:10px;
  top:50%;
  transform:translate(0, -50%);
  position:absolute;
  color:#fff;
  font-size:18px;
}

.custom-toggle .active-toggle{
  transition:0.2s;
}

.custom-toggle .active-toggle{
  height:30px;
  width:30px;
  top:4px;
  background-color:#f4f4f4;
  transform:translate(4px, 0);
  border-radius:50%;
  position:absolute;
  z-index:2;
  box-shadow: inset 0 1px 3px rgba(255, 255, 255, 1), inset 0 -2px 3px rgba(0, 0, 0, 0.6);
}

.custom-toggle .active-toggle.currency{
  transform:translate(44px, 0);
}

.percentage-info:hover::before{
  content:'Entre em contato com o captador para saber mais detalhes.';
  position:absolute;
  background-color:#f4f4f4;
  padding:10px;
  z-index:99;
  top:20px;
  width:200px;
  transform:translate(-40%, 0%);
}

.share-options-container{
  width:400px;

  @media(max-width:560px){
    width:100%;
  }
}


  @media(max-width:560px){
    .share-options-modal{
      width:100%!important;
    }
  }

.share-options-container .copy-share-input{
  width:100%;
  display:flex;
  border:solid 1px #cdcdcd;
  border-radius:8px;
  height:40px;
  cursor:pointer;
}

.share-options-container .copy-share-input input{
  width:calc(100% - 40px);
  border:none;
  border-radius:8px;
  padding:0 10px;
}

.share-options-container .copy-share-input span{
  width:40px;
  height:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  border-left:1px solid #cdcdcd;
}

.share-options-container .copy-share-input span i{
  font-size:20px;
  transition:0.2s;
}

.share-options-container .copy-share-input span:hover i{
  font-size:24px;
}

.share-options-container .icons-share-container{
  display:flex;
  gap:10px;
  align-items: center;
  margin-top:10px;
}

.share-options-container .share-option{
  display:flex;
  align-items: center;
  justify-content: center;
}

.share-options-container .share-option i{
  font-size:32px;
  cursor:pointer;
  transition:0.2s;
}

.share-options-container .share-option i:hover{
  transform:scale(1.05);
}

.update_now{
  background-color:#628adc;
  border-radius:10px;
  font-size:14px;
  font-weight:600;
  margin:0 10px;
  color:#fff;
  padding:3px 8px;
  display:flex;
  align-items:center;
  gap:5px;

  i{
    color:#fff;
    font-size:14px;
  }
}

.update_medium{
  background-color:#cdcdcd;
  border-radius:10px;
  font-size:14px;
  font-weight:600;
  margin:0 10px;
  color:#333;
  padding:3px 8px;
  display:flex;
  align-items:center;
  gap:5px;

  i{
    color:#333;
    font-size:14px;
  }
}

.update_danger{
  background-color:#da2c3e;
  border-radius:10px;
  font-size:14px;
  font-weight:600;
  margin:0 10px;
  color:#fff;
  padding:3px 8px;
  display:flex;
  align-items:center;
  gap:5px;

  i{
    color:#fff;
    font-size:14px;
  }
}


.premium-property-container{
  margin:20px 0 10px;
  box-shadow:2px 2px 5px rgba(0,0,0,0.3);
  border:solid 1px #cdcdcd;
  padding:20px;
  display:flex;
  flex-direction:column;
  gap:20px;
  align-items:center;
  justify-content:center;
  border-radius:8px;

  & h3{
    font-size:16px;
    text-align:center;
  }

  & span{
    background-color: #3E5E3F;
    font-weight:900;
    font-size:18px;
    color:#fff;
    height:35px;
    padding:0 10px;
    display:flex;
    align-items:center;
    justify-content:center;
  }
  & small{
    background-color: #cdcdcd;
    height:35px;
    padding:0 10px;
    font-size:16px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:700;
  }
  & div{
    display:flex;
    gap:10px;
    align-items:center;
  }

  & button{
    background-color: #3E5E3F;
    color:#fff;
    font-size:18px;
    padding:5px 15px;
    border-radius:8px;
    border:none;
    cursor:pointer;
  }
}

.catalog-select-modal-overlay{
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,0.6);
  z-index:998;
  display:none;
}

.catalog-select-modal-overlay.active{
  display:block;
}

.catalog-select-modal{
  position:fixed;
  background-color:#fff;
  border-radius:10px;
  top:50%;
  left:50%;
  transform:translate(-50%, -400%);
  transition:0.3s;
  z-index:999;
  width:100%;
  max-width:780px;
  padding:20px;
  box-shadow:0 0 5px rgba(0,0,0,0.3);

  @media(max-width:768px){
    padding:10px;
  }
}

.catalog-select-modal.active{
  transform:translate(-50%, -50%);
}

.catalog-select-modal .catalog-select-modal-title{
  display:flex;
  border-bottom: solid 1px #cdcdcd;
  padding:20px 15px;
  margin-bottom:20px;
  position:relative;

  & .catalog-modal-close{
    position:absolute;
    top:10px;
    right:10px;
    font-size: 32px;
    cursor:pointer;
  }
}

.catalog-select-modal  .catalog-select-modal-body{

  & .grid-enterprise-gallery{
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

    @media(max-width:768px){
      max-height:340px;
      overflow: auto;
    }
  }

  & .item-gallery.video,
  & .item-gallery-doc{
    border:solid 2px #cdcdcd;

    &.active{
      border:solid 3px #3E5E3F;
      background-color: #3e5e3f36;
    }

    & small{
      max-width: 100%;
      word-break: break-all;
    }
  }
}

.singleProperty video{
  @media(max-width:560px){
    width:100%;
  }
}