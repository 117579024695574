.result-total-indicators{
    display:flex;
    align-items:center;
    justify-content:space-between;
  }
  
  @media(max-width:768px){
    .filters-list{
      gap:10px;
    }
    .result-total-indicators{
      flex-direction: column-reverse;
      align-items:flex-end;
    }
  }