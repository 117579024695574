.sectionProposalForm .row{
  margin-bottom: 30px;
}

.containerProposalAccept{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.proposalAcceptStatus.col{
  width:300px;
  flex:none!important;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap:8px;
}

.proposalAcceptSuccess.col,
.proposalAcceptDecline.col{
  width:165px;
  flex:none!important;
}

@media(max-width:768px){
  .containerProposalAccept{
    justify-content: flex-start;
    width:500px;
    max-width:100%;
  }
  .proposalAcceptStatus.col{
    margin-bottom:20px;
    justify-content: flex-start;
  }
}