.indicators-modal-overlay{
    position:fixed;
    display:none;
    width:100%;
    height:100%;
    min-height:100vh;
    z-index:4998;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(0,0,0,0.7);
  }
  
  .indicators-modal-overlay.active{
    display:flex;
  }
  
  .indicators-modal{
    position:fixed;
    top:50%;
    left:50%;
    border-radius:10px;
    padding:40px 20px 20px;
    z-index:4999;
    background-color: #fff;
    transition:0.3s;
    transform:translate(-50%, -500%);
    width:640px;
    max-width:100%;
  }
  
  .indicators-modal.active{
    transform:translate(-50%, -50%);
  }
  
  .indicators-close{
    position:absolute;
    right:10px;
    top:10px;
    cursor:pointer;
  
    i {
      font-size:24px;
    }
  }
  
  .indicators-modal{
    h3{
      font-size:22px;
    }
  }
  
  .type-user-container{
    display:flex;
    gap:12px;
    align-items: center;
  
    & .type-user-button{
      padding:0 15px;
      border-radius: 8px;
      height:35px;
      background-color: #f4f4f4;
      display:flex;
      align-items: center;
      justify-content: center;
      font-weight:700;
      cursor:pointer;
  
      &:hover, &.active{
        background-color: #3E5E3F;
        color:#fff;
      }
    }
  }
  
  .indicators-modal .indicators-modal-content{
    & .modal-title{
      margin-bottom:20px;
  
      &  h3{
        font-size:20px;
      }
    }
  
    & .type-user-container{
      margin:5px 0 15px;
    }
  }
  
  .indicators-modal .indicator-modal-footer{
    display:flex;
    justify-content: flex-end;
    gap:8px;
    width:100%;
  }