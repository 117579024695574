.title {
  margin-top: 8rem;
  text-align: center;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
}

.immobile {
  max-width: 300px;
  border-radius: .5rem;
  background-color: #f6f6f6;
}

.bg {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 350px;
  height: 200px;
  border-radius: .5rem .5rem 0 0;
}

.immobile_config {
  padding: 1rem;
}

.immobile_name {
  margin-bottom: 1rem;
  font-weight: 500;
}

.immobile_options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-size: .9rem;
  /* padding-left: 1rem; */
  margin-bottom: 1rem;
}

.immobile_price {
  font-weight: 600;
  font-size: 1.2rem;
}

.immobile_view {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: .5rem;
}

.details::after {
  content: "";
  display: block;
  height: 2px;
  width: 150px;
  background-color: #c6c4c4;
}

.immobile_view p {
  background-color: #1919bf;
  color: #FFF;
  font-size: .8rem;
  padding: .2rem .6rem !important;
  border-radius: .5rem;
  cursor: pointer;
}

.immobile_view p:hover {
  background-color: #FFF;
  color: #1919bf;
  border: 1px solid #1919bf;
  transition: .5s;
}

.container_carousel {
  max-width: 1200px;
  margin: auto;
  cursor: pointer;
  transform: scale(.9);
  margin-bottom: -4rem;
  margin-top: 2rem;
}

.container_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
  font-size: .8rem;
  margin-top: 1rem !important;
}

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 22px;
  margin:50px auto 0;
  color:#101010;
  font-family: 'Poppins';
  margin-bottom: -2rem !important;
}

.actionIcons{
  position: absolute;
  left:20px;
  top:20px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer,
.container_button{
  width:auto;
  text-align: center;
  position:relative;
}

/* .container_button{
  margin:-30px;
} */

.titleContainer .actionIcons{
  right:calc(50% - 55px);
  top:-30px;
  left:auto;
}

.container_button  .actionIcons{
  right:calc(50% - 185px);
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

@media(max-width: 759px) {
  .title {
    text-align: center;
    margin-top: 4rem;
    font-size: 2rem;
    font-weight: 500;
  }
  .immobile {
    max-width: 300px;
  }
  .details::after {
    width: 130px;
  }
  .immobile_name {
    margin-bottom: .5rem;
    font-size: .8rem;
  }
  .immobile_options {
    margin-bottom: .5rem;
    font-size: .8rem;
    gap: 1.2rem;
  }
  .immobile_view {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}