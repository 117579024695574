.container_imgs {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(.79);
  margin-top: -1rem;
}

.container_imgs img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.container_content {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  /* padding: 0 4rem; */
}

.container_location {
  display: flex;
}

.container_config p{
  max-width: 400px;
}

.container_config_title {
  font-size: 1.5rem;
}

.container_imobile {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  max-width: 450px;
}

.config_icon {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: .5rem !important;
}

.price {
  margin: 1rem 0 !important;
  font-size: 1.5rem;
  font-weight: 500;
  border: 1px solid #C4C4C4;
  padding: 1rem !important;
  border-radius: 0.5rem;
  width: 250px !important;
}

.iptu {
  margin-bottom: 2rem;
}

.imobile {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.characteristics {
  margin: 1rem 0 !important; 
}

.information {
  margin-top: 4rem;
  margin-bottom: .5rem;
  width: 300px;
  color: #FFF;
  border-radius: .2rem;
  border: none;
  padding: .5rem 4rem;
  cursor: pointer;
}

.share {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  margin-bottom: 2rem;
  width: 300px;
  /* background-color: #1919bf; */
  color: #FFF;
  border-radius: .2rem;
  border: none;
  padding: .5rem 4rem;
  cursor: pointer;
}

.information:hover, 
.share:hover {
  /* color: #1919bf; */
  background-color: #FFF;
  /* border: 2px solid #1919bf; */
}

/* CAROUSEL */

.title_imobiles {
  text-align: center;
  margin-top: 4rem;
}

.container_carousel {
  max-width: 1200px;
  margin: auto;
  cursor: pointer;
  transform: scale(.9);
  margin-bottom: -2rem !important;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem;
}

.container_about {
  border: 1px solid #C4C4C4;
  padding: 1rem !important;
  border-radius: 0.5rem;
  width: 450px;
}

.immobile {
  max-width: 300px;
  border-radius: .5rem;
  background-color: #f6f6f6;
}

.bg {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 350px;
  height: 200px;
  border-radius: .5rem .5rem 0 0;
}

.immobile_config {
  padding: 1rem;
}

.immobile_name {
  margin-bottom: 1rem;
  font-weight: 500;
}

.immobile_options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-size: .9rem;
  margin-bottom: 1rem;
}

.immobile_price {
  font-weight: 600;
  font-size: 1.2rem;
}

.immobile_view {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: .5rem;
}

.details::after {
  content: "";
  display: block;
  height: 2px;
  width: 150px;
  background-color: #c6c4c4;
}

.immobile_view p {
  color: #FFF;
  font-size: .8rem;
  padding: .2rem .6rem !important;
  border-radius: .5rem;
  cursor: pointer;
}

.immobile_view p:hover {
  background-color: #FFF;
  transition: .5s;
}

.container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
  font-size: .8rem;
  margin-top: 1rem !important;
}


.actionIcons{
  position: absolute;
  left: 800px;
  top: 400px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons2{
  position: absolute;
  left: 800px;
  top: 850px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top:-30px;
  left:auto;
}

.container_button .actionIcons{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

/* .actionIcons:hover{
  transform: scale(1.05);
} */

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 22px;
  margin:auto;
  margin-top: 2rem !important;
  font-family: 'Poppins';
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

.maps {
  margin-bottom: -2rem !important;
}

/* LOGO */

.logo {
  width: 80px;
}

.semLogo{
  background-color: gray;
  color:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: .3rem;
  margin-left: 2rem;
}

.logo {
  margin-top: .3rem;
  margin-left: 2rem;
  width: 70px;
}

.container_logo{
  position:relative;
  background: #f6f6f6;
  opacity: .7;
  height: 48px;
}

.inputLogo{
  background-color: #bbb5b5;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 94px;
  cursor: pointer;
}