.feed-filters{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin-bottom: 20px;
}

.feed-filters fieldset{
    border-radius: 8px;
    width:100%;
    background-color: #f1f1f1;
    padding:10px;
}

.feed-filters .feed-search-input{
    display:flex;
    height:60px;

    & input{
        width:calc(100% - 40px);
        height:100%;
        border:solid 1px #cdcdcd;
        border-radius: 8px 0 0 8px;
        padding-left:5px;
        height:40px;
    }
    
    & span{
        width:40px;
        height:40px;
        margin: 0;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:24px;
        background-color: #3E5E3F;
        color:#fff;
        border-radius:0 8px 8px 0;
        cursor:pointer;

        &:hover{
            background-color: #283d29;
        }
    }
}

.feed-filters .clear-filters{
    display:block;
    text-align: center;
    text-decoration: underline;
    cursor:pointer;
    margin-top:5px;

    &:hover{
        color:#3E5E3F;
    }
} 