#indicate-generator{
    & label{
      margin-bottom:5px!important;
  
      @media(max-width:768px){
        margin-top:10px!important;
      }
    }
  }
  
  .indicate-link-container{
    width:100%;
    max-width:680px;
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .indicate-link-container input{
    width:calc(100% - 40px);
    border-radius:5px;
    height:35px;
    border:solid 1px #cdcdcd;
    background-color: #f4f4f4;
    color:#888;
    padding:0 5px;
  }
  
  .indicate-link-container button{
    border:none;
    border-radius:5px;
    width:35px;
    height:35px;
    background-color: #888;
    color:#101010;
    font-size:20px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkout-generator-link{
    display:flex;
    flex-direction: column;
    gap:10px;
  
    @media(max-width:768px){
      & select{
        margin-bottom:10px;
      }
    }
  }