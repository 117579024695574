:root{
    --green: #3e5e3f;
}

.checkout{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
}

.checkout h1,
.checkout span,
.checkout p{
    margin: 0;
    padding: 0;
}

.checkout-container{
    max-width: 1100px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.checkout .header-checkout {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1.3% 0%;
    background-color: white;
    margin-bottom: 30px;
}

.checkout .header-checkout .checkout-container img{
    width: 120px;
}

.checkout .checkout-container .menu-nav{
    background-color: white;
    display: flex;
    justify-content: center;
}

.checkout .checkout-container .menu-nav .menu-nav-content{
    display: flex;
    max-width: 990px;
    width: 100%;
    justify-content: space-evenly;
    border-bottom: 1px solid lightgrey;
}

.checkout .checkout-container .checkout-content{
    background-color: white;
    display: flex;
    gap: 20px;
    padding: 5%;
}

.checkout .checkout-container .menu-nav .menu-nav-content
.select-menu{
    cursor: pointer;
    padding: 3%;
}

.checkout .checkout-container .menu-nav .menu-nav-content
.select-menu span{
    font-size: 14px;
    color: lightgray;
}

.checkout .checkout-container .checkout-content 
.plan-select,
.checkout .checkout-container .checkout-content 
.plan-total{
    width: 50%;
}

.checkout .checkout-container .checkout-content 
.plan-select{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.checkout .checkout-container .checkout-content 
.plan-select .item-select{
    display: flex;
    gap: 25px;
    border: 1px solid lightgray;
    padding: 15px;
    cursor: pointer;
    transition: .3s;
}

.checkout .checkout-container .checkout-content 
.plan-select .item-select:hover{
    background-color: #3e5e3f24;
    border: 1px solid var(--green);
}

.checkout .checkout-container .checkout-content 
.plan-select .item-select .item-radio{
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkout .checkout-container .checkout-content 
.plan-select .item-select .item-prices{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.checkout .checkout-container .checkout-content 
.plan-select .item-select .item-prices h1{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 5px;
    color: var(--green);
}

.checkout .checkout-container .checkout-content 
.plan-select .item-select .item-prices p{
    font-size: 16px;
    color: var(--green);
}

.checkout .checkout-container .checkout-content 
.plan-select .item-select .item-prices span{
    font-size: 12px;
    color: var(--green);
}

.checkout .checkout-container .checkout-content 
.plan-select .btn-bottom{
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    border-top: 1px solid lightgrey;
}

.checkout .checkout-container .checkout-content 
.plan-select .btn-bottom button{
    background-color: unset;
    border: 0px;
    display: flex;
    cursor: pointer;
    align-items: center;
    font-size: 12px;
    outline: 0px;
    padding: 10px 45px;
    transition: .3s;
}

.checkout .checkout-container .checkout-content 
.plan-select .btn-bottom button svg{
    width: 20px;
}

.checkout .checkout-container .checkout-content 
.plan-select .btn-bottom button:nth-child(1){
    color: rgb(140, 140, 140);
    transition: .3s;
}

.checkout .checkout-container .checkout-content 
.plan-select .btn-bottom button:nth-child(1):hover{
    color: rgb(179, 179, 179);
    transition: .3s;
}

.checkout .checkout-container .checkout-content 
.plan-select .btn-bottom button:last-child{
    background-color: var(--green);
    color: white;
    border-radius: 20px;
}

.checkout .checkout-container .checkout-content 
.plan-select .btn-bottom button:last-child:hover{
    background-color: #3e5e3fb9;
}

.checkout .checkout-container .checkout-content 
.plan-total{
    border-left: 1px solid lightgray;
    padding: 5% 2%;
}

.checkout .checkout-container .checkout-content 
.plan-total .plan-title{
    border-bottom: 1px solid lightgray;
    margin-bottom: 15px;
    padding-bottom: 10px;
}

.checkout .checkout-container .checkout-content 
.plan-total .plan-title h1{
    font-size: 16px;
    color: rgb(144, 144, 144);
    font-weight: 500;
    margin-bottom: 15px;
}

.checkout .checkout-container .checkout-content 
.plan-total .plan-value,
.checkout .checkout-container .checkout-content 
.plan-total .plan-title span{
    color: rgb(144, 144, 144);
}

.checkout .checkout-container .checkout-content 
.plan-total .plan-value{
    display: flex;
    justify-content: space-between;
}


.checkout .checkout-container .checkout-content 
.plan-total .plan-value span:nth-child(2) span{
    font-size: 12px;
    margin-right: 5px;
    color: rgb(89, 89, 89);
}

.checkout .checkout-container .checkout-content 
.plan-total .plan-value span:nth-child(2){
    font-size: 22px;
    color: rgb(89, 89, 89);
}

.selected{
    border-bottom: 1px solid #3e5e3f;
}

.selected span{
    color: #3e5e3f!important;
}

.selected-radio{
    background-color: #3e5e3f24;
    border: 1px solid var(--green)!important;
}

.container-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7rem;
}

.price-year {
    font-size: 12px !important;
}

@media (max-width: 823px){
    .checkout .header-checkout {
        padding: 5% 0%;
    }
    
    .checkout-container{
        max-width: 390px;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .checkout .checkout-container .checkout-content{
        flex-direction: column;
    }

    .checkout .checkout-container .checkout-content .plan-select,
    .checkout .checkout-container .checkout-content .plan-total{
        width: 100%;
        border-left: 0px;
    }

    .checkout .checkout-container .checkout-content .plan-select .btn-bottom {
        padding: 10px 20px;
    }

    .checkout .checkout-container .checkout-content .plan-select .btn-bottom button:nth-child(1) {
        padding: 20px;
    }

}

.hidde{
    display: none!important;
}

/* .show{
    display: flex!important;
} */

.hidden-btn{
    display: none!important;
}