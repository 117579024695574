.credit-card-payment{
    display:flex;
    gap:15px;
  
    @media(max-width:768px){
      flex-direction:column-reverse;
    }
  }
  
  .credit-card-payment form{
    display:flex;
    flex-direction:column;
    gap:8px;
  
    & label{
      font-size:11px;
    }
  }
  
  .credit-card-payment-title{
    margin:10px 0 20px;
  
    & h3{
      font-size:18px;
    }
  }
  
  .credit-card-payment-row{
    display:flex;
    gap:10px;
  
    & fieldset:nth-child(1){
      flex:2;
    }
  
    & fieldset:nth-child(2){
      flex:1;
    }
  }
  
  .credit-card-payment button{
    margin-top: 8px;
    background-color: #3E5E3F;
    height:40px;
    border-radius:8px;
    font-size: 18px;
    font-weight: 800;
    border:none;
    color:#fff;
  
    &:hover{
      background-color: #283d29;
    }
  
    &:disabled, &:disabled:hover{
      background-color: #cdcdcd;
    }
  }