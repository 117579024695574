.new-user-modal{
    position:fixed;
    padding:20px;
    background-color: #fff;
    border-radius:10px;
    top:50%;
    left:50%;
    transform:translate(-50%, -500%);
    transition:0.3s;
    z-index:1999;
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
    width:640px;
    max-width:100%;
    min-height:400px;
    max-height:90vh;
    overflow: auto;
  
    & .close-modal{
      position:absolute;
      right:20px;
      top:10px;
      cursor:pointer;
  
      & i{
        font-size:20px;
      }
    }
  }
  
  .new-user-modal.active{
    transform:translate(-50%, -50%);
  }
  
  .new-user-modal-overlay{
    position:fixed;
    width:100%;
    height:100vh;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:black;
    opacity:0.6;
    display:none;
    z-index:1998;
  } 
  
  .new-user-modal-overlay.active{
    display:block;
  }
  
  .new-user-modal-body fieldset{
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
    width:100%;
  
    & input{
      height:40px;
      border-radius:5px;
      border:solid 1px #cdcdcd;
      padding:0 5px;
      width:100%;
    }
  }
  
  .new-user-modal-body .type-button{
    width:100%;
    background-color: #888;
    height:45px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 5px;
    color:#fff;
    gap:8px;
    font-size:18px;
    font-weight:800;
    cursor:pointer;
  }
  
  .new-user-modal-body .type-button:hover,
  .new-user-modal-body .type-button.active{
    background-color:#628adc;
  }
  
  .new-user-modal-body .type-container{
    margin-bottom:20px;
  }
  
  .new-user-modal-actions{
    display:flex;
    justify-content: flex-end;
    gap:10px;
  
    .close-modal-button{
      background-color: #888;
      border:none;
  
      &:hover{
        background-color: #636363;
      }
    }
  }
  
  .new-user-modal-pass{
    border:solid 1px #cdcdcd;
    border-radius:10px;
    margin-bottom:10px;
  }
  
  .new-user-modal-title{
    font-size: 18px;
    font-weight:700;
    margin-bottom: 20px;
  }
  
  @media(max-width:768px){
    .new-user-modal-pass button{
      margin-top:-45px;
    }
    .new-user-modal-body .type-container{
      gap:5px;
    }
    .new-user-modal-actions{
      margin-top:20px;
    }
  }