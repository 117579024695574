.bureaucracy {
  background: linear-gradient(93deg, rgba(244,244,244,1) 50%, rgba(255,255,255,1) 100%);
  margin-top: 3rem;
  padding-left: 1rem;
  height: 130px;
  position: relative;
  overflow-x: clip;
}

.title {
  text-align: center;
  font-size: 1em;
  padding-top: 2rem!important;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
  font-weight:600;
}

.icon {
  width: 38px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text p{
  margin-bottom: 0px;
  font-size: .7rem;
  font-family: 'Poppins', sans-serif;
  color:rgb(33, 33, 33);
}

@media(min-width: 759px) and (max-width: 1119px) {
  .bureaucracy {
    height: 145px;
  }
  .container {
    padding-bottom: 1rem;
  }
  .title {
    text-align: center;
    font-size: 1em;
    padding-top: 2rem;
    font-size: 1.5rem;
  }
  .text p{
    margin-bottom: 0px;
    font-size: 1rem;
  }
}

@media(min-width: 1200px) {
  .bureaucracy {
    height: 200px;
  }
  .title {
    font-size: 2rem;
    padding-top: 2.5rem;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  .container {
    padding-bottom: 2rem;
  }
  .text p{
    margin-bottom: 0px;
    font-size: 1.2rem;
    margin-left: -1.5rem !important;
  }
  .icon {
    width: 73px;
    margin-right: .6rem;
  }
  .doc {
    background-image: url('../assets/docfundo.png');
    background-repeat: no-repeat;
    position: absolute;
    z-index: 2;
    top: 0px;
    right: 0px;
    height: 180px;
    width: 130px;
    opacity: .3;
  }
}