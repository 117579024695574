@charset "UTF-8";
.UsersAnalytics .table {
  width: 100%;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  height: 400px;
  overflow-y: auto;
  background-color: #fff;
}

.UsersAnalytics .table::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.UsersAnalytics .table::-webkit-scrollbar-track {
  background: #f4f4f4; /* color of the tracking area */
}

.UsersAnalytics .table::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
  border: 3px solid #f4f4f4; /* creates padding around scroll thumb */
}

.UsersAnalytics .header {
  display: flex;
  flex-direction: row;
  background-color: #3E5E3F;
  border-radius: 5px 5px 0 0;
  color: #fff;
  height: 35px;
  align-items: center;
}

.UsersAnalytics .table .body {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  color: #888;
  min-height: 40px;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}

.UsersAnalytics .table .body:hover {
  height: 50px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #333;
  background-color: #cdcdcd;
}

.UsersAnalytics .header .col:nth-child(1) {
  width: calc(100% - 180px);
  padding: 5px 10px;
  flex: auto;
}

.UsersAnalytics .header .col:nth-child(2) {
  width: 180px;
  padding: 5px 10px;
  flex: auto;
  text-align: center;
}

.UsersAnalytics .table .body .col:nth-child(1) {
  width: calc(100% - 180px);
  padding: 5px 10px;
  flex: auto;
}

.UsersAnalytics .table .body .col:nth-child(2) {
  width: 180px;
  padding: 5px 10px;
  flex: auto;
  text-align: center;
}

.UsersAnalytics .table .body:nth-child(2n+1) {
  background-color: #f4f4f4;
}

.UsersAnalytics .table .body:nth-child(2n+1):hover {
  height: 50px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  color: #333;
  background-color: #cdcdcd;
}

.UsersAnalytics .table .premiumTrue {
  background-color: #3E5E3F;
  color: #fff;
  padding: 1px 15px;
  border-radius: 5px;
}

.UsersAnalytics .table .premiumStart {
  background-color: #628adc;
  color: #fff;
  padding: 1px 15px;
  border-radius: 5px;
}

.UsersAnalytics .table .premiumFalse {
  background-color: rgba(218, 44, 61, 0.5098039216);
  color: #fff;
  padding: 1px 15px;
  border-radius: 5px;
}

.UsersAnalytics .userType {
  border: solid 1px #3E5E3F;
  border-radius: 5px;
  padding: 30px 20px 0;
  margin-bottom: 30px;
  position: relative;
}

/* .UsersAnalytics .userType::before{
  font-size: 18px;
  color:#0F6E43;
  background-color: #fff;
  position:absolute;
  top:-15px;
  left:10px;
  padding:0 10px;
} */
/* .UsersAnalytics .userType.corretor::before{
  content:'Corretores';
}

.UsersAnalytics .userType.imobiliaria::before{
  content:'Imobiliárias';
} */
.UsersAnalytics .userType {
  margin-top: -30px;
}

.UsersAnalytics .pending {
  background-color: #f1ce41;
}

.UsersAnalytics .reproved {
  background-color: #da2c3e;
}

.UsersAnalytics .premium {
  background-color: #628adc;
}

.UsersAnalytics .total {
  background-color: #3E5E3F;
}

.UsersAnalytics .parceria {
  background-color: #628adc;
}

.UsersAnalytics .card {
  height: 40px;
  border-radius: 5px;
  width: 155px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  padding-left: 15px;
}

.UsersAnalytics .card:hover {
  transform: scale(1.05);
}

.UsersAnalytics .title {
  padding: 10px 0;
}

.UsersAnalytics .title h3 {
  font-size: 32px;
  color: #888;
  font-weight: 600;
  margin: 0 0 15px;
}

.UsersAnalytics .title h4 {
  font-size: 14px;
  color: #888;
  font-weight: 500;
  margin: 0 0 15px;
}

.UsersAnalytics .card span {
  font-size: 16px;
  font-weight: 600;
  color: #101010;
  background-color: #f4f4f4;
  width: 40px;
  height: 100%;
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UsersAnalytics .ranking-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  height: 460px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
}

.UsersAnalytics .ranking-container::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.UsersAnalytics .ranking-container::-webkit-scrollbar-track {
  background: #f4f4f4; /* color of the tracking area */
}

.UsersAnalytics .ranking-container::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
  border: 3px solid #f4f4f4; /* creates padding around scroll thumb */
}

.UsersAnalytics .ranking-container .item {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  align-items: center;
}

.UsersAnalytics .ranking-container .item:nth-child(2n+1) {
  background-color: #f4f4f4 !important;
}

.UsersAnalytics .ranking-container .item.title,
.UsersAnalytics .ranking-container .item.title .position {
  font-size: 14px;
  color: #3E5E3F;
}

.UsersAnalytics .title.users {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.UsersAnalytics .title.users input {
  width: 180px;
  border-radius: 5px;
  border: solid 1px #cdcdcd;
  height: 30px;
  padding: 0 5px;
}

.UsersAnalytics .ranking-container .item .position {
  width: 160px;
  font-size: 22px;
  position: relative;
  text-align: center;
}

.UsersAnalytics .ranking-container .item .info {
  width: 100%;
}

.UsersAnalytics .ranking-container .item .immobile {
  width: 140px;
}

.UsersAnalytics .ranking-container .item .value {
  width: 140px;
  font-size: 12px;
  color: #888;
}

.UsersAnalytics .ranking-container .item:nth-child(2) .position::before {
  content: "";
  background-image: url("./../../../images/Icons/medalouro.png");
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 35px;
  background-position: center center;
  background-size: cover;
}

.UsersAnalytics .ranking-container .item:nth-child(3) .position::before {
  content: "";
  background-image: url("./../../../images/Icons/medalprata.png");
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 35px;
  background-position: center center;
  background-size: cover;
}

.UsersAnalytics .ranking-container .item:nth-child(4) .position::before {
  content: "";
  background-image: url("./../../../images/Icons/medalbronze.png");
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 35px;
  background-position: center center;
  background-size: cover;
}

.UsersAnalytics .ranking-container .item .cover {
  width: 100px;
}

.UsersAnalytics .ranking-container .item .cover .image {
  width: 40px;
  height: 40px;
  background-color: #cdcdcd;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  margin: auto;
  margin-right: 10px;
}

.UsersAnalytics .ranking-container .item .info h3 {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  line-height: 1.2;
}

.UsersAnalytics .ranking-container .item .info small {
  font-size: 12px;
  margin: 0;
}

.UsersAnalytics .dataAccordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 0 30px;
}

.UsersAnalytics .dataAccordion .card-button {
  color: #0F6E43;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  width: 240px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  z-index: 3;
  margin-left: 20px;
}

.UsersAnalytics .dataAccordion .card-button:hover {
  width: 260px;
}

.UsersAnalytics .dataAccordion .card-button span {
  margin: 0 8px;
}

.UsersAnalytics .contentInfo {
  display: none;
}

.UsersAnalytics .contentInfo.realEstate.imobiliarias {
  display: block;
}

.UsersAnalytics .contentInfo.broker.corretores {
  display: block;
}

.UsersAnalytics .contentInfo.access.acessos {
  display: block;
}

.UsersAnalytics .contentInfo.immobile.imoveis {
  display: block;
}

.UsersAnalytics .contentInfo.schedule.agendamentos {
  display: block;
}

@media (max-width: 560px) {
  .vertical-menu {
    overflow-y: auto !important;
  }
  body[data-sidebar=light] .vertical-menu {
    background: rgba(37, 43, 59, 0.5882352941) !important;
    width: 100% !important;
  }
  #sidebar-menu {
    background: #fff !important;
    width: 280px !important;
    min-height: 100% !important;
  }
  #sidebar-menu .ms-1 {
    font-size: 16px !important;
  }
}
@media (min-width: 768px) {
  .table-responsive td {
    font-size: 14px !important;
  }
}