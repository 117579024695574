.bg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('../assets/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.about img {
  width: 140px;
  border-radius: .5rem;
}

.about p {
  font-size: 1.5rem;
  font-weight: 600;
}

.text {
  font-size: .9rem;
  padding: 1rem !important;
  text-align: left;
  height: 175px;
  overflow: hidden;
}

.container {
  background-color: #FFF;
  border-radius: .5rem;
  margin: 1rem;
  height: 400px;
  width: 250px;
}

.textExpand{
  height:auto;
  overflow:hidden;
}

.titleEdit {
  text-align: center;
  padding: 0;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 22px;
  margin:0;
  color:#101010;
  font-family: 'Poppins';
}

.actionIcons{
  position: absolute;
  left:20px;
  top:20px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer{
  width:auto;
  text-align: left;
  position:relative;
}


.titleContainer .actionIcons{
  right: auto;
  top: -30px;
  left: 200px;
}

.container_button  .actionIcons{
  right:calc(50% - 185px);
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

/* ACTIONS */

.actionIconsFirst {
  position: absolute;
  left: 80px;
  top: 1145px;
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIconsFirst:hover{
  opacity:1;
}