.sub-user-list{
    margin-top: 20px;
  }
  
  .sub-user-list-title{
    border-bottom:solid 1px #cdcdcd;
    padding:5px 0;
  
    & label{
      font-weight:800;
    }
  }
  
  .sub-user-list-user{
    padding:8px 0;
  
    & p{
      font-size:18px;
    }
  
    & i{
      cursor:pointer;
      font-size:20px;
    }
  }
  
  .sub-user-title{
    & .row{
      justify-content: space-between;
    }
  
    & button{
      display:flex;
      align-items: center;
      gap:4px;
    }
  
    & h3{
      font-size:20px;
    }
  }
  
  @media(max-width:768px){
    .sub-user-list-title{
      display:none!important;
    }
  
    .sub-user-list-user{
      border-top:solid 1px #cdcdcd;
    }
  
    .sub-user-title{
      & h3{
        font-size:16px;
      }
  
      & button{
        margin-top:10px;
      }
    }
    .sub-user-list.container,
    .sub-user-title.container{
      padding:0;
    }
  }