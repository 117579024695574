.SwiperImoveis{
  height:400px;
}

.SwiperImoveis .swiper-slide{
  width:auto!important;
}

.SwiperImoveis .swiper-button-prev, .SwiperImoveis .swiper-button-next{
  color:#3E5E3F;
  background-color: #fff;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  border-radius:50%;
  width:40px;
  height:40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SwiperImoveis .swiper-button-next:after, .SwiperImoveis .swiper-button-prev:after{
  font-size: 26px!important;
  font-weight: 800!important;
}

.SwiperImoveis .moreAll{
  width:100px;
  background-color: #333;
  height:calc(100% - 35px);
  margin-top:20px;
  margin-left:15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  text-align: center;
  line-height: 1.2;
  transition:0.3s;
  cursor:pointer;
}

.SwiperImoveis .moreAll:hover{
  transform: scale(1.05);
}

.filter-btn{
  // box-shadow: 0 0 8px rgba(0,0,0,0.3);
  background-color: #fff!important;
  min-width:90px;
}

.searchField{
  display: flex;
  flex-direction: row;
  border-radius:  5px 0 0 5px;
  background-color: #fff;
  margin-bottom:10px;
}

.searchField input{
  border-radius: 5px 0 0 5px;
  height:40px;
}

.searchField input::placeholder{
  color:#cdcdcd;
}

.searchField .search-btn{
  border-radius: 0 5px 5px 0;
  width:37px!important;
  height:40px!important;
  background-color: #3E5E3F!important;
  border:none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.3s;
}

.searchField .search-btn i{
  color:#fff;
  font-size: 16px;
  line-height: 1;
}

.searchField .search-btn:hover{
  transform: scale(1.1);
}

.filterActive{
  font-size: 12px!important;
  cursor:pointer!important;
}

.filterActive i{
  margin-left:5px;
}

.contractTabs{
  height:40px;
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  gap:10px;
  align-items: flex-end;
  padding-left:10px;
  position:relative;
}

.contractTabs{
  border-bottom: solid 2px #cdcdcd;
}

.contractTabs.fs{
  border-bottom: solid 2px #3E5E3F;
}

.contractTabs.parceria{
  border-bottom: solid 2px #f1ce41;
}

.contractTabs.outlet{
  border-bottom: solid 2px black;
}

.contractTabs .tab{
  cursor:pointer;
  padding:5px 5px;
  border-radius: 5px 5px 0 0;
  border-top:solid 1px;
  border-left:solid 1px;
  border-right:solid 1px;
  font-size: 13px;
  font-weight:600;
}

.contractTabs .tab.all{
  color: #888;
  border-color: #cdcdcd;
}

.contractTabs .tab.all.active,
.contractTabs .tab.all:hover{
  background-color: #cdcdcd;
  border:none;
}

.contractTabs .tab.all:hover{
  background-color: #cdcdcde3;
}

.contractTabs .tab.fs{
  color: #3E5E3F;
  border-color: #3E5E3F;
}

.contractTabs .tab.fs.active,
.contractTabs .tab.fs:hover{
  background-color: #3E5E3F;
  border:none;
  color:#fff;
}

.contractTabs .tab.fs:hover{
  background-color: #3e5e3fc0;
}

.contractTabs .tab.parceria{
  color: #f1ce41;
  border-color: #f1ce41;
}

.contractTabs .tab.parceria.active,
.contractTabs .tab.parceria:hover{
  background-color: #f1ce41;
  border:none;
  color:#333;
}

.contractTabs .tab.parceria:hover{
  background-color: #f1ce41d0;
}

.contractTabs .tab.outlet{
  color: #101010;
  border-color:#101010;
}

.contractTabs .tab.outlet.active,
.contractTabs .tab.outlet:hover{
  background-color: #101010;
  border:none;
  color:#fff;
}

.contractTabs .tab.outlet:hover{
  background-color: #101010cb;
}

.contractTabs .favoritos.active,
.contractTabs .favoritos:hover {
  background-color: #628adc;
  border: none;
  color: #fff;
}

.sortContainer{
  justify-content:flex-end;
  display: flex;
  justify-content: flex-end;
}

.moreFilters{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding:10px 0;
  border-bottom: solid 1px #333;
  margin-bottom:10px;
  cursor:pointer;
}

@media(max-width:768px){
  .contractTabs{
    gap:5px;
    padding:0 10px;
    height:30px;
  }
  .tabList{
    padding:0!important;
  }
  .sortContainer{
    padding-top:20px;
    align-items: flex-start!important;
    justify-content: flex-start!important;
    margin:0 10px;
  }
}

// $fs: #0f6e43;
// $premium: #62c90e;
// $parceria: #628adc;
// $pendente: #f1ce41;
// $cancelado: #da2c3e;

#btnMap{
  position: fixed;
  bottom:40px;
  left:calc(50% - 65px);
  z-index:999;
  box-shadow: 0 0 8px rgba(0,0,0,0.5);
  border:solid 1px #fff;
}

.tab-icon{
  display:none;
}

@media(max-width:1230px){
  .contractTabs{
    overflow-x: auto;
    height:auto!important;
    padding:10px 0 20px!important;
    gap:5px!important;
  }

  .contractTabs .tab{
    min-width: fit-content;
    width:auto!important;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:0 0 15px!important;
    text-align:center;
    border:none;
    gap:5px;
    font-size: 12px!important;
    line-height:1.2;
    color:#101010!important;
    flex-direction:column;
    font-weight:600;

    span{
      min-height:29px;
      width:100px;
    }
  }
  .contractTabs .tab.active{
    background-color:#fff!important;
    border-bottom:solid 3px #3E5E3F!important;
  }

  .tab-icon{
    display:block;
    width:45px;
    height:45px;
    background-position:center center;
    background-size:contain;
  }
}

.em-breve-construtoras{
  display:flex;
  align-items:center;
  justify-content:center;
  gap:10px;
  flex-direction:column;
  padding:40px 0;

  p{
    font-size:16px;
    max-width:300px;
    text-align:center;
    line-height:1.4;
  }

  h3{
    font-weight:600;
  }
  h4 span{
    color:#3E5E3F;
  }
  button{
    display:flex;
    gap:5px;
    align-items: center;
    margin-top:10px;
  }
  img{
    width:160px;
    margin-bottom:-20px;
    margin-right:-5px;
  }
}

.tab-parceria{
  border-color: #f1ce41!important;
  color: #dfb21e!important;
}

.tab-premium{
  border-color: #3E5E3F!important;
  color: #3E5E3F!important;
}

.tab-outlet{
  border-color: #333!important;
  color: #333!important;
}

.tab-construtoras{
  border-color: #628adc!important;
  color: #628adc!important;
}

.tab-empreendimentos{
  border-color: #cdcdcd!important;
  color: #888!important;
}

.tab-parceria:hover{
  background-color: #f1ce41!important;
  color:#333!important;
}

.tab-premium:hover{
  background-color: #3E5E3F!important;
  color:#fff!important;
}

.tab-outlet:hover{
  background-color: #333!important;
  color:#fff!important;
}

.tab-construtoras:hover{
  background-color: #628adc!important;
  color:#fff!important;
}

.tab-empreendimentos:hover{
  background-color: #cdcdcd!important;
  color:#333!important;
}

.tab-parceria.active{
  background-color: #f1ce41!important;
  color:#333!important;
}

.tab-premium.active{
  background-color: #3E5E3F!important;
  color:#fff!important;
}

.tab-outlet.active{
  background-color: #333!important;
  color: #fff!important;
}

.tab-construtoras.active{
  background-color: #628adc!important;
  color:#fff!important;
}

.tab-empreendimentos.active{
  background-color: #cdcdcd!important;
  color:#333!important;
}

@media(max-width:1230px){
  .tab-premium.active{
    color:#333!important;
  }

  .tab-outlet.active{
    color:#333!important;
  }
  
  .tab-construtoras.active{
    color:#333!important;
  }
}

.feed-cta-container{
  background-position: center center;
  background-size: cover;
  border:solid 3px #3E5E3F;
  border-radius:5px;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  padding:10px;
  display:flex;
  gap:10px;
  align-items: center;
  
  & .feed-cta-title{
    width:190px;
    text-align: left;
    display:flex;
    justify-content: center;
    gap:10px;
    align-items: center;

    & img{
      width:50px;
    }

    & h3{
      font-family: 'Poppins';
      font-size:22px;
      color: #3E5E3F;
      font-weight:600;
    }

    & h5{
      font-family: 'Poppins';
      font-size:18px;
      font-weight:600;
    }
  }
  & .feed-cta-body{
    width:50%;
    text-align:center;

    & h5{
      font-family: 'Poppins';
      font-size:16px;
      font-weight:500;
    }
    & p{
      font-family: 'Poppins';
      font-weight:500;
      font-size:12px;
      margin-bottom:8px!important;
    }
    & button{
      font-weight:600;
      font-size: 14px;

      @media(max-width:768px){
        font-size: 12px;
      }
    }
  }
  & .feed-cta-footer{
    margin-top:10px;
    text-align:center;
  }
}

.beta-version{
  margin:20px 10px!important;

  & h4{
    font-size:18px;
    font-weight:300;
    color:#555;
  }

  @media(max-width:768px){
    margin:20px 0px!important;

    & .col-12{
      padding:0!important;
    }

    & h4{
      font-size:14px;
    }
  }
}

.catalog-info-separate{
  margin:20px 15px 10px;
  display:block;
  color:#3E5E3F;

  & span{
    padding-top:5px;
  }

  & p{
    gap:8px;
    font-weight: 800;
    font-size:18px;

    @media(max-width:768px){
      font-size:16px;
    }
  }
}

.constructionPlace{
  margin-left: 30px;
  margin-top:-20px;
}

.construction-campaign{
    margin:30px;
    padding:0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:20px;

    & img{
      width:100%;
      max-width:640px;
    }
}

.mobile-tab-icon{
  @media(min-width:768px){
    display:none;
  }
}