.register-section{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    padding:40px 15px 80px;
  }
  
  .register-section .register-wrapper{
    width:100%;
    max-width:560px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  
  .register-section .register-wrapper .logo-container{
    text-align:center;
    margin-bottom:40px;
  
    & img{
      width:200px;
    }
  }
  
  .register-section .register-wrapper fieldset{
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
  
    & input{
      height:40px;
      border-radius:5px;
      border:solid 1px #cdcdcd;
      padding:0 5px;
    }
  }
  
  .register-section .register-wrapper .type-button{
    width:100%;
    background-color: #888;
    height:45px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 5px;
    color:#fff;
    gap:8px;
    font-size:18px;
    font-weight:800;
    cursor:pointer;
  }
  
  .register-section .register-wrapper .type-button:hover,
  .register-section .register-wrapper .type-button.active{
    background-color:#628adc;
  }
  
  .register-section .register-wrapper .type-container{
    margin-bottom:20px;
  }
  
  @media(max-width:768px){
    .register-section .register-wrapper .type-container{
      gap:10px;
    }
  }
  
  .register-section .register-wrapper .send-container{
    & button{
      width:100%;
      height:45px;
      font-size:18px;
      font-weight:800;
    }
  }
  
  .register-section .register-wrapper .step-title{
    text-align:center;
    margin-bottom:40px;
  
    & h1{
      font-size:24px;
    }
  
    & h3{
      font-size: 14px;
      color:#888;
    }
  }
  
  .thank-you-container{
    width:100%;
    height:100vh;
    background-position: center center;
    background-size: cover;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  
  .thank-you-container .thank-you-content{
    display:flex;
    flex-direction:column;
    gap:10px;
    align-items: center;
    text-align:center;
  
    & h4{
      font-size:24px;
      color:#fff;
    }
  
    & p{
      font-size:20px;
      color:#f4f4f4;
      max-width:480px;
    }
  
    & button{
      font-size:18px;
      font-weight:800;
      margin-top:10px;
    }
  }
  
  .thank-you-container .thank-you-title{
    display:flex;
    flex-direction:column;
    align-items: center;
    gap:10px;
  }