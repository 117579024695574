.add-borker-card{
  width:200px;
  height:290px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  padding:20px;
  border-radius:8px;
  cursor:pointer;
  transition:0.2s;
  margin-top:27px;
}

.add-borker-card-member{
  width:340px;
  height:290px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  padding:20px;
  border-radius:8px;
}

.add-borker-card:hover{
  transform:scale(1.03);
}

.add-broker-button{
  background-color:#f4f4f4;
  width:100%;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  gap:5px;
  flex-direction:column;
}

.add-broker-button p{
  color:#888;
  font-size:14px;
}

.add-broker-button i{
  color:#888;
  font-size:30px;
}

.modal-add-broker{
  display:flex;
  flex-direction:column;
}

.modal-add-broker input{
  min-width:320px;
  gap:10px;
}

.modal-add-broker-footer{
    padding:0;
    margin-top:15px;
    display:flex!important;
    justify-content:flex-end;
    width:100%;
}

.top-add-broker-card{
  width:100%;
  display:flex;
  gap:10px;
}

.cover-add-broker-card{
  width:80px;
  height:80px;
  background-position:center center;
  background-size:cover;
}

.name-add-broker-card{
  width:calc(100% - 100px);
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:flex-start;

  h3{
    font-size:18px;
  }
  p{
    font-size:12px;
  }
}

.body-add-broker-card{
  margin-top:15px;
  display:flex;
  flex-direction:column;
  gap:10px;

  .box{
    display:flex;
    gap:10px;
    border:solid 1px #f4f4f4;
    padding:10px;
    border-radius:8px;
    display:flex;
    flex-wrap:wrap;
    .col-box{
      flex:1;
      p{
        font-size:11px;
      }
      h3{
        font-size:16px;

        span{
          font-size:12px;
        }
      }
    }
  }
}

.footer-add-broker-card{
  text-align:right;
  padding:10px 0 6px;

  span{
    display:flex;
    gap:5px;
    align-items:center;
    font-weight:600;
    cursor:pointer;
    justify-content:flex-end;
  }
}

.status-add-broker{
  width:140px;
  margin:auto;
  padding:3px 5px;

  .pendente{
    background-color:#f1ce41;
    text-align:center;
    color:#888;
    border-radius:5px 5px 0 0;
  }
  .ativo{
    background-color:#3E5E3F;
    text-align:center;
    color:#fff;
    border-radius:5px 5px 0 0;
  }
  .negado{
    background-color:#da2c3e;
    text-align:center;
    color:#fff;
    border-radius:5px 5px 0 0;
  }
}

.profileCover{
  width:140px;
  height:140px;
  background-color:#f4f4f4;
  border-radius:6px;
  background-position:center center;
  background-size:cover;
}

.profileInfo{
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  height:100%;

  .name h3{
    font-size:18px;
  }
  .name p{
    font-weight:600;
    font-size:14px;
  }
}

.infoCol{
  border-right:solid 1px #cdcdcd;
  text-align:center;
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  height:80px;

  h3{
    font-size:20px;
  }
}

.infoCol.infoEnd{
  border:none;
}

@media(max-width:1000px){
    .infoCol{
      border:none;
      border-bottom:solid 1px #cdcdcd;
      height:100px;
    }
    .infoCol.infoEnd{
      border:none;
    }
  }

  .relatorio-button{
    display:flex;
    position:relative;
    background-color:#888!important;
    border:none!important;
    padding-left:50px!important;

    span{
      height:100%;
      width:40px;
      background-color:#cdcdcd;
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      border-radius:3px 0 0 3px;
      display:flex;
      align-items:center;
      justify-content: center;

      i{
        font-size:20px;
        color:#333;
      }
    }
  }