.container_search {
  margin: 4rem 2rem 0 0rem;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(224, 224, 224);
  width: 90px;
  font-size: .8rem;
  border-radius: .5rem .5rem 0 0;
  padding: .3rem;
  margin-left: .3rem;
  cursor: pointer;
}

.container_filter {
  background-color: #fbfbfb;
  padding: 2rem;
  border-radius: 0.5rem;
  border:1px solid #d7d7d7;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  height: 105vh;
}

.icon {
  width: 28px;
}

.contariner_first {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}

.category_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 1px solid rgb(225, 225, 225);
  width: 95px;
  height: 90px;
  padding: 1rem;
  border-radius: .5rem;
  cursor: pointer;
}

.category_content:hover {
  background-color: #d7d7d7;
  transition: .3s;
}

.category_content p {
  font-size: .7rem;
}

.vacancies p{
  margin-top: 2rem !important;
  padding-bottom: .5rem !important;
}

.numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  cursor: pointer;
}

.numbers span {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(225, 225, 225);
  border-radius: .5rem;
  padding: .8rem;
  width: 32px;
  height: 32px;
}

.bedrooms p {
  margin-top: 2rem !important;
  padding-bottom: .5rem !important; 
}

.value {
  margin-top: 2rem !important;
  padding-bottom: .5rem !important;
}

.value input {
  width: 225px;
  border: 1px solid #e9e9e9;
  border-radius: .5rem;
  margin-top: 1rem;
  padding-left: .5rem;
  height: 42px;
}

.container_buton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.button_filter {
  width: 225px;
  padding: .5rem;
  border: 1px solid rgb(225, 225, 225);
  border-radius: .5rem;
  cursor: pointer;
}

.button_filter:hover {
  background-color: #d7d7d7;
  transition: .3s;
}

.hidden {
  display: none;
}

.selected {
  border: 2px solid #007bff !important; 
}

.actionIcons{
  position: absolute;
  left: 800px;
  top: 400px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top:-30px;
  left: 400px;
  width: 150px;
}

.container_button .actionIcons{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

/* .actionIcons:hover{
  transform: scale(1.05);
} */

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border: none;
  width: 580px;
  max-width: 150%;
  font-weight: 500;
  font-size: 22px;
  margin: auto;
  margin-top: 2rem !important;
  font-family: 'Poppins';
  position: absolute;
  left: 280px;
  top: -40px;
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}