.container_imgs img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.config_icon {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.container_config {
  padding: 1rem;
}

.name {
  font-weight: 500;
  font-size: 1.1rem;
}

.container_icons {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  margin-bottom: 1rem;
}

.config {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  font-size: .9rem;
}

.price {
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.iptu {
  margin-top: 2rem;
  font-size: .9rem;
}

.information {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 214px;
  background-color: #1919bf;
  color: #FFF;
  border-radius: .2rem;
  border: none;
  padding: 1rem !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  margin-bottom: 2rem;
  width: 214px;
  background-color: #1919bf;
  color: #FFF;
  border-radius: .2rem;
  border: none;
  padding: .5rem 4rem;
}

.about {
  margin-bottom: 1rem !important;
}

.characteristics {
  margin: 1rem 0;
}

.container_about {
  border-radius: .5rem;
}

.container_about p{
  font-size: .9rem;
  margin-bottom: .5rem !important;
}

/* CAROUSEL */

.title_imobiles {
  text-align: center;
  margin-top: 4rem;
}

.container_carousel {
  max-width: 1200px;
  margin: auto;
  padding-top: 4rem;
  cursor: pointer;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6rem
}

.immobile {
  max-width: 350px;
  border-radius: .5rem;
  background-color: #f6f6f6;
}

.bg {
  background-image: url('../assets/foto_fake.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 350px;
  height: 200px;
  border-radius: .5rem .5rem 0 0;
}

.immobile_config {
  padding: 1rem;
}

.immobile_name {
  margin-bottom: 1rem;
  font-weight: 500;
}

.immobile_options {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: .9rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.immobile_price {
  font-weight: 600;
  font-size: 1.2rem;
}

.container_test_information {
  display: flex;
  justify-content: center;
}

.immobile_view {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: .5rem;
}

.details::after {
  content: "";
  display: block;
  height: 2px;
  width: 180px;
  background-color: #c6c4c4;
}

.immobile_view p {
  background-color: #1919bf;
  color: #FFF;
  font-size: .7rem;
  padding: .2rem .8rem !important;
  border-radius: .5rem;
  cursor: pointer;
}

.immobile_view p:hover {
  background-color: #FFF;
  color: #1919bf;
  border: 1px solid #1919bf;
  transition: .5s;
}

.container_icons {
  display: flex;
  align-items: center;
  gap: .2rem;
}

.container_info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionIcons2{
  position: absolute;
  left: 0px;
  top: 700px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons3{
  position: absolute;
  left: 0px;
  top: 620px;
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(0,0,0,0.4);
  background: rgb(237,237,237);
  background: radial-gradient(circle, rgba(237,237,237,1) 27%, rgba(255,255,255,1) 100%);
  /* width:45px; */
  height:30px;
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  transition:0.1s;
  cursor:pointer;
  padding:0 10px;
  border:solid 1px cyan;
  opacity:0.7;
}

.actionIcons:hover{
  opacity:1;
}

.titleContainer .actionIcons{
  right:calc(50% - 67px);
  top: -20px;
  left:auto;
}

.container_button .actionIcons{
  right:-70px;
  top:0px;
  left:auto;
}

.actionIcons label{
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap:8px;
}

.actionIcons span{
  font-size: 12px;
  line-height:1;
  cursor:pointer;
  font-weight: 600;
}

.actionIcons i{
  font-size: 18px;
  cursor:pointer;
}

/* .actionIcons:hover{
  transform: scale(1.05);
} */

.titleEdit {
  text-align: center;
  padding: 0 1rem;
  border:none;
  width: 580px;
  max-width: 100%;
  font-weight: 500;
  font-size: 22px;
  margin:auto;
  margin-top: 2rem !important;
  font-family: 'Poppins';
}

.titleContainer{
  width:auto;
  text-align: center;
  position:relative;
}

.colorPickerTitle{
  position: absolute;
  left:140px;
  top:0px;
  z-index:99999;
}

.colorPickerTitle i,
.colorPickerButton i{
  background-color: #fff;
  width:35px;
  height:35px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  right:-35px;
  top:0px;
  z-index:99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorPickerButton{
  position: absolute;
  left:70px;
  top:0px;
}

.maps {
  margin-bottom: -2rem !important;
}


@media(max-width: 759px) {
  .title {
    text-align: center;
    margin-top: 4rem;
    font-size: 2rem;
    font-weight: 500;
  }
  .immobile {
    max-width: 300px;
  }
  .details::after {
    width: 130px;
  }
  .immobile_name {
    margin-bottom: .5rem;
    font-size: .8rem;
    gap: 1rem;
  }
  .immobile_options {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-size: .8rem;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  .immobile_view {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}